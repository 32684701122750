import { EventEmitter, Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { User } from "../../models/user";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../app.config";
import { Observable} from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { TokenResponse } from "../../models/tokenResponse";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { UserService } from "../user/user.service";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authKey: string = this.config.keyMyAuth;
  clientId: string = "TestMakerFree";
  tokenResponse:TokenResponse;

  user: User = {
    Id: null,
    FirstName: null,
    LastName: null,
    Email: null,
    UserName: null,
    RegisteredDate: null,
    CompanyName: null,
    DepartmentName: null,
    Password: null,
    RememberMe: null,
    IsLoggedIn: null,
    Token: null,
  };
  loginUserName: string="";
  loginUserFirstName: string;
  companyName: string;
  
  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  isLoggedIn(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return sessionStorage.getItem(this.authKey) != null;
    }
    return false;
  }

  logout(): boolean {
    this.setAuth(null);
    return true;
  }

  //**************************************************************** /
  // Retrieves the auth JSON object (or NULL if none)
  // This is used in auth.intercepto.ts to insert token in the header
  //**************************************************************** /
  getAuth(): TokenResponse | null {
    if (isPlatformBrowser(this.platformId)) {
      var i = sessionStorage.getItem(this.authKey);
      if (i) {
        return JSON.parse(i);
      }
    }
    return null;
  }

  // Idea B) Based on the sample in the book "ASP.NET Core 2 and Atngular 5 (Packt)"
  loginBookSampleNew(email: string, password: string): Observable<TokenResponse> {
    var url = "/api/token/auth";
    var data = {
      Grant_type: "password",
      Client_id: this.clientId,
      Client_secret: "",
      Username: email,
      Password: password,
      Refresh_token: ""
    };
    this.loginUserName = data.Username;
    return this.getAuthFromServerAngular(url, data);
    //return this.getAuthFromServerFullStack(url, data);
  }

  // retrieve the access & refresh tokens from the server
  // Pattern 1: Below is VS2017(Host) - VSCode(Client) way
  getAuthFromServerAngular(url: string, data: any): Observable<TokenResponse> {
    return this.http
      .post<TokenResponse>(this.config.apiMyUrl + url, data)
      .map(res => {
      //.pipe(map(res => {
        let token = res && res.token;
        // if the token is there, login has been successful
        console.log(
          "######## In auth.service - response from api host success: " +
            res.token
        );
        if (token) {
          // store username and jwt token
          this.setAuth(res);
          this.setUserProfile(this.loginUserName);          
          this.setUserName(this.loginUserName);  /* Need this becuase before setserProfile finish
                                                 //SetItem session for username, login user is directed
                                                 //to dashboard in which onInit() needs loginUser session.   */
           localStorage.setItem("MTSPortalLoginUserId",data.Username)                                       
           console.log("localStorage saved user Id in auth.service: "+ localStorage.getItem("MTSPortalLoginUserId"));
          // successful login
          return true;
        }
        // failed login
        return Observable.throw("Unauthorized");
      })
      .catch(error => {
        return new Observable<any>(error);
      });
  }

  setUserProfile(userName: string) {
    this.userService.getUserProfile(userName).subscribe(result => {
      this.user = result;
      sessionStorage.setItem("loginUserName", this.user.UserName);
      sessionStorage.setItem("loginUserFirstName", this.user.FirstName);
    });
  }

  setUserName(userName: string) {
    sessionStorage.setItem("loginUserName",  userName);
  }

  // Persist auth into localStorage or removes it if a NULL argument is given
  setAuth(auth: TokenResponse | null): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (auth) {
        sessionStorage.setItem(this.authKey, JSON.stringify(auth));
      } else {
        sessionStorage.removeItem(this.authKey);
        sessionStorage.clear();
      }
    }
    return true;
  }

  checkMTSUser(): boolean {
    if (this.isLoggedIn()) {
      this.loginUserName = sessionStorage.getItem("loginUserName");
      if(this.loginUserName==null){
        return false;
      }
      return this.loginUserName.includes("marubeni-trans.com");
    }
    return false;
  }

  getUserName(): string {
    if (this.isLoggedIn()) {
      console.log('isLogin');
      console.log('session '+ sessionStorage.getItem("loginUserName"));
      this.loginUserName = sessionStorage.getItem("loginUserName");
    }
    return this.loginUserName;
  }

  getLoginUserFirstName(): string {
    if (this.isLoggedIn()) {
      this.loginUserFirstName = sessionStorage.getItem("loginUserFirstName");     
    }
    return this.loginUserFirstName;
  }

  getJWT() {
    this.tokenResponse = JSON.parse(sessionStorage.getItem(this.authKey));
    if(this.tokenResponse!=null){
    return new HttpHeaders()
      .append("Content-Type", "application/json")
      .append(
        "Authorization",
        "Bearer " + this.tokenResponse.token
      );
    }else{
      return null;
    }
  }

  getJWTforExcelAttachment() {
    this.tokenResponse = JSON.parse(sessionStorage.getItem(this.authKey));
    if(this.tokenResponse!=null){
    return new HttpHeaders()
      .append("Content-Type", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      .append(
        "Authorization",
        "Bearer " + this.tokenResponse.token
      );
    }else{
      return null;
    }
  }

}
