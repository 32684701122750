import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common'; 

//added
import { AppConfig } from "./app.config";
import { myRoutes} from "./app.myRoutes";
import { FlashMessagesModule } from "angular2-flash-messages";
import { FormsModule,ReactiveFormsModule  } from "@angular/forms";
import { HttpClientModule} from "@angular/common/http";
import { DatePipe } from "@angular/common";

// ###### Angular Material Master File #######################
import { MyAngularMaterialModule} from './app.angularMaterial-module';
//import {MatDialog} from "@angular/material/dialog";
//############################################################

// Service Imports
import { UserService } from "./services/user/user.service";
import { AuthService } from "./services/auth/auth.service";
import { CargoService} from "./services/cargo/cargo.service";
import { FileService} from "./services/file/file.service";

import { LoginComponent } from './components/general/login/login.component';
import { NewPasswordComponent } from './components/general/new-password/new-password.component';
import { NotFoundComponent } from './components/general/not-found/not-found.component';
import { RegisterComponent } from './components/general/register/register.component';
import { ResetPasswordComponent } from './components/general/reset-password/reset-password.component';
import { TokenCheckComponent } from './components/general/token-check/token-check.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { HomeComponent } from './components/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CargoHeaderComponent } from './components/cargo/cargo-header/cargo-header.component';
import { CargoDetailComponent } from './components/cargo/cargo-detail/cargo-detail.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { Dashboard2Component } from './components/dashboard/dashboard2/dashboard2.component';
import { Dashboard3Component } from './components/dashboaard/dashboard3/dashboard3.component';
import { FileUploadComponent } from './components/entry/file-upload/file-upload.component';
import { TopComponent } from './components/top/top.component';
import { RecentUpdatesComponent } from './components/recent-updates/recent-updates.component';
import { EmailSentMessageComponent } from './components/general/email-sent-message/email-sent-message.component';
import { EnterNewPasswordComponent } from './components/general/enter-new-password/enter-new-password.component';
import { BlHeaderComponent } from './components/cargo/bl-header/bl-header.component';
import { CargoMainHeaderComponent } from './components/cargo/cargo-main-header/cargo-main-header.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NewPasswordComponent,
    NotFoundComponent,
    RegisterComponent,
    ResetPasswordComponent,
    TokenCheckComponent,
    AlertDialogComponent,
    HomeComponent,
    NavbarComponent,
    CargoHeaderComponent,
    CargoDetailComponent,
    DashboardComponent,
    Dashboard2Component,
    FileUploadComponent,
    TopComponent,
    RecentUpdatesComponent,
    EmailSentMessageComponent,
    EnterNewPasswordComponent,
    Dashboard3Component,
    BlHeaderComponent,
    CargoMainHeaderComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    //added
    myRoutes,
    FlashMessagesModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MyAngularMaterialModule,
  ],
  providers: [ {provide: LocationStrategy, useClass: HashLocationStrategy}, AppConfig, DatePipe, AuthService, UserService, CargoService,FileService],
  bootstrap: [AppComponent]
})
export class AppModule { }
