import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from "angular2-flash-messages";
import { Router } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { UserService } from "../../../services/user/user.service";
import { User } from "../../../models/user";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  user: any = {};
  MTSDepartmentNames = ["ACC","BD","CGO","GA", "HQ", "IT", "LBCO","LBOI","NSH","NYK"];
  showMTSDepartment: boolean = false;
  loading: boolean = false;
  constructor(
    private userService: UserService,
    private router: Router,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit() {
    sessionStorage.removeItem("userInProcess");
  }
  myFirstName = new FormControl("", [Validators.required, Validators.email]);
  getErrorMessage() {
    return this.myFirstName.hasError("required")
      ? "You must enter a value."
      : this.myFirstName.hasError("email")
      ? "Not a valid email"
      : "";
  }

  onBlurEmailBox() {
    if (this.user.Email.toLowerCase().indexOf("@marubeni-trans.com") > 0 ) {
      this.showMTSDepartment = true;
    } 
  }

  onSubmit() {
    this.loading = true;
    if (
      // 1. Password match check
      this.user.Password !== this.user.rePassword
    ) {
      this.flashMessagesService.show("Password mismatch", {
        cssClass: "alert-danger",
        timeout: 5000
      });
      this.loading = false;
      this.router.navigate(["/register"]);
    } else if (
      // 2. Domain Check
      this.user.Email.toLowerCase().indexOf("@marubeni-trans.com") < 0 &&
      this.user.Email.toLowerCase().indexOf("@dhcmc.com") <0 &&
      this.user.Email.toLowerCase().indexOf("yasu_ozeki@hotmail.com") <0
    ) {
      this.flashMessagesService.show("Unauthorized domain", {
        cssClass: "alert-danger",
        timeout: 10000
      });
      this.loading = false;
      this.router.navigate(["/register"]);
    } else {
      this.userService.checkEmail(this.user.Email).subscribe(user => {
        var user = user;
        if (user == null) {
          this.user.CompanyName = this.user.Email.split("@")[1];
          this.GeneratePasscode();
        } else {
          this.flashMessagesService.show(
            "Email : " + this.user.Email + " is already taken...",
            { cssClass: "alert-danger", timeout: 5000 }
          );
          this.loading = false;
          this.router.navigate(["/register"]);
        }
      });
    }
  }

  GeneratePasscode() {
    // Authentication check by Email confirmation
    var passcode = Math.random()
      .toString(36)
      .substr(2, 6);
    sessionStorage.setItem(
      "userInProcess",
      JSON.stringify({ userNotConfirmedYet: this.user, token: passcode })
    );
    console.log(this.user);

    this.userService.sendEmail(this.user.Email, passcode).subscribe(
      data => {
        this.flashMessagesService.show("Email sent to you successfully. Check your inbox", {
          cssClass: "alert-success",
          timeout: 10000
        });
        this.router.navigate(["/tokenCheck"]);
      },
      error => {
        this.flashMessagesService.show("Sending email failed...", {
          cssClass: "alert-danger",
          timeout: 10000
        });
        this.loading = false;
        this.router.navigate(["/register"]);
      }
    );
  }
}
