<div class="myContainer">
    <div>
      <a
        routerLink="/recentUpdates"
        class="btn btn-link"
        id="linkBackTo"
      >
        <i class="fas fa-arrow-circle-left"></i> Go to Recent Updates</a>
    </div>
      <div class="row truckingTitle">
          <div class="col-md-6">
            <h2><i class="fas fa-route fa-2x"></i>&nbsp; Tracking Containers</h2>
          </div>
      </div>
        <div class="myContainer">
          <!--  ####### Search Box Area ######## -->
          <mat-card class="SearchBox  d-flex align-items-stretch" >        
                    <mat-card-content class="SearchBox2  d-flex align-items-stretch">
                      <form #mySearchForm="ngForm" (ngSubmit)="onSearchSubmit(mySearchForm)">
                          <div class="row">
                            <div class="searchItemDDL">
                                <mat-form-field class="containerSearchSm">
                                  <mat-label>Status</mat-label>
                                  <mat-select
                                    name="Status"
                                    [(ngModel)]="cargoHeader.Status"
                                  >
                                    <mat-option
                                      *ngFor="let status of statuses"
                                      [value]="status.value"
                                    >
                                      {{ status.display }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="searchItem">                
                                <mat-form-field class="containerSearchLg">
                                  <mat-label>Invoice #</mat-label>
                                  <input
                                    [(ngModel)]="cargoHeader.InvoiceNo"
                                    name="InvoiceNo"
                                    matInput
                                    placeholder="Invoice#"
                                    class="matInputBox"
                                  />
                                </mat-form-field>               
                            </div>
                            <div class="searchItem">
                                <mat-form-field class="containerSearchLg">
                                  <mat-label>Container #</mat-label>
                                  <input
                                    [(ngModel)]="cargoHeader.ContainerNo"
                                    name="ContainerNo"
                                    matInput
                                    placeholder="Container#"
                                    class="matInputBox"
                                  />
                                </mat-form-field>
                            </div>
                            <div class="searchItem">
                                <mat-form-field class="containerSearchLg">
                                  <mat-label>BL #</mat-label>
                                  <input
                                    [(ngModel)]="cargoHeader.BLNo"
                                    name="BLNo"
                                    matInput
                                    placeholder="BL#"
                                    class="matInputBox"
                                  />
                                </mat-form-field>
                            </div>
                            <div class="searchItem">
                                <mat-form-field class="containerSearchLg">
                                  <mat-label>Vessel Name</mat-label>
                                  <input
                                    [(ngModel)]="cargoHeader.VesselName"
                                    name="VesselName"
                                    matInput
                                    placeholder="Vessel Name"
                                    class="matInputBox"
                                  />
                                </mat-form-field>
                            </div>
                            <div class="searchItem">
                                <mat-form-field class="containerSearchLg">
                                  <mat-label>Voyage#</mat-label>
                                  <input
                                    [(ngModel)]="cargoHeader.VoyageNo"
                                    name="VoyageNo"
                                    matInput
                                    placeholder="PO#"
                                    class="matInputBox"
                                  />
                                </mat-form-field>
                            </div> 
                            <div class="searchItem">
                                <mat-form-field class="containerSearchLg">
                                  <mat-label>Factory #</mat-label>
                                  <input
                                    [(ngModel)]="cargoHeader.FactoryNo"
                                    name="FactoryNo"
                                    matInput
                                    placeholder="Factory#"
                                    class="matInputBox"
                                  />
                                </mat-form-field>
                            </div>
                            <div class="searchItem">
                                <mat-form-field class="containerSearchLg">
                                  <mat-label>PO #</mat-label>
                                  <input
                                    [(ngModel)]="cargoHeader.PONo"
                                    name="PONo"
                                    matInput
                                    placeholder="PO#"
                                    class="matInputBox"
                                  />
                                </mat-form-field>
                            </div>                   
                            <div  class="searchItem">
                                <mat-form-field class="containerSearchLg">
                                  <mat-label>Part #</mat-label>
                                  <input
                                    [(ngModel)]="cargoHeader.PartNo"
                                    name="PartNo"
                                    matInput
                                    placeholder="Part#"
                                    class="matInputBox"
                                  />
                                </mat-form-field>
                            </div>
                            <!-- <div  class="searchItemDDL">
                                <mat-form-field class="containerSearchSm">
                                  <mat-label>FTZ</mat-label>
                                  <mat-select
                                    name="Status"
                                    [(ngModel)]="cargoHeader.FTZFlag"
                                  >
                                    <mat-option
                                      *ngFor="let FTZFlag of FTZFlags"
                                      [value]="FTZFlag.value"
                                    >
                                      {{ FTZFlag.display }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                            </div> -->
                          </div>
                        <div class="row rowCalendars">
                          <div class="dateColor1">
                              <div>
                                  <mat-form-field class="containerSearch">
                                  <input
                                      matInput
                                      [matDatepicker]="ETDLPStart"
                                      placeholder="ETDLP beginning"
                                      [(ngModel)]="cargoHeader.ETDLPStart"
                                      name="ETDLPStart"
                                      class="matInputBox"
                                  />
                                  <mat-datepicker-toggle
                                      matSuffix
                                      [for]="ETDLPStart"
                                  ></mat-datepicker-toggle>
                                  <mat-datepicker #ETDLPStart></mat-datepicker>
                                  </mat-form-field>
                              </div>
                            </div>
                          <div class="dateColorSub">~</div>
                          <div class="dateColor1">
                              <div>
                                  <mat-form-field class="containerSearch">
                                  <input
                                      matInput
                                      [matDatepicker]="ETDLPEnd"
                                      placeholder="ETDLP ending"
                                      [(ngModel)]="cargoHeader.ETDLPEnd"
                                      name="ETDLPEnd"
                                      class="matInputBox"
                                  />
                                  <mat-datepicker-toggle
                                      matSuffix
                                      [for]="ETDLPEnd"
                                  ></mat-datepicker-toggle>
                                  <mat-datepicker #ETDLPEnd></mat-datepicker>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="dateColorSub2"></div>
                          <div class="dateColor1">               
                            <mat-form-field  class="containerSearch">
                            <input
                                matInput
                                [matDatepicker]="ATDLPStart"
                                placeholder="ATDLP beginning"
                                [(ngModel)]="cargoHeader.ATDLPStart"
                                name="ATDLPStart"
                                class="matInputBox"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="ATDLPStart"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #ATDLPStart></mat-datepicker>
                            </mat-form-field>                 
                          </div>
                          <div class="dateColorSub">~</div>
                          <div class="dateColor1">              
                            <mat-form-field  class="containerSearch">
                            <input
                                matInput
                                [matDatepicker]="ATDLPEnd"
                                placeholder="ATDLP ending"
                                [(ngModel)]="cargoHeader.ATDLPEnd"
                                name="ATDLPEnd"
                                class="matInputBox"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="ATDLPEnd"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #ATDLPEnd></mat-datepicker>
                            </mat-form-field>                   
                          </div>
                          <div class="dateColorSub2"></div>
                          <div class="dateColor1">                
                            <mat-form-field  class="containerSearch">
                            <input
                                matInput
                                [matDatepicker]="ETADPStart"
                                placeholder="ETADP beginning"
                                [(ngModel)]="cargoHeader.ETADPStart"
                                name="ETADPStart"
                                class="matInputBox"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="ETADPStart"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #ETADPStart></mat-datepicker>
                            </mat-form-field>                  
                          </div>
                          <div class="dateColorSub">~</div>
                          <div class="dateColor1">               
                            <mat-form-field class="containerSearch">
                            <input
                                matInput
                                [matDatepicker]="ETADPEnd"
                                placeholder="ETADP ending"
                                [(ngModel)]="cargoHeader.ETADPEnd"
                                name="ETADPEnd"
                                class="matInputBox"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="ETADPEnd"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #ETADPEnd></mat-datepicker>
                            </mat-form-field>                
                          </div>
                          <div class="dateColorSub2"></div>
                          <div class="dateColor1">             
                            <mat-form-field  class="containerSearch">
                            <input
                                matInput
                                [matDatepicker]="ATADPStart"
                                placeholder="ATADP beginning"
                                [(ngModel)]="cargoHeader.ATADPStart"
                                name="ATADPStart"
                                class="matInputBox"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="ATADPStart"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #ATADPStart></mat-datepicker>
                            </mat-form-field>                
                          </div>
                          <div class="dateColorSub">~</div>
                          <div class="dateColor1">              
                            <mat-form-field  class="containerSearch">
                            <input
                                matInput
                                [matDatepicker]="ATADPEnd"
                                placeholder="ATADP ending"
                                [(ngModel)]="cargoHeader.ATADPEnd"
                                name="ATADPEnd"
                                class="matInputBox"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="ATADPEnd"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #ATADPEnd></mat-datepicker>
                            </mat-form-field>                
                          </div>
                          <div class="dateColorSub2"></div>
                          <div class="row" id="btnGroup">
                              <button
                                  [disabled]="!mySearchForm.form.valid"
                                  class="btn btn-primary btnInSearchBox">
                                  <i class="fa fa-search fa-fw"></i> Search</button>
                              <button
                                  (click)="onCancel($event)"
                                  [disabled]="!mySearchForm.form.valid"
                                  class="btn btn-secondary  btnInSearchBox">
                                  Clear
                              </button>
                              <div>
                              <button (click)="exportToExcel()" 
                              class="btn btn-success btnInSearchBox">
                              Download
                              </button>
                              </div>
                          </div>
                        </div>

                      </form>
                    </mat-card-content>  
          </mat-card>

          <!--  ####### Body Area ######## -->
          <div *ngIf="!editMode">
            <h4 *ngIf="!loaded" class="table loading-message">Loading containers ...</h4>
            <div *ngIf="!loaded">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="row">
              <div>
                <h4 *ngIf="showNumberOfCargo" id="numberOfCargo">
                  {{ cntCargo }} Containers(s) Found
              </h4>
              </div>
              <div 
              *ngIf = "loaded && cargoHeaders?.length>0 && this.authService.checkMTSUser()"
              class="col-md-3 ml-auto">
              <button
                (click)="exportBLToCSV()"
                class="btn btn-success btnBLCSV"
                >
                  BL CSV (MTS Only)
              </button>
                <button
                  (click)="onEdit()"
                  class="btn btn-info btnEdit"
                >
                  Edit (MTS Only)
                </button>
              </div>
            </div>

            <table
              *ngIf="cargoHeaders?.length > 0"
              class="table table-striped table-bordered table-hover"
              matSort
              (matSortChange)="sortData($event)"
              id="LoadList"
            >
              <thead class="thead-inverse">
                <tr class="tableStatus">
                  <th mat-sort-header="InvoiceNo" class="sortColumnMd colMd">Invoice#</th>
                  <th mat-sort-header="ContainerNo" class="sortColumnMd colMd">Container#</th>
                  <th mat-sort-header="BLNo" class="sortColumnMd colMd">BL#</th>
                  <th mat-sort-header="VesselName" class="sortColumnLg colLg">Vessel Name</th>
                  <th mat-sort-header="VoyageNo" class="sortColumnSm colSm">Voyage#</th>
                  <th>LP</th>          
                  <th>ETD LP</th>
                  <th>ATD LP</th>
                  <th>DP</th>
                  <th>ETA DP</th>
                  <th>ATA DP</th>
                  <th>Customs<br> Clearance</th>
                  <!-- <th>FTZ</th> -->
                  <th>Status</th>
                  <th>Last Free<br>Date</th>
                  <th>Container<br>Appointment</th>
                  <th>Container<br>Pickup</th>
                  <th>Delivery<br>Completion</th>
                  <th *ngIf = "this.authService.checkMTSUser()" 
                  >Empty<br>Return Date</th>
                  <th>Note</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cargoHeader of cargoHeaders" class="tableStatus">
                  <td>
                    <a
                      routerLink="/cargo/cargo-detail/{{ cargoHeader.InvoiceNo }}"
                      >{{ cargoHeader.InvoiceNo }}</a
                    >
                  </td> 
                  <td>
                    <a
                      routerLink="/cargo/cargo-detail/{{ cargoHeader.InvoiceNo }}&{{cargoHeader.ContainerNo }}"
                      >{{  cargoHeader.ContainerNo }}</a
                    >
                  </td>
                  <td>
                    {{ cargoHeader.BLNo }}
                  </td>
                  <td  class="columnLg">
                    {{ cargoHeader.VesselName}}
                  </td>
                  <td>
                    {{ cargoHeader.VoyageNo }}
                  </td>
                  <td>
                    {{ cargoHeader.LoadingPort }}
                  </td>
                  <td><div [ngClass]="
                      cargoHeader.ETDLPRevised == null ? 'dateNotChanged' : 'dateChanged'"
                    >
                    {{
                      cargoHeader.ETDLP == null
                        ? ""
                        : (cargoHeader.ETDLP | date: "MM/dd/yy")
                    }}
                    <div *ngIf="cargoHeader.ETDLPRevised!=null"><i class="far fa-hand-point-right"></i>
                    {{
                      (cargoHeader.ETDLPRevised | date: "MM/dd/yy") 
                    }}
                  </div>
                  </div>
                </td>
                  <td>
                    {{ cargoHeader.ATDLP  | date: 'MM/dd/yy'}}
                  </td>
                  <td>
                    {{ cargoHeader.DischargingPort }}
                  </td>  
                  <td><div [ngClass]="
                    cargoHeader.ETADPRevised == null ? 'dateNotChanged' : 'dateChanged'"
                  >
                  {{
                    cargoHeader.ETADP == null
                      ? ""
                      : (cargoHeader.ETADP | date: "MM/dd/yy")
                  }}
                  <div *ngIf="cargoHeader.ETADPRevised!=null"><i class="far fa-hand-point-right"></i>
                  {{
                    (cargoHeader.ETADPRevised | date: "MM/dd/yy") 
                  }}
                </div>
                </div>
              </td>

                  <td>
                    {{ cargoHeader.ATADP  | date: 'MM/dd/yy'}}
                  </td>
                  <td>
                    {{ cargoHeader.CustomsClearanceDate  | date: 'MM/dd/yy'}}
                  </td>
                  <!-- <td>
                    {{ cargoHeader.FTZFlag }}
                  </td> -->
                  <td>
                    {{ cargoHeader.Status }}
                  </td>
                  <td>
                    {{ cargoHeader.LastFreeDate  | date: 'MM/dd/yy'}}
                  </td>     
                  <td>
                    {{ cargoHeader.AppointmentDate  | date: 'MM/dd/yy'}}
                  </td>         
                    <td>
                      {{ cargoHeader.ContainerPickedUpDate  | date: 'MM/dd/yy'}}<br>
                   </td>
                  <td>
                      {{ cargoHeader.DeliveryCompletion  | date: 'MM/dd/yy'}}
                  </td>
                  <td *ngIf = "this.authService.checkMTSUser()">
                      {{ cargoHeader.EmptyReturnDate  | date: 'MM/dd/yy'}}
                  </td>
                  <td>
                    {{ cargoHeader.MTSComment}}
                  </td>
                  <td>
                {{(cargoHeader.UpdatedDate!=null?cargoHeader.UpdatedDate :cargoHeader.UploadedDate)   | date:'short'   }}
                  </td>
                </tr>
              </tbody>
            </table>   
            <h4 *ngIf="cargoHeaders?.length == 0">No container found</h4>
          </div>  
          <!-- ******************************************************************************
              Non Edit Mode (Above) & Edit Mode (Below)
          ******************************************************************************** -->
          <div *ngIf="editMode">
            <h4 *ngIf="!loaded" class="table loading-message">Loading containers...</h4>
            <div *ngIf="!loaded">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="row">
              <div class="col-mg-7 ml-auto">
                <button (click)="onBack()" class="btn btn-danger btnEdit">
                  Back
                </button>
                &nbsp;&nbsp;
                <button (click)="onSave()" class="btn btn-warning btnEdit">
                  Save
                </button>
                &nbsp;&nbsp;&nbsp;
              </div>
          </div>
          <table
          *ngIf="cargoHeaders?.length > 0"
          class="table table-striped table-bordered table-hover"
          matSort
          (matSortChange)="sortData($event)"
          id="LoadList"
        >
              <thead class="thead-inverse">
                <tr class="tableStatus">
                  <th mat-sort-header="InvoiceNo">Invoice#</th>
                  <th mat-sort-header="ContainerNo">Container#</th>
                  <th mat-sort-header="BLNo">BL#</th>
                  <th mat-sort-header="VesselName">Vessel Name</th>
                  <th mat-sort-header="VoyageNo">Voyage#</th>
                  <th>LP</th>          
                  <th>ETD LP</th>
                  <th>Revised ETD</th>
                  <th>ATD LP</th>
                  <th>DP</th>
                  <th>ETA DP</th>
                  <th>Revised ETA</th>
                  <th>ATA DP</th>
                  <th>Customs<br> Clearance</th>
                  <!-- <th>FTZ</th> -->
                  <th>Status</th>
                  <th>Last Free<br>Date</th>
                  <th>Container<br>Appointment</th>
                  <th>Container<br>Pickup</th>
                  <th>Delivery<br>Completion</th>
                  <th>Empty<br>Return Date</th>
                  <th>Note</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
          <tbody>
            <tr *ngFor="let cargoHeader of cargoHeaders" class="tableStatus">
                <td>
                {{ cargoHeader.InvoiceNo }}
                </td>
                <td>
                {{ cargoHeader.ContainerNo }}
                </td>

                <td>
                {{ cargoHeader.BLNo }}
                </td>
                <td  class="columnLg">
                {{ cargoHeader.VesselName}}
                </td>
                <td>
                {{ cargoHeader.VoyageNo }}
                </td>
                <td>
                {{ cargoHeader.LoadingPort }}
                </td>
                <td>
                  {{ cargoHeader.ETDLP | date: 'MM/dd/yy' }}
                </td>

            <td class="editColumn">
              <mat-form-field class="truckloadSearchXsm">
                <input
                    matInput
                    [matDatepicker]="ETDLPRevised"
                    placeholder=""
                    [(ngModel)]="cargoHeader.ETDLPRevised"
                
                    name="ETDLPRevised"
                    class="matInputBoxEdit"
                    dateFormat="mm/dd/yy"
                    (dateChange)="dateChangedForEdit(cargoHeader.ContainerNo)"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="ETDLPRevised"
                ></mat-datepicker-toggle>
                <mat-datepicker #ETDLPRevised></mat-datepicker>
                </mat-form-field>
            </td>

            <td class="editColumn">
                <mat-form-field class="truckloadSearchXsm">
                  <input
                      matInput
                      [matDatepicker]="ATDLP"
                      placeholder=""
                      [(ngModel)]="cargoHeader.ATDLP"
                   
                      name="ATDLP"
                      class="matInputBoxEdit"
                      dateFormat="mm/dd/yy"
                      (dateChange)="dateChangedForEdit(cargoHeader.ContainerNo)"
                  />
                  <mat-datepicker-toggle
                      matSuffix
                      [for]="ATDLP"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #ATDLP></mat-datepicker>
                  </mat-form-field>
              </td>
              <td>
                {{ cargoHeader.DischargingPort }}ng
              </td>
              <td>
                {{ cargoHeader.ETADP | date: 'MM/dd/yy' }}
              </td>
                <td class="editColumn">
                    <mat-form-field class="truckloadSearchXsm">
                      <input
                          matInput
                          [matDatepicker]="ETADPRevised"
                          placeholder=""
                          [(ngModel)]="cargoHeader.ETADPRevised"
                     
                          name="ETADPRevised"
                          class="matInputBoxEdit"
                          dateFormat="mm/dd/yy"
                          (dateChange)="dateChangedForEdit(cargoHeader.ContainerNo)"
                      />
                      <mat-datepicker-toggle
                          matSuffix
                          [for]="ETADPRevised"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #ETADPRevised></mat-datepicker>
                      </mat-form-field>
                  </td>
      
                  <td class="editColumn">
                      <mat-form-field class="truckloadSearchXsm">
                        <input
                            matInput
                            [matDatepicker]="ATADP"
                            placeholder=""
                            [(ngModel)]="cargoHeader.ATADP"
                        
                            name="ATADP"
                            class="matInputBoxEdit"
                            dateFormat="mm/dd/yy"
                            (dateChange)="dateChangedForEdit(cargoHeader.ContainerNo)"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="ATADP"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #ATADP></mat-datepicker>
                        </mat-form-field>
                    </td>

                    <td class="editColumn">
                        <mat-form-field class="truckloadSearchXsm">
                          <input
                              matInput
                              [matDatepicker]="CustomsClearanceDate"
                              placeholder=""
                              [(ngModel)]="cargoHeader.CustomsClearanceDate"
                     
                              name="CustomsClearanceDate"
                              class="matInputBoxEdit"
                              dateFormat="mm/dd/yy"
                              (dateChange)="dateChangedForEdit(cargoHeader.ContainerNo)"
                          />
                          <mat-datepicker-toggle
                              matSuffix
                              [for]="CustomsClearanceDate"
                          ></mat-datepicker-toggle>
                          <mat-datepicker #CustomsClearanceDate></mat-datepicker>
                          </mat-form-field>
                      </td>
                      <!-- <td>
                        {{ cargoHeader.FTZFlag }}
                      </td> -->
                      <td>
                        {{ cargoHeader.Status }}
                        </td>

                        <td class="editColumn">
                          <mat-form-field class="truckloadSearchXsm">
                            <input
                                matInput
                                [matDatepicker]="LastFreeDate"
                                placeholder=""
                                [(ngModel)]="cargoHeader.LastFreeDate"
                          
                                name="LastFreeDate"
                                class="matInputBoxEdit"
                                dateFormat="mm/dd/yy"
                                (dateChange)="dateChangedForEdit(cargoHeader.ContainerNo)"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="LastFreeDate"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #LastFreeDate></mat-datepicker>
                            </mat-form-field>
                        </td>


                        <td class="editColumn">
                            <mat-form-field class="truckloadSearchXsm">
                              <input
                                  matInput
                                  [matDatepicker]="AppointmentDate"
                                  placeholder=""
                                  [(ngModel)]="cargoHeader.AppointmentDate"
                                
                                  name="AppointmentDate"
                                  class="matInputBoxEdit"
                                  dateFormat="mm/dd/yy"
                                  (dateChange)="dateChangedForEdit(cargoHeader.ContainerNo)"
                              />
                              <mat-datepicker-toggle
                                  matSuffix
                                  [for]="AppointmentDate"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #AppointmentDate></mat-datepicker>
                              </mat-form-field>
                          </td>


                          <td class="editColumn">
                            <mat-form-field class="truckloadSearchXsm">
                              <input
                                  matInput
                                  [matDatepicker]="ContainerPickedUpDate"
                                  placeholder=""
                                  [(ngModel)]="cargoHeader.ContainerPickedUpDate"
                                
                                  name="ContainerPickedUpDate"
                                  class="matInputBoxEdit"
                                  dateFormat="mm/dd/yy"
                                  (dateChange)="dateChangedForEdit(cargoHeader.ContainerNo)"
                              />
                              <mat-datepicker-toggle
                                  matSuffix
                                  [for]="ContainerPickedUpDate"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #ContainerPickedUpDate></mat-datepicker>
                              </mat-form-field>
                          </td>


                          <td class="editColumn">
                            <mat-form-field class="truckloadSearchXsm">
                              <input
                                  matInput
                                  [matDatepicker]="DeliveryCompletion"
                                  placeholder=""
                                  [(ngModel)]="cargoHeader.DeliveryCompletion"
                         
                                  name="DeliveryCompletion"
                                  class="matInputBoxEdit"
                                  dateFormat="mm/dd/yy"
                                  (dateChange)="dateChangedForEdit(cargoHeader.ContainerNo)"
                              />
                              <mat-datepicker-toggle
                                  matSuffix
                                  [for]="DeliveryCompletion"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #DeliveryCompletion></mat-datepicker>
                              </mat-form-field>
                          </td>


                          <td class="editColumn">
                            <mat-form-field class="truckloadSearchXsm">
                              <input
                                  matInput
                                  [matDatepicker]="EmptyReturnDate"
                                  placeholder=""
                                  [(ngModel)]="cargoHeader.EmptyReturnDate"
                         
                                  name="EmptyReturnDate"
                                  class="matInputBoxEdit"
                                  dateFormat="mm/dd/yy"
                                  (dateChange)="dateChangedForEdit(cargoHeader.ContainerNo)"
                              />
                              <mat-datepicker-toggle
                                  matSuffix
                                  [for]="EmptyReturnDate"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #EmptyReturnDate></mat-datepicker>
                              </mat-form-field>
                          </td>
                          <td class="editColumnLg">
                            <mat-form-field class="truckloadSearchLg">
                              <textarea
                                matInput
                                placeholder=""
                                [(ngModel)]="cargoHeader.MTSComment"
                                id="MISAMemoInGrid"
                                name="MTSComment"
                                class="matInputBoxEditLg"
                                (ngModelChange)="dateChangedForEdit(cargoHeader.ContainerNo)"
                              ></textarea>
                            </mat-form-field>
                          </td>
                          <td>
                            {{ cargoHeader.UpdatedDate!=null?cargoHeader.UpdatedDate:cargoHeader.UploadedDate | date: 'MM/dd/yy HH:mm:ss'}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
        
            <h4 *ngIf="cargoHeaders?.length == 0">No container found</h4>
          </div>

          
  </div>
 <!-- ############################################################################# -->
  
  
</div>