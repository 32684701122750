import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRoute } from "@angular/router";
import { AppConfig } from "../app.config";
import { TokenResponse } from "../models/tokenResponse";
import { User} from '../models/user';

@Injectable()
export class AuthGuard implements CanActivate {
  authKey: string = this.config.keyMyAuth;
  tokenHolder: TokenResponse;
  requestingUser:User;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private config: AppConfig
  ) {}

  canActivate() {
    var user = sessionStorage.getItem(this.authKey);
    if (user != null) {
      return true;
    }else if(localStorage.getItem("passwordRecoveryAskingUser")!=null){
      this.requestingUser = JSON.parse(localStorage.getItem("passwordRecoveryAskingUser"));
      this.router.navigate(["/new-password/"+this.requestingUser.Email]);
      return false;
    }else{
    this.router.navigate(["/login"]);
    return false;
    }
  }
}