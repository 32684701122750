import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from "angular2-flash-messages";
import { Router, ActivatedRoute } from "@angular/router";
import { CargoService } from "../../../services/cargo/cargo.service";
import { AuthService } from "../../../services/auth/auth.service";
import { CargoHeader } from "../../../models/cargoHeader";
import { CargoDetail } from "../../../models/cargoDetail";
import { Sort } from "@angular/material/sort";
import { User} from '../../../models/user';
import { FileService} from '../../../services/file/file.service';
import {AfterViewInit,  ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-bl-header',
  templateUrl: './bl-header.component.html',
  styleUrls: ['./bl-header.component.css']
})
export class BlHeaderComponent  {
 // displayedColumns: string[] = ['BLNo', 'VesselName', 'VoyageNo','LoadingPort', 'ETDLP','ATDLP','DischargingPort','ETADP','ATADP'];
  displayedColumns: string[] = ['BLNo', 'VesselName', 'VoyageNo','LoadingPort'];
  dataSource: MatTableDataSource<CargoHeader>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public statuses = [
    { value: "all", display: "All" },
    { value: "Incoming", display: "Incoming" },
    { value: "DoorDelivered", display: "Door Delivered" }
  ];

  myChoiceWhatsNew:null;
  loaded: boolean = false;
  loading = false;
  token: string;
  searchHash: string;
  documentId: string;
  documentHash: string;
  showNumberOfCargo: boolean = false;
  cntCargo: number = null;
  dateChangedContainers:string[]=[];

  cargoHeader:CargoHeader={
    CargoHeaderId:0,
    InvoiceNo:null,
    ContainerNo:null,
    ContainerType:null,
    BLNo:null,
    HouseBLNo:null,
    BLDate:null,
    ETDLP:null,
    ETDLPRevised:null,
    ATDLP :null,
    ETADP :null,
    ETADPRevised :null,
    ATADP :null,
    ETADoor :null,
    ETADoorRevised:null,
    ATADoor:null,
    AppointmentDate:null,
    GateOutDate:null,
    LastFreeDate:null,
    EmptyReturnDate:null,
    Commodity:null,
    DeliveryTerm:null,
    Currency:null,
    Status:null,
    CarrierName:null,
    VesselName :null,
    VoyageNo:null,
    PlaceOfReceipt:null,
    OriginCountry:null,
    LoadingPort:null,
    DischargingPort :null,
    Via :null,
    FinalDestination :null,
    Remarks:null,
    MTSComment :null,
    CargoType:null,  
    InlandMot:null,
    DomesticCarrierName:null,
    ETDLPStart:null,
    ETDLPEnd:null,
    ATDLPStart:null,
    ATDLPEnd:null,
    ETADPStart:null,
    ETADPEnd:null,
    ATADPStart:null,
    ATADPEnd :null,
    CustomsClearanceDate:null,
    ETADoorStart:null,
    ETADoorEnd :null,
    ATADoorStart :null,
    ATADoorEnd :null,
    CurrentLocation:null,
    CurrentEvent:null,
    StampDate:null,
    ContainerPickedUpDate:null,
    DeliveryCompletion:null,
    SealNo:null,
    CaseQty:null,
   //detail
    FactoryNo :null,
    PONo:null,
    PartNo :null,
    FTZFlag:null,
    UploadedDate:null,
    UpdatedDate:null
  }
  cargoHeaders:CargoHeader[];
  
  constructor(
    public authService: AuthService,
    private cargoService: CargoService,
    private router: Router,
    private flashMessage: FlashMessagesService,
    private route: ActivatedRoute,
    private fileService:FileService
  ) { }

  cargo:any={};
  ngOnInit() {
      // this.cargo = JSON.parse(sessionStorage.getItem("mySearchingBL"));
      // if (this.cargo != null) {
      //   this.cargoHeader.BLNo       = this.cargo.myCargo.BLNo;
      //   this.cargoHeader.VesselName = this.cargo.myCargo.VesselName;
      //   this.cargoHeader.VoyageNo   = this.cargo.myCargo.VoyageNo;
      //   this.cargoHeader.Status     = this.cargo.myCargo.Status;
      //   this.cargoHeader.ATDLPStart = this.cargo.myCargo.ATDLPStart;
      //   this.cargoHeader.ATDLPEnd   = this.cargo.myCargo.ATDLPEnd;
      //   this.cargoHeader.ETDLPStart = this.cargo.myCargo.ETDLPStart;
      //   this.cargoHeader.ETDLPEnd   = this.cargo.myCargo.ETDLPEnd;
      //   this.cargoHeader.ETADPStart = this.cargo.myCargo.ETADPStart;
      //   this.cargoHeader.ETADPEnd   = this.cargo.myCargo.ETADPEnd;
      //   this.cargoHeader.ATADPStart = this.cargo.myCargo.ATADPStart;
      //   this.cargoHeader.ATADPEnd   = this.cargo.myCargo.ATADPEnd;
      //   this.getCargoHeadersBySearchKeys(this.cargoHeader);
      // } else {                                       
      //   this.GetCargoHeadersArrivedToday();
      // }
  }

  // GetCargoHeadersArrivedToday() {
  //   this.showNumberOfCargo = false;
  //   this.cargoService.getCargoHeadersArrivedToday().subscribe(
  //     results => {
  //       this.cargoHeaders = results;

  //       // Assign the data to the data source for the table to render
  //       this.dataSource = new MatTableDataSource(this.cargoHeaders);


        
  //       var tempInvoiceNo;
  //       var cnt = 0;
  //       this.cargoHeaders.forEach(element => {
  //         if (tempInvoiceNo != element) {
  //           tempInvoiceNo = element.InvoiceNo;
  //           cnt++;
  //         }
  //         this.cntCargo = cnt;
  //       });
  //       this.loaded = true;
  //       this.flashMessage.show(
  //         cnt + " BL(s) arrived discharging port today",
  //         {
  //           cssClass: "alert-success",
  //           timeout: 8000
  //         }
  //       );
  //     },
  //     error => {
  //       this.flashMessage.show(
  //         "Error: Cannot receive BL information arriving discharging port today."+ error.message,
  //         {
  //           cssClass: "alert-danger",
  //           timeout: 8000
  //         }
  //       );
  //       this.loaded = true;
  //     }
  //   );
  // }

  // onSearchSubmit({ value, valid }: { value: CargoHeader; valid: boolean }) {
  //   this.cargoHeaders = null;
  //   this.cntCargo = 0;
  //   this.cargoHeader.Status =
  //   value.Status == null
  //     ? null
  //     : value.Status == ""
  //     ? null
  //     : value.Status.trim();
  //   this.cargoHeader.BLNo =
  //     value.BLNo == null
  //       ? null
  //       : value.BLNo.trim() == ""
  //       ? null
  //       : value.BLNo.trim();
  //   this.cargoHeader.VesselName =
  //   value.VesselName == null
  //     ? null
  //     : value.VesselName.trim() == ""
  //     ? null
  //     : value.VesselName.trim();
  //   this.cargoHeader.VoyageNo =
  //   value.VoyageNo == null
  //     ? null
  //     : value.VoyageNo.trim() == ""
  //     ? null
  //     : value.VoyageNo.trim();  
  //   this.cargoHeader.ETDLPEnd =   
  //     value.ETDLPEnd == null
  //     ? null
  //     : value.ETDLPEnd.toString().trim() == ""
  //     ? null
  //     : value.ETDLPEnd;  
  //   this.cargoHeader.ATDLPStart =   
  //     value.ATDLPStart == null
  //       ? null
  //       : value.ATDLPStart.toString().trim() == ""
  //       ? null
  //       : value.ATDLPStart;    
  //   this.cargoHeader.ATDLPEnd =   
  //     value.ATDLPEnd == null
  //     ? null
  //     : value.ATDLPEnd.toString().trim() == ""
  //     ? null
  //     : value.ATDLPEnd;
  //   this.cargoHeader.ETADPStart =   
  //     value.ETADPStart == null
  //       ? null
  //       : value.ETADPStart.toString().trim() == ""
  //       ? null
  //       : value.ETADPStart;    
  //   this.cargoHeader.ETADPEnd =   
  //     value.ETADPEnd == null
  //     ? null
  //     : value.ETADPEnd.toString().trim() == ""
  //     ? null
  //     : value.ETADPEnd; 
  //   this.cargoHeader.ATADPStart =   
  //     value.ATADPStart == null
  //       ? null
  //       : value.ATADPStart.toString().trim() == ""
  //       ? null
  //       : value.ATADPStart;    
  //   this.cargoHeader.ATADPEnd =   
  //     value.ATADPEnd == null
  //     ? null
  //     : value.ATADPEnd.toString().trim() == ""
  //     ? null
  //     : value.ATADPEnd;
  //   if (!valid) {
  //     this.loaded = true;
  //     this.flashMessage.show("Please fill out the form correctly", {
  //       cssClass: "alert-danger",
  //       timeout: 5000
  //     });
  //   } else if (
  //     value.Status == null &&
  //     value.BLNo == null &&
  //     value.VesselName == null &&
  //     value.VoyageNo == null &&
  //     value.ETDLPStart == null &&
  //     value.ETDLPEnd == null &&
  //     value.ATDLPStart == null &&
  //     value.ATDLPEnd == null &&
  //     value.ETADPStart == null &&
  //     value.ETADPEnd == null &&
  //     value.ATADPStart == null &&
  //     value.ATADPEnd == null 
  //   ) {
  //     this.loaded = true;
  //     this.flashMessage.show("All seach key is blank", {
  //       cssClass: "alert-danger",
  //       timeout: 6000
  //     });
  //   } else {
  //     console.log("Status: "+  value.Status);
  //     console.log("BL# :" + value.BLNo);
  //     console.log("Status :" + value.Status);
  //     console.log("ETDLP Start:"+value.ETDLPStart);
  //     console.log("ETDLP End:"+value.ETDLPEnd);
  //     console.log("ATDLP Start:"+value.ATDLPStart);
  //     console.log("ATDLP End:"+value.ATDLPEnd);   
  //     console.log("ETADP Start:" + value.ETADPStart);
  //     console.log("ETADP End :" + value.ETADPEnd);
  //     console.log("ATADP Start:" + value.ATADPStart);
  //     console.log("ATADP End :" + value.ATADPEnd);

  //     sessionStorage.removeItem("mySearchingBL");
  //     sessionStorage.setItem(
  //       "mySearchingBL",
  //       JSON.stringify({ myCargo: this.cargoHeader })
  //     );
  //     // this.getCargoBySearchKey(this.cargoHeader);
  //     this.getCargoHeadersBySearchKeys(this.cargoHeader);
  //   }
  // }

  // getCargoHeadersBySearchKeys(searchingCargo: CargoHeader){ 
  //   this.loaded=false;
  //   this.showNumberOfCargo = false;
  //   this.cargoService
  //     .getCargoHeadersBySearchKeys(searchingCargo)
  //     .subscribe(results => {
  //       this.cargoHeaders = results;
  //       // Assign the data to the data source for the table to render
  //       this.dataSource = new MatTableDataSource(this.cargoHeaders);



  //       var tempInvoiceNo;
  //       var cnt = 0;
  //       this.cargoHeaders.forEach(element => {
  //         if (tempInvoiceNo != element) {
  //           tempInvoiceNo = element.InvoiceNo;
  //           cnt++;
  //         }
  //         this.cntCargo = cnt;
  //       });
  //       this.loaded = true;
  //       this.showNumberOfCargo = true;
  //     },        
  //     error => {
  //       this.flashMessage.show("No data found. " + error.message, {
  //         cssClass: "alert-danger",
  //         timeout: 6000
  //       });
  //       this.loaded = true;
  //       this.showNumberOfCargo = true
  //     }
  //     );
  // }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();

  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }

}

