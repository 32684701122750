<nav class="navbar navbar-expand-md navbar-dark " id="navBar">
  <a routerLink="/top" class="navbar-brand" id="SiteName">&nbsp;&nbsp;DHTrack2</a>
  <div class="collapse navbar-collapse" id="navbarMain">

    <ul class="navbar-nav mr-auto navMenuList">
      <li *ngIf="this.authService.isLoggedIn()" class="nav-item dropdown myHomeMenu">
        <a class="nav-item myHomeMenu" href="#" routerLink="top">
          Home
        </a>
      </li> 
      <li *ngIf="this.authService.isLoggedIn()" class="nav-item dropdown myNavMenu">
        <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
          Recent  Updates
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#" routerLink="/recentUpdates">Recent Updates</a>
        </div>
      </li>

      <li *ngIf="this.authService.isLoggedIn()" class="nav-item dropdown myNavMenu">
        <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
          Tracking
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#" routerLink="cargo/cargo-main-header">BL</a>
          <a class="dropdown-item" href="#" routerLink="cargo/cargo-header">Container</a>
        </div>
      </li>
      <li *ngIf="this.authService.isLoggedIn() && this.authService.checkMTSUser()==true  " class="nav-item dropdown myNavMenu">
        <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
          Entry
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#" routerLink="entry/FileUpload">File Upload</a>
        </div>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#" routerLink="entry/FileUpload">Container Appointment</a>
        </div>
      </li>
      <li *ngIf="this.authService.isLoggedIn()" class="nav-item dropdown myNavMenu">
        <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
          Dashboard
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#" routerLink="dashboard/dashboard">Dashboard Sample1</a>
          <a class="dropdown-item" href="#" routerLink="dashboard/dashboard2">Dashboard Sample2</a>
          <a class="dropdown-item" href="#" routerLink="dashboard/dashboard3">Dashboard Sample3</a>
        </div>
      </li>
    </ul>  

    <ul class="navbar-nav ml-auto" id="myProfileGroup">
      <li *ngIf="!this.authService.isLoggedIn()" class="nav-item">
        <a class="nav-link" href="#" routerLink="/register">Register</a>
      </li>
      <li *ngIf="!this.authService.isLoggedIn()" class="nav-item">
        <a class="nav-link" href="#" routerLink="/login">Login</a>
      </li>
      <li *ngIf="this.authService.isLoggedIn()" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
          Hi, {{ this.authService.getLoginUserFirstName() }}
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#" routerLink="/myAccount">My Account</a>
          <a class="dropdown-item" (click)="onClickLogOut()">Log Out</a>
        </div>
      </li>
    </ul> 

    <!-- <div class="spacer">
      <a href="mailto:support@marubeni-trans.com?cc=mtsit@marubeni-trans.com&subject=Hi, MTS!"><button
          mat-button id="btnContactUs">
          <i class="far fa-envelope  fa-2x"></i>&nbsp;&nbsp;Contact MTS
        </button></a>
    </div> -->
  </div>
</nav>