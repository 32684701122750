<div class="w3layouts-main"> 
	<div class="bg-layer">
		<h1>&nbsp;</h1>
		<div class="header-main">
			<div class="main-icon">
				<div id="mtsLogo">
					<img src="../../assets/images/logo-head.png" id="logoImage">
				</div>
				<br><br><br>
			</div>
			<!-- <div class="header-left-bottom">
				<form 
				name="form"
				(ngSubmit)="myForm.form.valid && onSubmit()"
				#myForm="ngForm"
				novalidate
				class="loginForm"
				>
					<div class="icon1">
						<i class="fas fa-user fa-2x"></i>&nbsp;&nbsp;
						<input type="email" placeholder="Email Address" required=""/>
						<input
						matInput
						placeholder="Email"
						type="email"
						name="Email"
						[(ngModel)]="Email"
						#myEmail="ngModel"
						required
						class="matInputField"
					  />
					
					</div>
					<div class="icon1">
						<i class="fa fa-lock fa-2x"></i>&nbsp;&nbsp;
						<input type="password" placeholder="Password" required=""/>
					</div>
					<div class="login-check">
						 <label class="checkbox"><input type="checkbox" name="checkbox" checked=""><i> </i> Keep me logged in</label>
					</div>
					<div class="bottom">
						<button class="btn">Log In</button>
					</div>
					<div class="links">
						<p><a href="#">Forgot Password?</a></p>
						<p class="right"><a href="#">New User? Register</a></p>
						<div class="clear"></div>
					</div>
				</form>	
			</div> -->
			<!-- ******************************************************************** -->
			<div >
				<form
				name="form"
				(ngSubmit)="myForm.form.valid && onSubmit()"
				#myForm="ngForm"
				novalidate
				class="loginForm"
			  ><i class="fas fa-user fa-2x"></i>&nbsp;
				<mat-form-field id="LoginInput">					
				  <input
					matInput
					placeholder="Email"
					type="email"
					name="Email"
					[(ngModel)]="Email"
					#myEmail="ngModel"
					required
					class="matInputField"
				  />
				  <mat-error *ngIf="myEmail.invalid">{{
					getErrorMessage()
				  }}</mat-error>
				</mat-form-field>
				<br /><br />
				<i class="fa fa-lock fa-2x"></i>&nbsp;
				<mat-form-field id="LoginInput">
				  <input
					matInput
					placeholder="Password"
					type="password"
					name="Password"
					[(ngModel)]="Password"
					#myPassword="ngModel"
					required
					class="matInputField"
				  />
				</mat-form-field>
				<br />
				<br />
				<button  [disabled]="loading" class="btnLogin">Login</button>
				<img
				  *ngIf="loading"
				  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
				/>
				<img *ngIf="loading" />
				<br><br><br>
				<a [routerLink]="['/register']" class="btn btn-link" >Register</a>&nbsp;&nbsp;
				<a [routerLink]="['/reset-password']" class="btn btn-link">Forgot password?</a>
			  </form>
			</div>
		</div>
	</div>
</div>	