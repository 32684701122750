import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from "angular2-flash-messages";
import { Router, ActivatedRoute } from "@angular/router";
import { CargoService } from "../../../services/cargo/cargo.service";
import { AuthService } from "../../../services/auth/auth.service";
import { Sort } from "@angular/material/sort";
import {FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { CargoHeader } from "../../../models/cargoHeader";
import { CargoDetail } from "../../../models/cargoDetail";
import { MatRadioChange } from '@angular/material/radio';

interface TempContainer {
  tempContNo: string;
  tempContType: string;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  loaded: boolean = true;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  mayMoveForwardUploadingFile:boolean=false;
  fileToUpload: File = null;
  fileToUploadContainerAppointment:File=null;
  removingInvoiceNo:string;
  myChoice:any;
  showGoodSubmit:boolean;
  recordUploaded:number;
  showRecordUploaded:boolean=false;

  fileChoices = [
    { value: "CLPINV", display: "CLP & Invoice" },
    { value: "ContainerAppointment", display: "Container Appointment" }
  ];

  tempContainer:TempContainer={
    tempContNo:null,
    tempContType:null
  }
  tempContainers:TempContainer[]=[];

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private cargoService:CargoService,
    private flashMessage: FlashMessagesService
    ) { }

    cargoHeader:CargoHeader={
      CargoHeaderId:0,
      InvoiceNo:null,
      ContainerNo:null,
      ContainerType:null,
      BLNo:null,
      HouseBLNo:null,
      BLDate:null,
      ETDLP:null,
      ETDLPRevised:null,
      ATDLP :null,
      ETADP :null,
      ETADPRevised :null,
      ATADP :null,
      ETADoor :null,
      ETADoorRevised:null,
      ATADoor:null,
      AppointmentDate:null,
      GateOutDate:null,
      LastFreeDate:null,
      EmptyReturnDate:null,
      Commodity:null,
      DeliveryTerm:null,
      Currency:null,
      Status:null,
      CarrierName:null,
      VesselName :null,
      VoyageNo:null,
      PlaceOfReceipt:null,
      OriginCountry:null,
      LoadingPort:null,
      DischargingPort :null,
      Via :null,
      FinalDestination :null,
      Remarks:null,
      MTSComment :null,
      CargoType:null,  
      InlandMot:null,
      DomesticCarrierName:null,
      ETDLPStart:null,
      ETDLPEnd:null,
      ATDLPStart:null,
      ATDLPEnd:null,
      ETADPStart:null,
      ETADPEnd:null,
      ATADPStart:null,
      ATADPEnd :null,
      CustomsClearanceDate:null,
      ETADoorStart:null,
      ETADoorEnd :null,
      ATADoorStart :null,
      ATADoorEnd :null,
      CurrentLocation:null,
      CurrentEvent:null,
      StampDate:null,
      ContainerPickedUpDate:null,
      DeliveryCompletion:null,
      SealNo:null,
      CaseQty:null,
       //detail
    FactoryNo :null,
    PONo:null,
    PartNo :null,
    FTZFlag:null,
  UploadedDate:null,
  UpdatedDate:null
    }
    cargoHeaders:CargoHeader[]=[];
    
    cargoDetail:CargoDetail={
      CargoDetailId:0,
      InvoiceNo:null,
      ContainerNo :null,
      BLNo:null,
      VesselName :null,
      VoyageNo:null,
      SealNo :null,
      FactoryNo:null,
      CaseNo :null,
      KeyNo:null,
      ItemNo:null,
      PLNo:null,
      PONo :null,
      PartsDescription:null,
      Qty :null,
      PartNo :null,
      UploadedDate:null,
      UpdatedDate:null
    }
    cargoDetails:CargoDetail[];

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.showGoodSubmit=false;
  }
  myEmailBody:Blob;
  uploadShipmentAdviceFromEmailBody(obj) {
    console.log(obj);
    this.recordUploaded = 0;
    this.showRecordUploaded=false;
    if (obj != null) {
      this.loaded = false;
      var myBlob = new Blob([obj], { type: 'text' });
      this.cargoService.uploadShipmentAdviceFromEmailBody("shipmentInfo.txt", myBlob).subscribe(
        data => {
          this.cargoHeader = data;
          console.log('ID! : ' + this.cargoHeader.CargoHeaderId)
          //this.getCargoContaiersByInvoiceNo(this.cargoHeader.InvoiceNo);        
        },
        error => {
          this.flashMessage.show("Email Body upload failed."+ error.message, {
            cssClass: "alert-danger",
            timeout: 5000
          });
          this.loaded=true
        } 
      );
    }
  }
  clearShipmentAdvice(){
    event.preventDefault(); // This is neccessary to prevent cencel button submit form
    this.cargoHeader.InvoiceNo = null; 
    this.cargoHeader.ETDLP = null;   
    this.cargoHeader.ETADP = null;      
    this.cargoHeader.BLNo = null;     
    this.cargoHeader.VesselName = null;
    this.cargoHeader.VoyageNo=null;   
    this.cargoHeader.LoadingPort = null;   
    this.cargoHeader.DischargingPort = null;   
    this.mayMoveForwardUploadingFile=false;   
  }

  //getCargoContaiersByInvoiceNo(invoiceNo:string){
    // this.cargoService.getCargoContainersByInvoiceNo(invoiceNo).subscribe(
    //   data => {
    //   this.shipmentAdviceCargoContainers = data;
    //   this.shipmentAdviceCargoContainers.forEach(element => {
    //   });
    //     this.loaded = true;
    //   },
    //   error => {
    //     this.flashMessage.show("Please fill out the form correctly", {
    //       cssClass: "alert-danger",
    //       timeout: 5000
    //     });
    //     this.loaded = true;
    //   }
    // );
  //}

  handleFileInput(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.fileToUpload = files.item(0);
  }

  handleFileInputContainerAppointment(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.fileToUploadContainerAppointment = files.item(0);
  }


  // addContainerFromTextbox(obj:CargoHeader){
  //   if(obj.ContainerNo==null || obj.ContainerNo.trim()==''){
  //     this.flashMessage.show(
  //       "Container # blank",
  //       {
  //         cssClass: "alert-danger",
  //         timeout: 4000
  //       }
  //     );
  //     this.loaded=true;
  //   }else{
  //     var  tempCont:TempContainer={
  //       tempContNo:null,
  //       tempContType:null
  //     };
  //     tempCont.tempContNo= obj.ContainerNo;
  //     tempCont.tempContType=obj.ContainerType;
  //     this.tempContainers.push(tempCont);
  //   } 
  // }

  removeContainerFromGrid(obj:TempContainer){
    const index = this.tempContainers.indexOf(obj);
    if(index>-1){
      this.tempContainers.splice(index,1);
    }
  }

  onChangeETDLP(obj) {
    this.cargoHeader.ETDLP = obj.target.value
  }
  onChangeETADP(obj) {
    this.cargoHeader.ETADP = obj.target.value
  }

  updateShipmentAdvice(){
    this.loaded=false;
    console.log('InvoiceNo:' +this.cargoHeader.InvoiceNo );
    console.log("ETD LP :"+  this.cargoHeader.ETDLP); 
    console.log("ETA DP :"+  this.cargoHeader.ETADP);   
    console.log("BL No:" + this.cargoHeader.BLNo)
    //this.upseartCargoHeader();
    sessionStorage.removeItem("newEntryCargoHeader");
    sessionStorage.setItem(
      "newEntryCargoHeader", JSON.stringify({myNewCargo: this.cargoHeader })
    );
    this.loaded=true;
    this.showGoodSubmit=true;
    this.mayMoveForwardUploadingFile=true;
  }

  updateCargoHeaderFromShipmentAdvice(){
    if(this.tempContainers.length>0){
      console.log('multiple: '+ this.tempContainers.length);
      this.tempContainers.forEach(element => {
        var tempCargoHeader:CargoHeader={
          CargoHeaderId:0,
          InvoiceNo:null,
          ContainerNo:null,
          ContainerType:null,
          BLNo:null,
          HouseBLNo:null,
          BLDate:null,
          ETDLP:null,
          ETDLPRevised:null,
          ATDLP :null,
          ETADP :null,
          ETADPRevised :null,
          ATADP :null,
          ETADoor :null,
          ETADoorRevised:null,
          ATADoor:null,
          AppointmentDate:null,
          GateOutDate:null,
          LastFreeDate:null,
          EmptyReturnDate:null,
          Commodity:null,
          DeliveryTerm:null,
          Currency:null,
          Status:null,
          CarrierName:null,
          VesselName :null,
          VoyageNo:null,
          PlaceOfReceipt:null,
          OriginCountry:null,
          LoadingPort:null,
          DischargingPort :null,
          Via :null,
          FinalDestination :null,
          Remarks:null,
          MTSComment :null,
          CargoType:null,  
          InlandMot:null,
          DomesticCarrierName:null,
          ETDLPStart:null,
          ETDLPEnd:null,
          ATDLPStart:null,
          ATDLPEnd:null,
          ETADPStart:null,
          ETADPEnd:null,
          ATADPStart:null,
          ATADPEnd :null,
          CustomsClearanceDate:null,
          ETADoorStart:null,
          ETADoorEnd :null,
          ATADoorStart :null,
          ATADoorEnd :null,
          CurrentLocation:null,
          CurrentEvent:null,
          StampDate:null,
          ContainerPickedUpDate:null,
          DeliveryCompletion:null,
          SealNo:null,
          CaseQty:null,
                 //detail
    FactoryNo :null,
    PONo:null,
    PartNo :null,
    FTZFlag:null,
  UploadedDate:null,
  UpdatedDate:null     
        };
        tempCargoHeader.ContainerNo = element.tempContNo;
        tempCargoHeader.ContainerType = element.tempContType;
        tempCargoHeader.InvoiceNo = this.cargoHeader.InvoiceNo;
        tempCargoHeader.ETDLP=this.cargoHeader.ETDLP;
        tempCargoHeader.ETADP=this.cargoHeader.ETADP;
        tempCargoHeader.BLNo=this.cargoHeader.BLNo;
        tempCargoHeader.VesselName=this.cargoHeader.VesselName;
        tempCargoHeader.VoyageNo=this.cargoHeader.VoyageNo;
        tempCargoHeader.LoadingPort=this.cargoHeader.LoadingPort;
        tempCargoHeader.DischargingPort=this.cargoHeader.DischargingPort;
        this.cargoHeaders.push(tempCargoHeader);
      });
    }else{
      console.log('single');
      this.cargoHeaders.push(this.cargoHeader);
    }
    this.loaded=false;
    this.cargoService.upseartCargoHeaders(this.cargoHeaders).subscribe(
      data => {
        this.flashMessage.show("Shipment Advice Header Updated successfully", {
          cssClass: "alert-success",
          timeout: 6000  
        });
        this.loaded=true;
        this.mayMoveForwardUploadingFile=true;
      },
      error => {
        this.flashMessage.show("Update Cargo Header failed."+ error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loaded=true;
      }
    );
  }

  upseartCargoHeader(){
    this.loaded=false;
    this.cargoService.upseartCargoHeader(this.cargoHeader).subscribe(
      data => {
        this.flashMessage.show("Shipment Advice Header Updated successfully", {
          cssClass: "alert-success",
          timeout: 6000
       
        });
        this.loaded=true;
        this.mayMoveForwardUploadingFile=true;
      //  this.enableNextToStep3=true;
      },
      error => {
        this.flashMessage.show("Update Cargo Header failed"+ error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loaded=true;
      //  this.enableNextToStep3=false;
      }
    );
  }
  
  uploadingFileCLPInv() {
    this.showGoodSubmit=false;
    if (this.fileToUpload != null) {
      this.uploadFileCLPInv();
    } else {
      this.flashMessage.show("No file selected.", {
        cssClass: "alert-danger",
        timeout: 4000
      });
    }
  }
  
  uploadFileCLPInv() {
    this.loaded=false;
    /* CargoDetails saves fist and CargoHeader next reason with 
    container# is not avaialble untill it is save in DB for CargoHeader */
    var intBLNo = this.cargoHeader.InvoiceNo+'&'+this.cargoHeader.BLNo;
    console.log('uploading intBLNo: '+ intBLNo)
    this.cargoService.uploadFileCLPInv(this.fileToUpload, intBLNo).subscribe(
      data => {
        this.recordUploaded = data;
        this.showRecordUploaded=true;
        this.loaded=true;     
        this.upseartCargoHeaderBasedOnCLPInvFile();
      },
      error => {
        this.flashMessage.show("File upload failed." + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loaded=true;
      }
    );
  }

  upseartingCargoHeader:any={};
  upseartCargoHeaderBasedOnCLPInvFile(){
    this.upseartingCargoHeader = JSON.parse(
      sessionStorage.getItem("newEntryCargoHeader"));
      if(this.upseartingCargoHeader!=null){
        this.cargoHeader.InvoiceNo = this.upseartingCargoHeader.myNewCargo.InvoiceNo;
        this.cargoHeader.ETDLP = this.upseartingCargoHeader.myNewCargo.ETDLP;
        this.cargoHeader.ETADP = this.upseartingCargoHeader.myNewCargo.ETADP;
        this.cargoHeader.BLNo = this.upseartingCargoHeader.myNewCargo.BLNo;
        this.cargoHeader.VesselName = this.upseartingCargoHeader.myNewCargo.VesselName;
        this.cargoHeader.VoyageNo = this.upseartingCargoHeader.myNewCargo.VoyageNo;
        this.cargoHeader.LoadingPort = this.upseartingCargoHeader.myNewCargo.LoadingPort;
        this.cargoHeader.DischargingPort = this.upseartingCargoHeader.myNewCargo.DischargingPort;
        this.loaded=false;
        this.cargoService.upseartCargoHeaderBasedOnCLPInvFile(this.cargoHeader).subscribe(
          data => {
            this.flashMessage.show("File uploaded successfully", {
              cssClass: "alert-success",
              timeout: 6000
            });
            this.loaded=true;
          },
          error => {
            this.flashMessage.show("File upload failed." + error.message, {
              cssClass: "alert-danger",
              timeout: 6000
            });
            this.loaded=true;
          }
        );
      }
  }

  uploadFileContainerAppointment() {
    if (this.fileToUploadContainerAppointment != null) {
      this.uploadFileToActivityContainerAppointment();
    } else {
      this.flashMessage.show("No file selected.", {
        cssClass: "alert-danger",
        timeout: 6000
      });
    }
  }
  uploadFileToActivityContainerAppointment() {
    this.loaded = false;
    this.cargoService.uploadFileToActivityContainerAppointment(this.fileToUploadContainerAppointment).subscribe(
      data => {
        this.recordUploaded = data;
        this.flashMessage.show("File uploaded successfully", {
          cssClass: "alert-success",
          timeout: 6000
        });
        this.loaded = true;
        this.showRecordUploaded=true;
      },
      error => {
        this.flashMessage.show("File Upload failed."+ error.message, {
          cssClass: "alert-danger",
          timeout: 10000
        });
        this.loaded = true;
      }
    );
  }

  onChangeFileChoice($event: MatRadioChange){
    this.showRecordUploaded=false;
    console.log("My Payment Choice:"+$event.value);
    this.myChoice=$event.value;
  }

  deleteDataByInvoiceNo(invoiceNo:String){
    this.loaded=false;
    console.log("Going to delete invoice: "+invoiceNo );
    this.cargoService.deleteDataByInvoiceNo(invoiceNo).subscribe(
      data => {
        this.flashMessage.show("Invoice deleted successfully", {
          cssClass: "alert-success",
          timeout: 6000
        });
        this.loaded = true;
      },
      error => {
        this.flashMessage.show("Delete invoice failed."+ error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loaded = true;
      }
    );
  }

}
