<br>
<br>
<div class="row">
    <div class="col-md-6 mx-auto">
      <h1>Reset your password</h1>
      <br>
      <div class="card">
        <div class="card-body">
          <form
            name="form"
            (ngSubmit)="myForm.form.valid && onSubmit()"
            #myForm="ngForm"
            novalidate
          >
            <div class="form-group">
              <label for="email">Enter the email linked to your account and look out for a reset password email.</label>
              <input
                type="email"
                placeholder="EMAIL"
                class="form-control"
                name="Email"
                [(ngModel)]="Email"
                #myEmail="ngModel"
                required
              />
              <div
                *ngIf="myForm.submitted && !myEmail.valid"
                class="alert alert-danger"
              >
                Email is required
              </div>
            </div>
            <div class="form-group">
              <button [disabled]="loading" class="btn btn-primary">Submit</button>
              <img
                *ngIf="loading"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
              <a [routerLink]="['/login']" class="btn btn-link">Cancel</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>