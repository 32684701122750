<div class="myContainer">
  <div id="DealerEditHeader" class="col-md-4">
    <h2><i class="fas fa-info-circle fa-1x"></i>
      Data Entry & File upload:</h2>
  </div>
  <div class="fileChoice">
    <mat-card class="FileCardList">
          <mat-radio-group (change)="onChangeFileChoice($event)">
            <mat-radio-button class="example-radio-button radioFileChoice" *ngFor="let fileChoice of fileChoices"
              [value]="fileChoice.value">
              {{fileChoice.display}}
            </mat-radio-button>
          </mat-radio-group>
        <h4 *ngIf="!loaded" class="table loading-message">Processing ...</h4>
        <div *ngIf="!loaded">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>      
        <div class="cardFileInfo">
          <!--##################################################################
          A) CLP & Invoice
        ######################################################################-->
          <div *ngIf="myChoice=='CLPINV'">
            <div id="clpInv-entry-form">
              <!-- steppers -->
              <div class="row">
                <div class="col-md-6">
                  <mat-horizontal-stepper #stepper class="stepperFileUpload">
                    <!-- ########### Step1 ############################################################## -->
                    <mat-step [stepControl]="firstFormGroup" class="test1">
                      <form [formGroup]="firstFormGroup">                     
                        <ng-template matStepLabel class="test1">Copy Email Body</ng-template>
                      <div>
                          <label>* If this is 2nd time to upload same invoice#, please delete the invoice first.</label>&nbsp;&nbsp;
                        <input type="text" 
                        [(ngModel)]="removingInvoiceNo" 
                        [ngModelOptions]="{standalone: true}"  
                        placeholder="Invoice#"                 
                          name="myInvoiceNo" /> &nbsp;
                          <button
                            type="button" (click)="deleteDataByInvoiceNo(removingInvoiceNo)"
                            class="btn  btn-outline-dark btnAddNote">
                              Remove
                         </button>
                      </div>
                        <mat-form-field id="matFieldTxtArea">
                          <textarea matInput placeholder="Copy Shipment data from Email Body"
                            formControlName="firstCtrl" [(ngModel)]="myEmailBody" id="txtAreaBody" required></textarea>
                        </mat-form-field>
                        <div>
                          <button mat-button class="stepBtnNext"
                            (click)="uploadShipmentAdviceFromEmailBody(myEmailBody)" matStepperNext>Next</button>
                        </div>
                      </form>
                    </mat-step>
                    <!-- ########### Step2 ############################################################## -->
                    <mat-step [stepControl]="secondFormGroup">
                      <!-- <h4 *ngIf="!loaded" class="table loading-message">Uploading ...</h4>
                      <div *ngIf="!loaded">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                      </div> -->
                      <form [formGroup]="secondFormGroup">
                        <ng-template matStepLabel>Update Shipment Advice </ng-template>
                   
                          <!-- <div>
                            #{{cargoHeader.InvoiceNo}}
                          </div>
                          <input matInput formControlName="secondCtrl" [(ngModel)]="cargoHeader.InvoiceNo"
                            placeholder="" required readonly id="titleInvoiceNo"> -->


                        <div class="card ">
                          <!-- <div class="card-header row">
                            <h4>Shipment Advice</h4>
                              #{{cargoHeader.InvoiceNo}}
                           
                          </div> -->
                          <div class="card-body">
                            <form #myShipmentAdviceForm="ngForm">
                              <div class="row">
                                <div class="col-md-3">
                                  <div class="form-group ">
                                    <label>1. Invoice#</label>
                                    <input type="text" [(ngModel)]="cargoHeader.InvoiceNo" [ngClass]="{
                                        'is-invalid': myInvoiceNo.errors && myInvoiceNo.touched
                                      }" class="form-control grpInput" name="InvoiceNo" #myInvoiceNo="ngModel" required
                                      minlength="3" />
                                    <div [hidden]="!myInvoiceNo.errors?.required" class="invalid-feedback">
                                      Invoice# Required
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label>2. ETD LP</label>
                                    <input type="date" [ngModel]="cargoHeader.ETDLP | date: 'yyyy-MM-dd'"
                                      class="form-control grpInput" name="ETDLP" (change)="onChangeETDLP($event)" />
                                  </div>
                                  <div class="form-group">
                                    <label>3. ETA DP</label>
                                    <input type="date" [ngModel]="cargoHeader.ETADP | date: 'yyyy-MM-dd'"
                                      class="form-control grpInput" name="ETADP" (change)="onChangeETADP($event)" />
                                  </div>
                                  <div class="form-group">
                                    <label>4. BL#</label>
                                    <input type="text" [(ngModel)]="cargoHeader.BLNo" class="form-control grpInput"
                                      name="BLNo" />
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label>5. Vessel Name</label>
                                    <input type="text" [(ngModel)]="cargoHeader.VesselName"
                                      class="form-control grpInput" name="VesselName" #myVesselName="ngModel" />
                                  </div>
                                  <div class="form-group">
                                    <label>6. Voyage No</label>
                                    <input type="text" [(ngModel)]="cargoHeader.VoyageNo" class="form-control grpInput"
                                      name="VoyageNo" #myVoyageNo="ngModel" />
                                  </div>
                                  <div class="form-group ">
                                    <label>7. Loading Port</label>
                                    <input type="text" [(ngModel)]="cargoHeader.LoadingPort"
                                      class="form-control grpInput" name="LoadingPort" />
                                  </div>
                                  <div class="form-group ">
                                    <label>8. Discharging Port</label>
                                    <input type="text" [(ngModel)]="cargoHeader.DischargingPort"
                                      class="form-control grpInput" name="DischargingPort" />
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="divRegistrationNote">
                                    <label>9. Container#</label><br>
                                    <mat-form-field id="formContainerNo">
                                      <mat-label>Container#</mat-label>
                                      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                        id="txtContainerNo" [(ngModel)]="cargoHeader.ContainerNo"
                                        [value]="cargoHeader.ContainerNo" name="ContainerNo"></textarea>
                                    </mat-form-field>&nbsp;&nbsp;
                                    <mat-form-field id="formContainerType">
                                      <mat-label>Type</mat-label>
                                      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                        id="txtContainerType" [(ngModel)]="cargoHeader.ContainerType"
                                        [value]="cargoHeader.ContainerType" name="ContainerType"></textarea>
                                    </mat-form-field>&nbsp;&nbsp;
                                    <button type="button"
                                    class="btn  btn-outline-secondary btnAddNote">
                                    Add
                                  </button>
                                    <!-- <button type="button" (click)="addContainerFromTextbox(cargoHeader)"
                                      class="btn  btn-outline-secondary btnAddNote">
                                      Add
                                    </button> -->
                                    <table *ngIf="tempContainers?.length > 0; else noContainer"
                                      class="table table-striped table-bordered table-hover" id="ContainerList">
                                      <thead class="thead-inverse">
                                        <tr class="tableStatus">
                                          <th class="columnMd">Container No.</th>
                                          <th class="columnSm">Type</th>
                                          <th class="columnSm">&nbsp;</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let tempContainer of tempContainers" class="tableStatus">
                                          <td>{{tempContainer.tempContNo }}</td>
                                          <td>{{tempContainer.tempContType }}</td>
                                          <td><button type="button" (click)="removeContainerFromGrid(tempContainer)"
                                              class="btn  btn-outline-dark btnAddNote">
                                              Remove
                                            </button> </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <ng-template #noContainer>
                                      <hr />
                                      <h5>No containers</h5>
                                    </ng-template>
                                  </div>
                                </div>
                              </div>
                              <br>
                              <div class="row">
                                <button [disabled]="!myShipmentAdviceForm.form.valid"
                                  class="btn btn-light btn-block mb-3" id="updateInSteps"
                                  (click)="updateShipmentAdvice()">
                                  Submit
                                </button>&nbsp;
                                <button class="btn  mb-3" id="clearInSteps" (click)="clearShipmentAdvice()">
                                  Clear
                                </button>
                                <div *ngIf="showGoodSubmit==true">
                                  <h4 class="goodSubmit">Good! Go to next.</h4>
                                </div>
                                
                              </div>
                            </form>
                          </div>
                        </div>
                        <br>
                        <div>
                          <button mat-button class="stepBtnBack" matStepperPrevious>Back</button>&nbsp;
                          <button mat-button [disabled]="!mayMoveForwardUploadingFile" class="stepBtnNext"
                            matStepperNext>Next</button>
                        </div>
                      </form>
                    </mat-step>
                    <!-- ########### Step3 ############################################################## -->
                    <mat-step [stepControl]="thirdFormGroup">
                      <form [formGroup]="thirdFormGroup">
                        <ng-template matStepLabel>Select File</ng-template>
                        <input type="file" (change)="handleFileInput($event)" name="PickupGroupTest"
                          formControlName="thirdCtrl">
                        <br><br>
                        <div>
                          <button mat-button class="stepBtnBack" matStepperPrevious>Back</button>&nbsp;
                          <button mat-button matStepperNext class="stepBtnNext" (click)="uploadingFileCLPInv()">Next</button>
                        </div>
                      </form>
                    </mat-step>
                    <!-- ########### Step4 ############################################################## -->                    
                    <mat-step>
                      <div *ngIf="loaded">
                        <ng-template matStepLabel>Done</ng-template>
                        <div>
                          <h4 *ngIf="showRecordUploaded" id="numberOfCargo">
                            All done!&nbsp;&nbsp; {{ recordUploaded }} Records Uploaded
                        </h4>
                        </div>
                        <div>
                          <button mat-button class="stepBtnBack" matStepperPrevious>Back</button>&nbsp;&nbsp;
                          <button mat-button class="stepBtnReset" (click)="stepper.reset()">Start again</button>
                        </div>
                      </div>
                    </mat-step>
                  </mat-horizontal-stepper>
                </div>
              </div>
            </div>
          </div>
          <!--##################################################################
          B) Container Appointment
        ######################################################################-->
          <div *ngIf="myChoice=='ContainerAppointment'">
            <div id="contAppo-entry-form">
              <div class="container">
                <div class="row uploadMenues">
                  <div class="card col-md-6 cardContAppo">
                    <h2>
                      <i class="fa fa-cloud-upload"></i>
                      Upload Container Appointment
                    </h2>
                    <div class="clearfix">
                      <form>
                        <div class="form-group">
                          <input type="file" (change)="handleFileInputContainerAppointment($event)" />

                          <div class="row">
                            <h4>
                              <button type="submit" class="btn btn-primary" (click)="uploadFileContainerAppointment()">
                                Submit
                              </button>
                            </h4>
                            <h4 *ngIf="showRecordUploaded" id="numberOfCargo">
                              All done!&nbsp;&nbsp; {{ recordUploaded }} Records Uploaded
                          </h4>
                          </div>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </mat-card>
  </div>
</div>