import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FlashMessagesService } from "angular2-flash-messages";
import { User } from "../../../models/user";
import { AuthService } from "../../../services/auth/auth.service";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: User;
  loading = false;
  Email: string;
  Password: string;
  constructor(
    private authService: AuthService,
    private router: Router,
    private flashMessage: FlashMessagesService
  ) {}

  ngOnInit() {
    //Login
    //localStorage.removeItem("myAuthKey");
    localStorage.removeItem("MTSPortalLoginUserId")

    //Clear local storage
    localStorage.removeItem("passwordRecoveryAskingUser");
    //New-Passwrd, Reset-password
    sessionStorage.removeItem("passwordResettingUser");
    //Register, Token-check
    sessionStorage.removeItem("userInProcess");
    //auth.service
    sessionStorage.removeItem("loginUserName");
    sessionStorage.removeItem("loginUserFirstName");
    //Cargo Header  Contaienr Serach
     sessionStorage.removeItem("mySearchingCargo");
    this.checkAuth();
  }

  myEmail = new FormControl("", [Validators.required, Validators.email]);
  getErrorMessage() {
    return this.myEmail.hasError("required")
      ? "You must enter a value."
      : this.myEmail.hasError("email")
      ? "Not a valid email"
      : "";
  }

  checkAuth() {
    var auth = this.authService.getAuth();
    console.log("How about auth ? : " + auth);
  }

  onSubmit() {
    this.loading = true;
    // Idea A) Developed by Oz when KEWWMS was developed in early 2018
    //var userOz =  this.authService.loginOzOriginal(this.Email, this.Password)

    // Idea B) Based on the sample in the book "ASP.NET Core 2 and Angular 5 (Packt)"
    var user = this.authService
      .loginBookSampleNew(this.Email.toLowerCase(), this.Password)
      .subscribe(
        data => {
          // this.flashMessage.show("You are logged in successfully.", {
          //   cssClass: "alert-success",
          //   timeout: 3000
          // }); 
          this.router.navigate(["/top"]);
        },
        error => {
          this.flashMessage.show(
            "Login failed... Please make sure your password is correct.",
            {
              cssClass: "alert-danger",
              timeout: 3000
            }
          );
          this.loading = false;
          this.router.navigate(["/login"]);
        }
      );
  }
}
