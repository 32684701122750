import { Routes, RouterModule } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AuthGuard } from "./guards/auth.guard";

//copy from app.module.ts
//General
import { LoginComponent } from './components/general/login/login.component';
import { NewPasswordComponent } from './components/general/new-password/new-password.component';
import { NotFoundComponent } from './components/general/not-found/not-found.component';
import { RegisterComponent } from './components/general/register/register.component';
import { ResetPasswordComponent } from './components/general/reset-password/reset-password.component';
import { TokenCheckComponent } from './components/general/token-check/token-check.component';
import { HomeComponent } from './components/home/home.component';
import { EmailSentMessageComponent } from './components/general/email-sent-message/email-sent-message.component';
import { EnterNewPasswordComponent } from './components/general/enter-new-password/enter-new-password.component';
//Operation
import { CargoHeaderComponent } from './components/cargo/cargo-header/cargo-header.component';
import { CargoDetailComponent } from './components/cargo/cargo-detail/cargo-detail.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { Dashboard2Component } from './components/dashboard/dashboard2/dashboard2.component';
import { Dashboard3Component } from './components/dashboaard/dashboard3/dashboard3.component';
import { FileUploadComponent } from './components/entry/file-upload/file-upload.component';
import { TopComponent } from './components/top/top.component';
import { RecentUpdatesComponent } from './components/recent-updates/recent-updates.component';
import { BlHeaderComponent } from './components/cargo/bl-header/bl-header.component';
import { CargoMainHeaderComponent } from './components/cargo/cargo-main-header/cargo-main-header.component';

const routes: Routes = [
      { path: "", component: HomeComponent , canActivate: [AuthGuard] }, 
      { path: "login", component: LoginComponent },
      { path: "home", component: HomeComponent , canActivate: [AuthGuard] },     
      { path: "register", component: RegisterComponent },
      { path: "reset-password", component: ResetPasswordComponent },
      { path: "tokenCheck", component: TokenCheckComponent },
      { path: "new-password/:token", component: NewPasswordComponent},
      { path: "email-sent-message", component: EmailSentMessageComponent },
      { path: "enter-new-password/:token", component: EnterNewPasswordComponent },   
      {path: "cargo/cargo-header", component:CargoHeaderComponent, canActivate: [AuthGuard]},
      {path: "cargo/cargo-header/:InvoiceNoAndContainerNo", component:CargoHeaderComponent},
      {path: "cargo/cargo-detail", component:CargoDetailComponent, canActivate: [AuthGuard]},
      {path: "cargo/cargo-detail/:InvoiceNoAndContainerNo", component:CargoDetailComponent, canActivate: [AuthGuard]},
      {path: "cargo/cargo-main-header", component:CargoMainHeaderComponent, canActivate: [AuthGuard]},
      {path: "cargo/cargo-main-header/:InvoiceNoAndContainerNo", component:CargoMainHeaderComponent},      
      {path: "cargo/bl-header", component:BlHeaderComponent, canActivate: [AuthGuard]},
      {path: "cargo/bl-header/:InvoiceNoAndContainerNo", component:BlHeaderComponent},    

      {path: "dashboard/dashboard", component:DashboardComponent, canActivate: [AuthGuard]},
      {path: "dashboard/dashboard2", component:Dashboard2Component, canActivate: [AuthGuard]},
      {path: "dashboard/dashboard3", component:Dashboard3Component, canActivate: [AuthGuard]},   
      {path: "entry/FileUpload", component:FileUploadComponent, canActivate: [AuthGuard]},
      {path: "top", component:TopComponent, canActivate: [AuthGuard]},
      {path: "recentUpdates", component:RecentUpdatesComponent, canActivate: [AuthGuard]},
      {path: "**", component: NotFoundComponent }

    ];
    
    @NgModule({
      exports: [RouterModule],
      imports: [RouterModule.forRoot(routes)],
      providers: [AuthGuard]
    })
    export class myRoutes {}