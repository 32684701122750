import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from "angular2-flash-messages";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { UserService } from "../../../services/user/user.service";
import { User } from "../../../models/user";

@Component({
  selector: 'app-enter-new-password',
  templateUrl: './enter-new-password.component.html',
  styleUrls: ['./enter-new-password.component.css']
})
export class EnterNewPasswordComponent implements OnInit {
  loading = false;
  password: string;
  rePassword: string;
  token:string;
  //tempToken:string[];
  mySelectedLanguage:string;
  user: User = {
    Id: null,
    Email: null,
    UserName: null,
   // registeredDate: null,
    Password: null,
    FirstName: null,
    LastName: null,
    RegisteredDate:null,
    CompanyName: null,
    DepartmentName:null,
    RememberMe: null,
    IsLoggedIn: null,
    Token: null
  };

  constructor(
    private userService: UserService,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    public route: ActivatedRoute
  ) { }

  tempToken:any[]=[];
  tempToken2nd:any[]=[];
  tempEmail:any[]=[];
  ngOnInit() {
    console.log("Token: "+ this.user.Token);
    console.log("Email: "+ this.user.Email);
    console.log("URL:"+ this.router.url)
    if(this.router.url.includes('token')==true){
      console.log("Yes token is in");
      this.tempToken =  this.router.url.split("token");
      console.log("this.tempToken[0]:"+ this.tempToken[0]);
      console.log("this.tempToken[1]:"+ this.tempToken[1]);
      if(this.tempToken.length>1){
        if(this.tempToken[1].includes('email')==true){
          console.log('Yes email is in')
          this.tempToken2nd= this.tempToken[1].split("email");
          this.user.Token=this.tempToken2nd[0];
          this.user.Email = this.tempToken2nd[1];

          while(this.user.Token.indexOf('$')>-1){
            this.user.Token = this.user.Token.replace('$', '/');
          }
          while(this.user.Token.indexOf('&')>-1){
            this.user.Token = this.user.Token.replace('&', '+');
          }  
          
          console.log("Born again Token: "+ this.user.Token);
          console.log("Email: "+ this.user.Email);
        }
      }
    }

  }

  onSubmit({ value, valid }: { value: User; valid: boolean }) {
    if (!valid) {
      this.flashMessagesService.show("Please fill in all fields", {
        cssClass: "alert-danger",
        timeout: 8000
      });
      this.router.navigate(["enter-new-password/" + this.user.Email]);
    } else if (value.Password != this.rePassword) {
      this.flashMessagesService.show("Password mismatch....", {
        cssClass: "alert-danger",
        timeout: 8000
      });
      this.loading = false;
      this.router.navigate(["enter-new-password/" + this.user.Email]);
    } else {
      console.log("Test1:" + value.Password);

      //this.userService.updateUser(this.user.email, value).subscribe(
      this.user.Password = value.Password;
      //this.user.Token = value.Token;
      this.userService.passwordRecovery(this.user.Email, this.user).subscribe(
        data => {
          this.flashMessagesService.show( 
            "Password updated successfully."
            , {
            cssClass: "alert-success",
            timeout: 5000
          });
          this.router.navigate(["/top"]);
        },
        error => {
          this.flashMessagesService.show("Password update failed...", {
            cssClass: "alert-danger",
            timeout: 5000
          });
          this.loading = false;
      //    this.router.navigate(["/reset-password/"+ this.mySelectedLanguage]);
        }
      );
    }
  }

}
