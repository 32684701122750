import { Component, OnInit, ViewChild } from "@angular/core";
import { FlashMessagesService } from "angular2-flash-messages";
import { Router, ActivatedRoute } from "@angular/router";
import { CargoService } from "../../../services/cargo/cargo.service";
import { AuthService } from "../../../services/auth/auth.service";
import { CargoHeader } from "../../../models/cargoHeader";
import { CargoDetail } from "../../../models/cargoDetail";
import { CargoHeaderDetail } from "../../../models/cargoHeaderDetail";
import { DateUpdatedLog} from "../../../models/dateUpdatedLog";
import { Sort, MatSort } from "@angular/material/sort";
//import {MatTableDataSource} from '@angular/material/table';
//import { runInThisContext } from 'vm';

@Component({
  selector: 'app-cargo-detail',
  templateUrl: './cargo-detail.component.html',
  styleUrls: ['./cargo-detail.component.css']
})
export class CargoDetailComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  myCargoDetailDisplayedColumns = ['BLNo','InvoiceNo','ContainerNo', 'FactoryNo','CaseQty','CaseNo',
  'ItemNo','PLNo','PONo','PartsDescription','Qty','PartNo'];

  loadedCargoHeader:boolean;
  loadedCargoDetails:boolean;
  loadedDateUpdatedLog:boolean;
  
  cargoHeader:CargoHeader={
    CargoHeaderId:0,
    InvoiceNo:null,
    ContainerNo:null,
    ContainerType:null,
    BLNo:null,
    HouseBLNo:null,
    BLDate:null,
    ETDLP:null,
    ETDLPRevised:null,
    ATDLP :null,
    ETADP :null,
    ETADPRevised :null,
    ATADP :null,
    ETADoor :null,
    ETADoorRevised:null,
    ATADoor:null,
    AppointmentDate:null,
    GateOutDate:null,
    LastFreeDate:null,
    EmptyReturnDate:null,
    Commodity:null,
    DeliveryTerm:null,
    Currency:null,
    Status:null,
    CarrierName:null,
    VesselName :null,
    VoyageNo:null,
    PlaceOfReceipt:null,
    OriginCountry:null,
    LoadingPort:null,
    DischargingPort :null,
    Via :null,
    FinalDestination :null,
    Remarks:null,
    MTSComment :null,
    CargoType:null,  
    InlandMot:null,
    DomesticCarrierName:null,
    ETDLPStart:null,
    ETDLPEnd:null,
    ATDLPStart:null,
    ATDLPEnd:null,
    ETADPStart:null,
    ETADPEnd:null,
    ATADPStart:null,
    ATADPEnd :null,
    CustomsClearanceDate:null,
    ETADoorStart:null,
    ETADoorEnd :null,
    ATADoorStart :null,
    ATADoorEnd :null,
    CurrentLocation:null,
    CurrentEvent:null,
    StampDate:null,
    ContainerPickedUpDate:null,
    DeliveryCompletion:null,
    SealNo:null,
    CaseQty:null,
     //detail
    FactoryNo :null,
    PONo:null,
    PartNo :null,
    FTZFlag:null,
    UploadedDate:null,
    UpdatedDate:null
  }
  cargoHeaders:CargoHeader[];
  
  cargoDetail:CargoDetail={
    CargoDetailId:0,
    InvoiceNo:null,
    ContainerNo :null,
    BLNo:null,
    VesselName :null,
    VoyageNo:null,
    SealNo :null,
    FactoryNo:null,
    CaseQty:null,
    CaseNo :null,
    KeyNo:null,
    ItemNo:null,
    PLNo:null,
    PONo :null,
    PartsDescription:null,
    Qty :null,
    PartNo :null,
    UploadedDate:null,
    UpdatedDate:null
  }
  cargoDetails:CargoDetail[];
  
  cargoHeaderDetail:CargoHeaderDetail={
    CargoHeaderDetailId:0,
    InvoiceNo:null,
    ContainerNo :null,
    BLNo:null,
    VesselName :null,
    VoyageNo:null,
    SealNo :null,
    FactoryNo:null,
    CaseQty:null,
    CaseNo :null,
    KeyNo:null,
    ItemNo:null,
    PLNo:null,
    PONo :null,
    PartsDescription:null,
    Qty :null,
    PartNo :null,
    LoadingPort:null,
    ETDLP:null,
    DischargingPort:null,
    ETADP:null,
    ETADPRevised:null,
    ATADP:null,
    CustomsClearanceDate:null,
    LastFreeDate:null,
    AppointmentDate:null,
    ContainerPickedUpDate:null,
    DeliveryCompletion:null,
    UpdatedDate:null,
    Note:null
  }
  cargoHeaderDetails:CargoHeaderDetail[];



  dateUpdatedLog:DateUpdatedLog={
    DateUpdatedLogId:0,
    InvoiceNo:null,
    ContainerNo:null,
    BLNo:null,
    ETDLP:null,
    ETDLPRevised:null,
    ATDLP:null, 
    ETADP:null,
    ETADPRevised:null,
    ATADP:null,
    CustomsClearanceDate:null,
    ETADoor :null,
    ETADoorRevised:null, 
    ATADoor:null,
    Comment :null,
    UpdatedDate:null,
    UpdatedBy:null,
    AppointmentDate:null,  
    GateOutDate:null,
    LastFreeDate :null, 
    EmptyReturnDate:null
  }
  dateUpdatedLogs:DateUpdatedLog[];

  constructor(
    private authService: AuthService,
    private cargoService: CargoService,
    private router: Router,
    private flashMessage: FlashMessagesService,
    private route: ActivatedRoute
  ) { }
  searchingCaseNo:string;
  searchingInnerCaseNo:string;
  searchingItemNo:string;
  searchingCodeNoShipped:string;
  searchingPONo1:string;
  invNoAndContNo: string = "";

  searchingInvoiceNo:string;
  searchingContainerNo:string;
  searchingBLNo:string;
  searchingFactoryNo:string;
  searchingPONo:string;
  searchingPartNo:string;
  showDateChangeHistory:boolean;
  backToPage:string;
  ngOnInit(): void {
    this.searchingInvoiceNo = sessionStorage.getItem("mySearchingInvoiceNo");
    this.searchingContainerNo = sessionStorage.getItem("mySearchingContainerNo");
    this.searchingBLNo = sessionStorage.getItem("mySearchingBLNo");
    this.searchingFactoryNo = sessionStorage.getItem("mySearchingFactoryNo");
    this.searchingPONo = sessionStorage.getItem("mySearchingPONo");
    this.searchingPartNo = sessionStorage.getItem("mySearchingPartNo");
    console.log("MyFactory#: "+this.searchingFactoryNo);
    console.log("MyPO#: "+this.searchingPONo);
    console.log("MyPart#: "+this.searchingPartNo);
    this.invNoAndContNo = this.route.snapshot.params["InvoiceNoAndContainerNo"];
    var splitString: string[];
    if (this.invNoAndContNo != null) { 
      if(this.invNoAndContNo.split("@").length==2){
        //This is comming from BL page
        splitString = this.invNoAndContNo.split("@");
        this.cargoHeader.BLNo = splitString[0];
        console.log('BL#: '+this.cargoHeader.BLNo); 
        this.getCargoHeadersByBLNo(this.cargoHeader);
        this.getCargoDetailsByBLNo(this.cargoHeader);  
        this.getCargoHeaderDetailsByBLNo(this.cargoHeader);

        this.showDateChangeHistory=false;
        this.backToPage="BL";
      } else if(this.invNoAndContNo.split("$").length==2){
        //This is comming from BL page
        splitString = this.invNoAndContNo.split("$");
        this.cargoHeader.VesselName = splitString[0];
        this.cargoHeader.VoyageNo = splitString[1];
        console.log('vessel name: ' +  this.cargoHeader.VesselName);
        console.log('voyage #: '+ this.cargoHeader.VoyageNo);
        this.getCargoHeadersByVesselNameVoyageNo(this.cargoHeader);
        this.getCargoDetailsByVesselNameVoyageNo(this.cargoHeader);  
        this.getCargoHeaderDetailsByVesselNameVoyageNo(this.cargoHeader);
        this.showDateChangeHistory=false;
        this.backToPage="BL";
      }else{
        //This is comming from Container Page
        splitString = this.invNoAndContNo.split("&");   
        this.cargoHeader.InvoiceNo = splitString[0]; 
        this.cargoHeader.ContainerNo = splitString[1];
        if(this.cargoHeader.ContainerNo!=null && this.cargoHeader.ContainerNo!=''){
          /*Folloiwng invoic# serach is removed requested by Maki.
            This is becase when user need to search using only container#, they need
            all container across multiple invoice#.
          */
          this.cargoHeader.InvoiceNo=null;
        }
        this.getCargoHeadersByInvContNo(this.cargoHeader);
        this.getCargoDetailsByInvContNo(this.cargoHeader);
        this.getCargoHeaderDetailsByInvContNo(this.cargoHeader);
        this.getDateUpdatedLogBySearchKeys(this.cargoHeader); 
        this.showDateChangeHistory=true;
        this.backToPage="Container";
      }
      // console.log("Invoice#:" + this.cargoHeader.InvoiceNo);
      // console.log("Container#:" + this.cargoHeader.ContainerNo);   
      // console.log("BL#:" + this.cargoHeader.BLNo);  
    } 
  }
  
  getCargoHeadersByInvContNo(model: CargoHeader){ 
    this.loadedCargoHeader=false;
    this.cargoService
      .getCargoHeadersByInvContNo(model)
      .subscribe(results => {
        this.cargoHeader = results[0];
        this.loadedCargoHeader=true;
      },
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loadedCargoHeader=true;
      });
  }
  getCargoHeadersByBLNo(model: CargoHeader){ 
    this.loadedCargoHeader=false;
    this.cargoService
      .getCargoHeadersByBLNo(model)
      .subscribe(results => {
        this.cargoHeader = results[0];
        this.loadedCargoHeader=true;
      },
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loadedCargoHeader=true;
      });
  }
  getCargoHeadersByVesselNameVoyageNo(model: CargoHeader){ 
    this.loadedCargoHeader=false;
    this.cargoService
      .getCargoHeadersByVesselNameVoyageNo(model)
      .subscribe(results => {
        this.cargoHeader = results[0];
        this.loadedCargoHeader=true;
      },
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loadedCargoHeader=true;
      });
  }

  getCargoDetailsByInvContNo(model: CargoHeader){
    this.loadedCargoDetails=false;
    this.cargoService
      .getCargoDetailsByInvContNo(model)
      .subscribe(results => {
        this.cargoDetails = results;
        
        //handling case qty
        var tempContainerNo="";
        var tempCaseCount=0;
        this.cargoDetails.forEach(element => {        
          if(tempContainerNo == ""){
            //This is 1st one.
            tempContainerNo = element.ContainerNo;
            tempCaseCount=1;
          }else if(tempContainerNo == element.ContainerNo){
            tempCaseCount++;

          }else if(tempContainerNo != element.ContainerNo){
            tempCaseCount=0;
          }
        });
        this.loadedCargoDetails = true;
      },        
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loadedCargoDetails = true;
      });  
  }
  getCargoDetailsByBLNo(model: CargoHeader){
    this.loadedCargoDetails=false;
    this.cargoService
      .getCargoDetailsByBLNo(model)
      .subscribe(results => {
        this.cargoDetails = results;
       
        //handling case qty
        var tempContainerNo="";
        var tempCaseCount=0;
        this.cargoDetails.forEach(element => {        
          if(tempContainerNo == ""){
            //This is 1st one.
            tempContainerNo = element.ContainerNo;
            tempCaseCount=1;
          }else if(tempContainerNo == element.ContainerNo){
            tempCaseCount++;

          }else if(tempContainerNo != element.ContainerNo){
            tempCaseCount=0;
          }
        });
        this.loadedCargoDetails = true;
      },        
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loadedCargoDetails = true;
      });  
  }
  getCargoDetailsByVesselNameVoyageNo(model: CargoHeader){
    this.loadedCargoDetails=false;
    this.cargoService
      .getCargoDetailsByVesselNameVoyageNo(model)
      .subscribe(results => {
        this.cargoDetails = results;
       
        //handling case qty
        var tempContainerNo="";
        var tempCaseCount=0;
        this.cargoDetails.forEach(element => {        
          if(tempContainerNo == ""){
            //This is 1st one.
            tempContainerNo = element.ContainerNo;
            tempCaseCount=1;
          }else if(tempContainerNo == element.ContainerNo){
            tempCaseCount++;

          }else if(tempContainerNo != element.ContainerNo){
            tempCaseCount=0;
          }
        });
        this.loadedCargoDetails = true;
      },        
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loadedCargoDetails = true;
      });  
  }

  getCargoHeaderDetailsByInvContNo(model: CargoHeader){
    this.loadedCargoDetails=false;
    this.cargoService
      .getCargoHeaderDetailsByInvContNo(model)
      .subscribe(results => {
        this.cargoHeaderDetails = results;
        
        //handling case qty
        // var tempContainerNo="";
        // var tempCaseCount=0;
        // this.cargoHeaderDetails.forEach(element => {        
        //   if(tempContainerNo == ""){
        //     //This is 1st one.
        //     tempContainerNo = element.ContainerNo;
        //     tempCaseCount=1;
        //   }else if(tempContainerNo == element.ContainerNo){
        //     tempCaseCount++;

        //   }else if(tempContainerNo != element.ContainerNo){
        //     tempCaseCount=0;
        //   }
        // });
         this.loadedCargoDetails = true;
      },        
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loadedCargoDetails = true;
      });  
  }
  getCargoHeaderDetailsByBLNo(model: CargoHeader){
    this.loadedCargoDetails=false;
    this.cargoService
      .getCargoHeaderDetailsByBLNo(model)
      .subscribe(results => {
        this.cargoHeaderDetails = results;
        
        //handling case qty
        // var tempContainerNo="";
        // var tempCaseCount=0;
        // this.cargoHeaderDetails.forEach(element => {        
        //   if(tempContainerNo == ""){
        //     //This is 1st one.
        //     tempContainerNo = element.ContainerNo;
        //     tempCaseCount=1;
        //   }else if(tempContainerNo == element.ContainerNo){
        //     tempCaseCount++;

        //   }else if(tempContainerNo != element.ContainerNo){
        //     tempCaseCount=0;
        //   }
        // });
         this.loadedCargoDetails = true;
      },        
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loadedCargoDetails = true;
      });  
  }
  getCargoHeaderDetailsByVesselNameVoyageNo(model: CargoHeader){
    this.loadedCargoDetails=false;
    this.cargoService
      .getCargoHeaderDetailsByVesselNameVoyageNo(model)
      .subscribe(results => {
        this.cargoHeaderDetails = results;
        
        //handling case qty
        // var tempContainerNo="";
        // var tempCaseCount=0;
        // this.cargoHeaderDetails.forEach(element => {        
        //   if(tempContainerNo == ""){
        //     //This is 1st one.
        //     tempContainerNo = element.ContainerNo;
        //     tempCaseCount=1;
        //   }else if(tempContainerNo == element.ContainerNo){
        //     tempCaseCount++;

        //   }else if(tempContainerNo != element.ContainerNo){
        //     tempCaseCount=0;
        //   }
        // });
         this.loadedCargoDetails = true;
      },        
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loadedCargoDetails = true;
      });  
  }

  getDateUpdatedLogBySearchKeys(model: CargoHeader){
    this.loadedDateUpdatedLog = false;
    this.cargoService
      .getDateUpdatedLogBySearchKeys(model)
      .subscribe(results => {
        this.dateUpdatedLogs = results;
        this.loadedDateUpdatedLog=true;
      },        
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loadedDateUpdatedLog = true;
      });
  }

  exportToExcel() {
    var headers = {
      BLNo:"BL #",
      InvoiceNo: "Invoice #",
      ContainerNo:"Container #",
      FactoryNo:"Factory #",
      CaseQty:"Case Qty",
      CaseNo:"Case #",
      ItemNo:"Item #",
      PLNo:"PL #",
      PONo:"PO #",
      PartsDescription:"Parts Description",
      Qty:"Qty",
      PartNo:"Part #"
    };
    var itemsFormatted = [];
    this.cargoDetails.forEach(item => {
      itemsFormatted.push({
        BLNo:item.BLNo ==null?"":item.BLNo,
        InvoiceNo: item.InvoiceNo == null ? "" : item.InvoiceNo,
        ContainerNo:item.ContainerNo == null ?"" :item.ContainerNo,
        FactoryNo:item.FactoryNo ==null?"":item.FactoryNo,
        CaseQty: item.CaseQty==null?"":item.CaseQty,
        CaseNo: item.CaseNo==null?"":item.CaseNo,
        ItemNO:item.ItemNo==null?"":item.ItemNo,
        PLNo:item.PLNo==null?"":item.PLNo,
        PONo:item.PONo==null?"":item.PONo,
        PartsDescription:item.PartsDescription==null?"":item.PartsDescription,
        Qty:item.Qty==null?"":item.Qty,
        PartNo:item.PartNo==null?"":item.PartNo
      });
    });
    var fileTitle = "loads";
    this.exportCSVFile(headers, itemsFormatted, fileTitle);
  }
  exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = this.convertToCSV(jsonObject);
    var exportedFilenmae = fileTitle + ".csv" || "export.csv";
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToExcelForSAP() {
    var headers = {
      BLNo:"BL#",
      InvoiceNo: "Invoice#",
      VesselName:"VesselName",
      VoyageNo:"Voyage#",
      ContainerNo:"CNTR#",
      SealNo:"Seal#",
      FactoryNo:"Factory#",
      CaseQty:"CaseQty",
      CaseNo:"Case#",
      ItemNo:"Item#",
      PLNo:"P/L#",
      PONo:"P/O#",
      PartsDescription:"Parts Description",
      Qty:"Qty",
      PartNo:"Part#",
      LoadingPort:"LP",
      ETDLP:"ETD LP",
      DischargingPort:"DP",
      ETADP:"Org.ETA DP",
      ETADPRevised:"ETA DP Updated",
      ATADP:"ATA DP",
      CustomsClearanceDate:"CC Date",
      LastFreeDate:"Last FD",
      AppointmentDate:"CNTR Delivery Appt",
      ContainerPickedUpDate:"CNTR Picked Up",
      DeliveryCompletion:"Delivery Completion",
      UpdatedDate:"Last Update",
      Note:"Note"
    };
    var itemsFormatted = [];
    this.cargoHeaderDetails.forEach(item => {
      var tempETD = new Date(item.ETDLP);
      var tempETDMonth = tempETD.getMonth()+1;
      var tempETDDay = tempETD.getDate();
      var tempETDYear = tempETD.getFullYear();
      var tempETA = new Date(item.ETADP);
      var tempETAMonth = tempETA.getMonth()+1;
      var tempETADay = tempETA.getDate();
      var tempETAYear = tempETA.getFullYear();
      var tempETARevised = new Date(item.ETADPRevised);
      var tempETAMonthRevised = tempETA.getMonth()+1;
      var tempETADayRevised = tempETA.getDate();
      var tempETAYearRevised = tempETA.getFullYear();
      var tempATA = new Date(item.ATADP);
      var tempATAMonth = tempATA.getMonth()+1;
      var tempATADay = tempATA.getDate();
      var tempATAYear = tempATA.getFullYear();
      var tempCC = new Date(item.CustomsClearanceDate);
      var tempCCMonth = tempCC.getMonth()+1;
      var tempCCDay = tempCC.getDate();
      var tempCCYear = tempCC.getFullYear();
      var tempLastFree = new Date(item.LastFreeDate);
      var tempLastFreeMonth = tempLastFree.getMonth()+1;
      var tempLastFreeDay = tempLastFree.getDate();
      var tempLastFreeYear = tempLastFree.getFullYear();
      var tempAppointment = new Date(item.AppointmentDate);
      var tempAppointmentMonth = tempAppointment.getMonth()+1;
      var tempAppointmentDay = tempAppointment.getDate();
      var tempAppointmentYear = tempAppointment.getFullYear();
      var tempContainerPickup = new Date(item.ContainerPickedUpDate);
      var tempContainerPickupMonth = tempContainerPickup.getMonth()+1;
      var tempContainerPickupDay = tempContainerPickup.getDate();
      var tempContainerPickupYear = tempContainerPickup.getFullYear();
      var tempDeliveryCompletion = new Date(item.DeliveryCompletion);
      var tempDeliveryCompletionMonth = tempDeliveryCompletion.getMonth()+1;
      var tempDeliveryCompletionDay = tempDeliveryCompletion.getDate();
      var tempDeliveryCompletionYear = tempDeliveryCompletion.getFullYear();
      var tempUpdatedDate = new Date(item.UpdatedDate);
      var tempUpdatedDateMonth = tempUpdatedDate.getMonth()+1;
      var tempUpdatedDateDay = tempUpdatedDate.getDate();
      var tempUpdatedDateYear = tempUpdatedDate.getFullYear();

      itemsFormatted.push({
        BLNo:item.BLNo ==null?"":item.BLNo.replace(/,/g, " "),
        InvoiceNo: item.InvoiceNo == null ? "" : item.InvoiceNo.replace(/,/g, " "),
        VesselName:item.VesselName==null?"":item.VesselName.replace(/,/g, " "),
        VoyageNo:item.VoyageNo==null?"":item.VoyageNo.replace(/,/g, " "),
        ContainerNo:item.ContainerNo == null ?"" :item.ContainerNo.replace(/,/g, " "),
        SealNo:item.SealNo==null?"":item.SealNo.replace(/,/g, " "),
        FactoryNo:item.FactoryNo ==null?"":item.FactoryNo.replace(/,/g, " "),
        CaseQty: item.CaseQty==null?"":item.CaseQty,
        CaseNo: item.CaseNo==null?"":item.CaseNo,
        ItemNO:item.ItemNo==null?"":item.ItemNo.replace(/,/g, " "),
        PLNo:item.PLNo==null?"":item.PLNo.replace(/,/g, " "),
        PONo:item.PONo==null?"":item.PONo.replace(/,/g, " "),
        PartsDescription:item.PartsDescription==null?"":item.PartsDescription.replace(/,/g, " "),
        Qty:item.Qty==null?"":item.Qty,
        PartNo:item.PartNo==null?"":item.PartNo.replace(/,/g, " "),

        LoadingPort:item.LoadingPort==null?"":item.LoadingPort.replace(/,/g, " "),
        ETDLP:item.ETDLP==null?"":tempETDMonth+"/"+tempETDDay+"/"+tempETDYear,
        DischargingPort:item.DischargingPort==null?"":item.DischargingPort.replace(/,/g, " "),
        ETADP: item.ETADP == null ? "" : tempETAMonth+"/"+tempETADay+"/"+tempETAYear,
        ETADPRevised:item.ETADPRevised == null ? "" : tempETAMonthRevised +"/"+tempETADayRevised +"/"+tempETAYearRevised ,
        ATADP: item.ATADP == null ? "" : tempATAMonth+"/"+tempATADay+"/"+tempATAYear,
        CustomsClearanceDate:item.CustomsClearanceDate == null ? "" : tempCCMonth+"/"+tempCCDay+"/"+tempCCYear, 
        LastFreeDate: item.LastFreeDate == null ? "" : tempLastFreeMonth+"/"+tempLastFreeDay+"/"+tempLastFreeYear,
        AppointmentDate: item.AppointmentDate == null ? "" : tempAppointmentMonth+"/"+tempAppointmentDay+"/"+tempAppointmentYear, 
        ContainerPickedUpDate:item.ContainerPickedUpDate == null ? "" : tempContainerPickupMonth+"/"+tempContainerPickupDay+"/"+tempContainerPickupYear, 
        DeliveryCompletion:item.ContainerPickedUpDate == null ? "" : tempDeliveryCompletionMonth+"/"+tempDeliveryCompletionDay+"/"+tempDeliveryCompletionYear, 
        UpdatedDate:item.UpdatedDate == null ? "" : tempUpdatedDateMonth+"/"+tempUpdatedDateDay+"/"+tempUpdatedDateYear,
        Note:item.Note==null?"":item.Note.replace(/,/g, " "),
      });
    });
    var fileTitle = "loads";
    this.exportCSVFile(headers, itemsFormatted, fileTitle);
  }

}
