import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { AppConfig } from "../../app.config";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient, private config: AppConfig) {}

  
}
