import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from "angular2-flash-messages";
import { Router, ActivatedRoute } from "@angular/router";
import { CargoService } from "../../../services/cargo/cargo.service";
import { AuthService } from "../../../services/auth/auth.service";
import { CargoHeader } from "../../../models/cargoHeader";
import { CargoDetail } from "../../../models/cargoDetail";
import { Sort } from "@angular/material/sort";
import { User} from '../../../models/user';
import { FileService} from '../../../services/file/file.service';

@Component({
  selector: 'app-cargo-header',
  templateUrl: './cargo-header.component.html',
  styleUrls: ['./cargo-header.component.css']
})
export class CargoHeaderComponent implements OnInit {

public statuses = [
  { value: "all", display: "All" },
  { value: "Incoming", display: "Incoming" },
  { value: "DoorDelivered", display: "Door Delivered" }
];

public FTZFlags = [
  { value: true, display: "Yes" },
  { value: false, display: "No" }
];

myChoiceWhatsNew:null;
loaded: boolean = false;
loading = false;
token: string;
searchHash: string;
documentId: string;
documentHash: string;
showNumberOfCargo: boolean = false;
cntCargo: number = null;
dateChangedContainers:string[]=[];

cargoHeader:CargoHeader={
  CargoHeaderId:0,
  InvoiceNo:null,
  ContainerNo:null,
  ContainerType:null,
  BLNo:null,
  HouseBLNo:null,
  BLDate:null,
  ETDLP:null,
  ETDLPRevised:null,
  ATDLP :null,
  ETADP :null,
  ETADPRevised :null,
  ATADP :null,
  ETADoor :null,
  ETADoorRevised:null,
  ATADoor:null,
  AppointmentDate:null,
  GateOutDate:null,
  LastFreeDate:null,
  EmptyReturnDate:null,
  Commodity:null,
  DeliveryTerm:null,
  Currency:null,
  Status:null,
  CarrierName:null,
  VesselName :null,
  VoyageNo:null,
  PlaceOfReceipt:null,
  OriginCountry:null,
  LoadingPort:null,
  DischargingPort :null,
  Via :null,
  FinalDestination :null,
  Remarks:null,
  MTSComment :null,
  CargoType:null,  
  InlandMot:null,
  DomesticCarrierName:null,
  ETDLPStart:null,
  ETDLPEnd:null,
  ATDLPStart:null,
  ATDLPEnd:null,
  ETADPStart:null,
  ETADPEnd:null,
  ATADPStart:null,
  ATADPEnd :null,
  CustomsClearanceDate:null,
  ETADoorStart:null,
  ETADoorEnd :null,
  ATADoorStart :null,
  ATADoorEnd :null,
  CurrentLocation:null,
  CurrentEvent:null,
  StampDate:null,
  ContainerPickedUpDate:null,
  DeliveryCompletion:null,
  SealNo:null,
  CaseQty:null,
 //detail
  FactoryNo :null,
  PONo:null,
  PartNo :null,
  FTZFlag:null,
  UploadedDate:null,
  UpdatedDate:null
}
cargoHeaders:CargoHeader[];

cargoDetail:CargoDetail={
  CargoDetailId:0,
  InvoiceNo:null,
  ContainerNo :null,
  BLNo:null,
  VesselName :null,
  VoyageNo:null,
  SealNo :null,
  FactoryNo:null,
  CaseQty:null,
  CaseNo :null,
  KeyNo:null,
  ItemNo:null,
  PLNo:null,
  PONo :null,
  PartsDescription:null,
  Qty :null,
  PartNo :null,
  UploadedDate:null,
  UpdatedDate:null
}
cargoDetails:CargoDetail[];

editMode: boolean = false;

  constructor(
    public authService: AuthService,
    private cargoService: CargoService,
    private router: Router,
    private flashMessage: FlashMessagesService,
    private route: ActivatedRoute,
    private fileService:FileService
  ) { }

  fileToUpload: File = null;
  searchKeyTruckload: any = {};
  searchingAccountNameGroup:string="";
  pickupNo: string = "";
  pickupNoAndAccountName:string="";
  loginUser:any={};
  user:User={
    Id:null,
    FirstName:null,
    LastName:null,
    Email:null,
    UserName:null,
    RegisteredDate:null,
    CompanyName:null,
    DepartmentName:null,
    Password:null,
    RememberMe:null,
    IsLoggedIn:null,
    Token:null
  }
  searchKeyCargoHeader: any = {};
  invNoAndContNo: string = "";
  cargo:any={};

  myDate:number=Date.now() ;
  ngOnInit() {
    this.invNoAndContNo = this.route.snapshot.params["InvoiceNoAndContainerNo"];
    var splitString: string[];
    if (this.invNoAndContNo != null) {  
      splitString = this.invNoAndContNo.split("&");
      this.cargoHeader.InvoiceNo = splitString[0];
      this.cargoHeader.ContainerNo = splitString[1];
      console.log("Invoice#:" + this.cargoHeader.InvoiceNo);
      console.log("Container#:" + this.cargoHeader.ContainerNo);  
      this.getCargoBySearchKey(this.cargoHeader);
    } else {
      this.cargo = JSON.parse(sessionStorage.getItem("mySearchingCargo"));
      if (this.cargo != null) {
        this.cargoHeader.InvoiceNo       = this.cargo.myCargo.InvoiceNo;
        this.cargoHeader.ContainerNo     = this.cargo.myCargo.ContainerNo;
        this.cargoHeader.BLNo            = this.cargo.myCargo.BLNo;
        this.cargoHeader.VesselName = this.cargo.myCargo.VesselName;
        this.cargoHeader.VoyageNo = this.cargo.myCargo.VoyageNo;
        this.cargoHeader.FactoryNo  = this.cargo.myCargo.FactoryNo;
        this.cargoHeader.PONo =this.cargo.myCargo.PONo;
        this.cargoHeader.PartNo = this.cargo.myCargo.PartNo;
        // this.cargoHeader.FTZFlag = this.cargo.myCargo.FTZFlag;
        this.cargoHeader.Status           = this.cargo.myCargo.Status;
        this.cargoHeader.ATDLPStart     = this.cargo.myCargo.ATDLPStart;
        this.cargoHeader.ATDLPEnd = this.cargo.myCargo.ATDLPEnd;
        this.cargoHeader.ETDLPStart = this.cargo.myCargo.ETDLPStart;
        this.cargoHeader.ETDLPEnd = this.cargo.myCargo.ETDLPEnd;
        this.cargoHeader.ETADPStart = this.cargo.myCargo.ETADPStart;
        this.cargoHeader.ETADPEnd = this.cargo.myCargo.ETADPEnd;
        this.cargoHeader.ATADPStart = this.cargo.myCargo.ATADPStart;
        this.cargoHeader.ATADPEnd = this.cargo.myCargo.ATADPEnd;
        this.getCargoBySearchKey(this.cargoHeader);
      } else {                                       
        this.GetCargoHeadersArrivedToday();
      }
    }
  }

  onEdit() {
    this.editMode = true;
  }
  onBack() {
    this.onUndo();
    this.editMode = false;
    this.dateChangedContainers=[];
    this.getCargoBySearchKey(this.cargoHeader);
  }
  onUndo() {
    this.cargoHeaders.forEach(element => {
      element.ETDLP = null;
      element.ATDLP = null;
      element.ETADP = null;
      element.ATADP = null;
      element.CustomsClearanceDate = null;
      element.AppointmentDate = null;
      element.GateOutDate = null;
      element.LastFreeDate = null;
      element.EmptyReturnDate = null;
      element.MTSComment=null;
    });
  }
  
  editingCargoHeaders:CargoHeader[]=[];
  onSave() {
    this.loaded=false;
    var included=false;
    this.cargoHeaders.forEach(element =>{
    if(this.dateChangedContainers.includes(element.ContainerNo)){
      this.editingCargoHeaders.push(element);
    }})
    this.editingCargoHeaders.forEach(element => {
      console.log("Invoice# :" + element.InvoiceNo);
      console.log("Container# :" + element.ContainerNo);
      console.log("ETDLP :" + element.ETDLP);
      console.log("ATDLP:" + element.ATDLP);
      console.log("ETADP:" + element.ETADP);
      console.log("ATADP:" + element.ATADP);
      console.log("CC Date:" + element.CustomsClearanceDate);
      console.log("Appointment Date:" + element.AppointmentDate);
      console.log("Gate Out Date:" + element.GateOutDate);
      console.log("Last Free Date:" + element.LastFreeDate);
      console.log("Empty Return Date:" + element.EmptyReturnDate);
      console.log("Remarks:" + element.MTSComment);
      console.log("###updateCargoHeaders#########################################");
    });
    this.cargoService
      .updateCargoHeaders(this.editingCargoHeaders)
      .subscribe(
        data => {
        // A. Usually Choose A, but this case B is elected to reflect exeptional Revised Date reflect.
        //this.cargoHeaders = data;
        // B.
        this.getCargoBySearchKey(this.cargoHeader) 
        this.flashMessage.show("Data updated successfully", {
            cssClass: "alert-success",
            timeout: 6000
          });
          this.editMode = false;
          this.loaded=true;
        },
        error => {
          this.flashMessage.show("Data update failed. " + error.message, {
            cssClass: "alert-danger",
            timeout: 6000
          });
          this.loaded=true;
        }
      );  
      this.dateChangedContainers=[];  
  }

  /**** Sort Begin ****************************************************** */
  sortData(sort: Sort) {
    const data = this.cargoHeaders.slice();
    if (!sort.active || sort.direction === "") {
      this.cargoHeaders = data;
      return;
    }
    this.cargoHeaders = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "InvoiceNo":
          return this.compare(a.InvoiceNo, b.InvoiceNo, isAsc);
        case "ContainerNo":
          return this.compare(a.ContainerNo, b.ContainerNo, isAsc);
        case "BLNo":
          return this.compare(a.BLNo, b.BLNo, isAsc);
        case "VesselName":
          return this.compare(a.VesselName, b.VesselName, isAsc);
        case "VoyageNo":
          return this.compare(a.VoyageNo, b.VoyageNo, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  /***** Sort End********************************************************** */

  tempDate: Date[];

  GetCargoHeadersArrivedToday() {
    this.showNumberOfCargo = false;
    this.cargoService.getCargoHeadersArrivedToday().subscribe(
      results => {
        this.cargoHeaders = results;
        var tempInvoiceNo;
        var cnt = 0;
        this.cargoHeaders.forEach(element => {
          if (tempInvoiceNo != element) {
            tempInvoiceNo = element.InvoiceNo;
            cnt++;
          }
          this.cntCargo = cnt;
        });
        this.loaded = true;
        this.flashMessage.show(
          cnt + " Container(s) arrived discharging port today",
          {
            cssClass: "alert-success",
            timeout: 8000
          }
        );
      },
      error => {
        this.flashMessage.show(
          "Error: Cannot receive container information arriving discharging port today."+ error.message,
          {
            cssClass: "alert-danger",
            timeout: 8000
          }
        );
        this.loaded = true;
      }
    );
  }

  onSearchSubmit({ value, valid }: { value: CargoHeader; valid: boolean }) {
    this.cargoHeaders = null;
    this.cntCargo = 0;
    this.cargoHeader.Status =
    value.Status == null
      ? null
      : value.Status == ""
      ? null
      : value.Status.trim();
    this.cargoHeader.InvoiceNo =
      value.InvoiceNo == null
        ? null
        : value.InvoiceNo.trim() == ""
        ? null
        : value.InvoiceNo.trim();
    this.cargoHeader.ContainerNo =
      value.ContainerNo == null
        ? null
        : value.ContainerNo.trim() == ""
        ? null
        : value.ContainerNo.trim();
    this.cargoHeader.BLNo =
      value.BLNo == null
        ? null
        : value.BLNo.trim() == ""
        ? null
        : value.BLNo.trim();
    this.cargoHeader.VesselName =
    value.VesselName == null
      ? null
      : value.VesselName.trim() == ""
      ? null
      : value.VesselName.trim();
    this.cargoHeader.VoyageNo =
    value.VoyageNo == null
      ? null
      : value.VoyageNo.trim() == ""
      ? null
      : value.VoyageNo.trim();
    this.cargoHeader.FactoryNo =    
      value.FactoryNo == null
        ? null
        : value.FactoryNo.trim() == ""
        ? null
        : value.FactoryNo.trim();
    this.cargoHeader.PONo =     
      value.PONo == null
      ? null
      : value.PONo.trim() == ""
      ? null
      : value.PONo.trim();
    this.cargoHeader.PartNo =   
    value.PartNo == null
      ? null
      : value.PartNo.trim() == ""
      ? null
      : value.PartNo.trim();
      this.cargoHeader.ETDLPStart =   
      value.ETDLPStart == null
        ? null
        : value.ETDLPStart.toString().trim() == ""
        ? null
        : value.ETDLPStart;    
      this.cargoHeader.ETDLPEnd =   
      value.ETDLPEnd == null
      ? null
      : value.ETDLPEnd.toString().trim() == ""
      ? null
      : value.ETDLPEnd;  
      this.cargoHeader.ATDLPStart =   
      value.ATDLPStart == null
        ? null
        : value.ATDLPStart.toString().trim() == ""
        ? null
        : value.ATDLPStart;    
      this.cargoHeader.ATDLPEnd =   
      value.ATDLPEnd == null
      ? null
      : value.ATDLPEnd.toString().trim() == ""
      ? null
      : value.ATDLPEnd;
      this.cargoHeader.ETADPStart =   
      value.ETADPStart == null
        ? null
        : value.ETADPStart.toString().trim() == ""
        ? null
        : value.ETADPStart;    
      this.cargoHeader.ETADPEnd =   
      value.ETADPEnd == null
      ? null
      : value.ETADPEnd.toString().trim() == ""
      ? null
      : value.ETADPEnd; 
      this.cargoHeader.ATADPStart =   
      value.ATADPStart == null
        ? null
        : value.ATADPStart.toString().trim() == ""
        ? null
        : value.ATADPStart;    
      this.cargoHeader.ATADPEnd =   
      value.ATADPEnd == null
      ? null
      : value.ATADPEnd.toString().trim() == ""
      ? null
      : value.ATADPEnd;


    if (!valid) {
      this.loaded = true;
      this.flashMessage.show("Please fill out the form correctly", {
        cssClass: "alert-danger",
        timeout: 5000
      });
    } else if (
      value.Status == null &&
      value.InvoiceNo == null &&
      value.ContainerNo == null &&
      value.BLNo == null &&
      value.VesselName == null &&
      value.VoyageNo == null &&
      value.FactoryNo == null &&
      value.PONo == null &&
      value.PartNo == null &&
      value.ETDLPStart == null &&
      value.ETDLPEnd == null &&
      value.ATDLPStart == null &&
      value.ATDLPEnd == null &&
      value.ETADPStart == null &&
      value.ETADPEnd == null &&
      value.ATADPStart == null &&
      value.ATADPEnd == null 
    ) {
      this.loaded = true;
      this.flashMessage.show("All seach key is blank", {
        cssClass: "alert-danger",
        timeout: 6000
      });
    } else {
      console.log("Status: "+  value.Status);
      console.log("Invoice# : " + value.InvoiceNo);
      console.log("Container# : " + value.ContainerNo);
      console.log("BL# :" + value.BLNo);
      console.log("Factory# :" + value.FactoryNo);
      console.log("PO# :" + value.PONo);
      console.log("part# :" + value.PartNo);
      console.log("Status :" + value.Status);
      console.log("ETDLP Start:"+value.ETDLPStart);
      console.log("ETDLP End:"+value.ETDLPEnd);
      console.log("ATDLP Start:"+value.ATDLPStart);
      console.log("ATDLP End:"+value.ATDLPEnd);   
      console.log("ETADP Start:" + value.ETADPStart);
      console.log("ETADP End :" + value.ETADPEnd);
      console.log("ATADP Start:" + value.ATADPStart);
      console.log("ATADP End :" + value.ATADPEnd);

      sessionStorage.removeItem("mySearchingCargo");
      sessionStorage.setItem(
        "mySearchingCargo",
        JSON.stringify({ myCargo: this.cargoHeader })
      );
      this.getCargoBySearchKey(this.cargoHeader);
    }
  }

  getCargoBySearchKey(searchingCargo: CargoHeader) {
    sessionStorage.removeItem("mySearchingInvoiceNo");
    sessionStorage.removeItem("mySearchingContainerNo");
    sessionStorage.removeItem("mySearchingBLNo");
    sessionStorage.removeItem("mySearchingFactoryNo");
    sessionStorage.removeItem("mySearchingPONo");
    sessionStorage.removeItem("mySearchingPartNo");
    sessionStorage.setItem("mySearchingInvoiceNo",searchingCargo.InvoiceNo);
    sessionStorage.setItem("mySearchingContainerNo",searchingCargo.ContainerNo);
    sessionStorage.setItem("mySearchingBLNo",searchingCargo.BLNo);
    if(searchingCargo.FactoryNo==null &&
      searchingCargo.PONo==null &&
      searchingCargo.PartNo==null){
        this.getCargoHeadersBySearchKeys(searchingCargo);    
      }else{
        sessionStorage.setItem("mySearchingFactoryNo",searchingCargo.FactoryNo);
        sessionStorage.setItem("mySearchingPONo",searchingCargo.PONo);
        sessionStorage.setItem("mySearchingPartNo",searchingCargo.PartNo);
        this.getCargoHeaderDetailsBySearchKeys(searchingCargo)
      }
  }

  getCargoHeadersBySearchKeys(searchingCargo: CargoHeader){ 
    this.loaded=false;
    this.showNumberOfCargo = false;
    this.cargoService
      .getCargoHeadersBySearchKeys(searchingCargo)
      .subscribe(results => {
        this.cargoHeaders = results;
        var tempInvoiceNo;
        var cnt = 0;
        this.cargoHeaders.forEach(element => {
          if (tempInvoiceNo != element) {
            tempInvoiceNo = element.InvoiceNo;
            cnt++;
          }
          this.cntCargo = cnt;
        });
        this.loaded = true;
        this.showNumberOfCargo = true;
      },        
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loaded = true;
        this.showNumberOfCargo = true
      }
      );
  }

  getCargoHeaderDetailsBySearchKeys(searchingCargo: CargoHeader){
    this.loaded=false;
    this.showNumberOfCargo = false;
    this.cargoService
      .getCargoHeaderDetailsBySearchKeys(searchingCargo)
      .subscribe(results => {
        this.cargoHeaders = results;
        var tempInvoiceNo;
        var cnt = 0;
        this.cargoHeaders.forEach(element => {
          if (tempInvoiceNo != element) {
            tempInvoiceNo = element.InvoiceNo;
            cnt++;
          }
          this.cntCargo = cnt;
        });
        this.loaded = true;
        this.showNumberOfCargo = true;
      },        
      error => {
        this.flashMessage.show("No data found. " + error.message, {
          cssClass: "alert-danger",
          timeout: 6000
        });
        this.loaded = true;
        this.showNumberOfCargo = true
      }
      );  
  }

  onCancel($event) {
    event.preventDefault(); // This is neccessary to prevent cencel button submit form
    this.cargoHeader.InvoiceNo = null;
    this.cargoHeader.ContainerNo = null;
    this.cargoHeader.BLNo = null;
    this.cargoHeader.VesselName=null;
    this.cargoHeader.VoyageNo=null;
    this.cargoHeader.FactoryNo=null;
    this.cargoHeader.PONo=null;
    this.cargoHeader.PartNo=null;
    this.cargoHeader.Status = null;
    this.cargoHeader.ETDLPStart = null;
    this.cargoHeader.ETDLPEnd=null;
    this.cargoHeader.ATDLPStart=null;
    this.cargoHeader.ATDLPEnd=null;
    this.cargoHeader.ETADPStart = null;
    this.cargoHeader.ETADPEnd = null;
    this.cargoHeader.ATADPStart = null;
    this.cargoHeader.ATADPEnd = null;
    sessionStorage.removeItem("mySearchingCargo");
  }
  exportBLToCSV(){
    if(this.cargoHeader.BLNo==null){
      this.flashMessage.show("BL# missing. ", {
        cssClass: "alert-danger",
        timeout: 6000
      });
      this.loaded = true;
      this.showNumberOfCargo = true
    }else{
      this.cargoService
        .getCargoDetailByBLNo(this.cargoHeader.BLNo)
        .subscribe(results => {
          this.cargoDetails = results;
          var headers={
            BLNo: "BL#",   
            ContainerNo: "Container#",
            CaseNo: "Case#",
            PartNo: "part#"
          };
          var itemsFormatted = [];
          this.cargoDetails.forEach(item => {
            itemsFormatted.push({
              BLNo: item.BLNo == null ? "" : item.BLNo,
              ContainerNo: item.ContainerNo == null ? "" : item.ContainerNo,
              CaseNo: item.CaseNo==null?"":item.CaseNo,
              PartNo: item.PartNo==null?"":item.PartNo
            });
          });
          var fileTitle = "BLDetails";
          this.exportCSVFile(null, itemsFormatted, fileTitle);//No header is required
      
        },        
        error => {
          this.flashMessage.show("No data found. " + error.message, {
            cssClass: "alert-danger",
            timeout: 6000
          });
          this.loaded = true;
          this.showNumberOfCargo = true
        }
      );
    }
  }

  exportToExcel() {
    var headers = {
      InvoiceNo: "Invoice #",
      ContainerNo: "Container #",
      BLNo: "BL #",
      VesselName: "Vessel Name",
      VoyageNo:"Voyage #",
      LoadingPort:"Loading Port",
      ETD: "ETD",
      ATD:"ATD",
      DischargingPort:"Discharging Port",
      ETA:"ETA",
      ATA:"ATA",
      CustomsClearanceDate: "Customs Clearance Date",
      Status:"Status",
      LastFree: "Last Free",
      AppointmentDate:"Appointment Date",
      ContainerPickup:"Container Pickup",
      DeliveryCompletion:"Delivery Completion",
      EmptyReturn: "Empty Return",
      Memo: "Memo"
    };
    var itemsFormatted = [];
    this.cargoHeaders.forEach(item => {

      var tempETD = new Date(item.ETDLP);
      var tempETDMonth = tempETD.getMonth()+1;
      var tempETDDay = tempETD.getDate();
      var tempETDYear = tempETD.getFullYear();
      var tempATD = new Date(item.ATDLP);
      var tempATDMonth = tempATD.getMonth()+1;
      var tempATDDay = tempATD.getDate();
      var tempATDYear = tempATD.getFullYear();
      var tempETA = new Date(item.ETADP);
      var tempETAMonth = tempETA.getMonth()+1;
      var tempETADay = tempETA.getDate();
      var tempETAYear = tempETA.getFullYear();
      var tempATA = new Date(item.ATADP);
      var tempATAMonth = tempATA.getMonth()+1;
      var tempATADay = tempATA.getDate();
      var tempATAYear = tempATA.getFullYear();
      var tempCC = new Date(item.CustomsClearanceDate);
      var tempCCMonth = tempCC.getMonth()+1;
      var tempCCDay = tempCC.getDate();
      var tempCCYear = tempCC.getFullYear();
      var tempLastFree = new Date(item.LastFreeDate);
      var tempLastFreeMonth = tempLastFree.getMonth()+1;
      var tempLastFreeDay = tempLastFree.getDate();
      var tempLastFreeYear = tempLastFree.getFullYear();
      var tempAppointment = new Date(item.AppointmentDate);
      var tempAppointmentMonth = tempAppointment.getMonth()+1;
      var tempAppointmentDay = tempAppointment.getDate();
      var tempAppointmentYear = tempAppointment.getFullYear();
      var tempContainerPickup = new Date(item.ContainerPickedUpDate);
      var tempContainerPickupMonth = tempContainerPickup.getMonth()+1;
      var tempContainerPickupDay = tempContainerPickup.getDate();
      var tempContainerPickupYear = tempContainerPickup.getFullYear();
      var tempDeliveryCompletion = new Date(item.DeliveryCompletion);
      var tempDeliveryCompletionMonth = tempDeliveryCompletion.getMonth()+1;
      var tempDeliveryCompletionDay = tempDeliveryCompletion.getDate();
      var tempDeliveryCompletionYear = tempDeliveryCompletion.getFullYear();
      var tempEmptyReturn = new Date(item.EmptyReturnDate);
      var tempEmptyReturnMonth = tempEmptyReturn.getMonth()+1;
      var tempEmptyReturnDay = tempEmptyReturn.getDate();
      var tempEmptyReturnYear = tempEmptyReturn.getFullYear();

      itemsFormatted.push({
        InvoiceNo: item.InvoiceNo == null ? "" : item.InvoiceNo,
        ContainerNo: item.ContainerNo == null ? "" : item.ContainerNo,
        BLNo: item.BLNo == null ? "" : item.BLNo,
        VesselName:
          item.VesselName == null ? "" : item.VesselName.replace(/,/g, " "),
        VoyageNo:
        item.VoyageNo == null ? "" : item.VoyageNo.replace(/,/g, " "), 
        LoadingPort:
        item.LoadingPort == null
          ? ""
          : item.LoadingPort.replace(/,/g, " "),  
        ETDLP: item.ETDLP==null?"":tempETDMonth+"/"+tempETDDay+"/"+tempETDYear,
        ATDLP: item.ATDLP == null ? "" : tempATDMonth+"/"+tempATDDay+"/"+tempATDYear,

        DischargingPort:
          item.DischargingPort == null
            ? ""
            : item.DischargingPort.replace(/,/g, " "),
        ETADP: item.ETADP == null ? "" : tempETAMonth+"/"+tempETADay+"/"+tempETAYear,
        ATADP: item.ATADP == null ? "" : tempATAMonth+"/"+tempATADay+"/"+tempATAYear, 
        CustomsClearanceDate:
          item.CustomsClearanceDate == null ? "" : tempCCMonth+"/"+tempCCDay+"/"+tempCCYear,   
        Status: item.Status == null ? "" : item.Status,
        LastFree: item.LastFreeDate == null ? "" : tempLastFreeMonth+"/"+tempLastFreeDay+"/"+tempLastFreeYear,
        AppointmentDate: item.AppointmentDate == null ? "" : tempAppointmentMonth+"/"+tempAppointmentDay+"/"+tempAppointmentYear, 
        ContainerPickup:item.ContainerPickedUpDate == null ? "" : tempContainerPickupMonth+"/"+tempContainerPickupDay+"/"+tempContainerPickupYear, 
        DeliveryCompletion:item.ContainerPickedUpDate == null ? "" : tempDeliveryCompletionMonth+"/"+tempDeliveryCompletionDay+"/"+tempDeliveryCompletionYear, 
        EmputyReturn: item.EmptyReturnDate == null ? "" : tempEmptyReturnMonth+"/"+tempEmptyReturnDay+"/"+tempEmptyReturnYear, 
        Memo:item.MTSComment == null ? "" : item.MTSComment.replace(/,/g, ""),   
      });
    });
    var fileTitle = "loads";
    this.exportCSVFile(headers, itemsFormatted, fileTitle);
  }
  exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = this.convertToCSV(jsonObject);
    var exportedFilenmae = fileTitle + ".csv" || "export.csv";
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  dateChangedForEdit(containerNo:string){
    console.log("Editing container: "+ containerNo)
    this.dateChangedContainers.push(containerNo);
  }

}
