<div class="myContainer">
 <div>
   <a
     routerLink="/recentUpdates"
     class="btn btn-link"
     id="linkBackTo"
   >
     <i class="fas fa-arrow-circle-left"></i> Go to Recent Updates</a>
 </div>
   <div class="row truckingTitle">
       <div class="col-md-6">
         <h2><i class="fas fa-route fa-2x"></i>&nbsp; Tracking BL</h2>
       </div>
   </div>
     <div class="myContainer">
       <!--  ####### Search Box Area ######## -->
       <mat-card class="SearchBox  d-flex align-items-stretch" >        
         <mat-card-content class="SearchBox2  d-flex align-items-stretch">
           <form #mySearchForm="ngForm" (ngSubmit)="onSearchSubmit(mySearchForm)">
               <div class="row">
                 <div class="searchItemDDL">
                     <mat-form-field class="containerSearchSm">
                       <mat-label>Status</mat-label>
                       <mat-select
                         name="Status"
                         [(ngModel)]="cargoHeader.Status"
                       >
                         <mat-option
                           *ngFor="let status of statuses"
                           [value]="status.value"
                         >
                           {{ status.display }}
                         </mat-option>
                       </mat-select>
                     </mat-form-field>
                 </div>
                 <div class="searchItem">
                     <mat-form-field class="containerSearchLg">
                       <mat-label>BL #</mat-label>
                       <input
                         [(ngModel)]="cargoHeader.BLNo"
                         name="BLNo"
                         matInput
                         placeholder="BL#"
                         class="matInputBox"
                       />
                     </mat-form-field>
                 </div>
                 <div class="searchItem">
                     <mat-form-field class="containerSearchLg">
                       <mat-label>Vessel Name</mat-label>
                       <input
                         [(ngModel)]="cargoHeader.VesselName"
                         name="VesselName"
                         matInput
                         placeholder="Vessel Name"
                         class="matInputBox"
                       />
                     </mat-form-field>
                 </div>
                 <div class="searchItem">
                     <mat-form-field class="containerSearchLg">
                       <mat-label>Voyage#</mat-label>
                       <input
                         [(ngModel)]="cargoHeader.VoyageNo"
                         name="VoyageNo"
                         matInput
                         placeholder="PO#"
                         class="matInputBox"
                       />
                     </mat-form-field>
                 </div> 
               </div>
             <div class="row rowCalendars">
               <div class="dateColor1">
                   <div>
                       <mat-form-field class="containerSearch">
                       <input
                           matInput
                           [matDatepicker]="ETDLPStart"
                           placeholder="ETDLP beginning"
                           [(ngModel)]="cargoHeader.ETDLPStart"
                           name="ETDLPStart"
                           class="matInputBox"
                       />
                       <mat-datepicker-toggle
                           matSuffix
                           [for]="ETDLPStart"
                       ></mat-datepicker-toggle>
                       <mat-datepicker #ETDLPStart></mat-datepicker>
                       </mat-form-field>
                   </div>
                 </div>
               <div class="dateColorSub">~</div>
               <div class="dateColor1">
                   <div>
                       <mat-form-field class="containerSearch">
                       <input
                           matInput
                           [matDatepicker]="ETDLPEnd"
                           placeholder="ETDLP ending"
                           [(ngModel)]="cargoHeader.ETDLPEnd"
                           name="ETDLPEnd"
                           class="matInputBox"
                       />
                       <mat-datepicker-toggle
                           matSuffix
                           [for]="ETDLPEnd"
                       ></mat-datepicker-toggle>
                       <mat-datepicker #ETDLPEnd></mat-datepicker>
                       </mat-form-field>
                   </div>
               </div>
               <div class="dateColorSub2"></div>
               <div class="dateColor1">               
                 <mat-form-field  class="containerSearch">
                 <input
                     matInput
                     [matDatepicker]="ATDLPStart"
                     placeholder="ATDLP beginning"
                     [(ngModel)]="cargoHeader.ATDLPStart"
                     name="ATDLPStart"
                     class="matInputBox"
                 />
                 <mat-datepicker-toggle
                     matSuffix
                     [for]="ATDLPStart"
                 ></mat-datepicker-toggle>
                 <mat-datepicker #ATDLPStart></mat-datepicker>
                 </mat-form-field>                 
               </div>
               <div class="dateColorSub">~</div>
               <div class="dateColor1">              
                 <mat-form-field  class="containerSearch">
                 <input
                     matInput
                     [matDatepicker]="ATDLPEnd"
                     placeholder="ATDLP ending"
                     [(ngModel)]="cargoHeader.ATDLPEnd"
                     name="ATDLPEnd"
                     class="matInputBox"
                 />
                 <mat-datepicker-toggle
                     matSuffix
                     [for]="ATDLPEnd"
                 ></mat-datepicker-toggle>
                 <mat-datepicker #ATDLPEnd></mat-datepicker>
                 </mat-form-field>                   
               </div>
               <div class="dateColorSub2"></div>
               <div class="dateColor1">                
                 <mat-form-field  class="containerSearch">
                 <input
                     matInput
                     [matDatepicker]="ETADPStart"
                     placeholder="ETADP beginning"
                     [(ngModel)]="cargoHeader.ETADPStart"
                     name="ETADPStart"
                     class="matInputBox"
                 />
                 <mat-datepicker-toggle
                     matSuffix
                     [for]="ETADPStart"
                 ></mat-datepicker-toggle>
                 <mat-datepicker #ETADPStart></mat-datepicker>
                 </mat-form-field>                  
               </div>
               <div class="dateColorSub">~</div>
               <div class="dateColor1">               
                 <mat-form-field class="containerSearch">
                 <input
                     matInput
                     [matDatepicker]="ETADPEnd"
                     placeholder="ETADP ending"
                     [(ngModel)]="cargoHeader.ETADPEnd"
                     name="ETADPEnd"
                     class="matInputBox"
                 />
                 <mat-datepicker-toggle
                     matSuffix
                     [for]="ETADPEnd"
                 ></mat-datepicker-toggle>
                 <mat-datepicker #ETADPEnd></mat-datepicker>
                 </mat-form-field>                
               </div>
               <div class="dateColorSub2"></div>
               <div class="dateColor1">             
                 <mat-form-field  class="containerSearch">
                 <input
                     matInput
                     [matDatepicker]="ATADPStart"
                     placeholder="ATADP beginning"
                     [(ngModel)]="cargoHeader.ATADPStart"
                     name="ATADPStart"
                     class="matInputBox"
                 />
                 <mat-datepicker-toggle
                     matSuffix
                     [for]="ATADPStart"
                 ></mat-datepicker-toggle>
                 <mat-datepicker #ATADPStart></mat-datepicker>
                 </mat-form-field>                
               </div>
               <div class="dateColorSub">~</div>
               <div class="dateColor1">              
                 <mat-form-field  class="containerSearch">
                 <input
                     matInput
                     [matDatepicker]="ATADPEnd"
                     placeholder="ATADP ending"
                     [(ngModel)]="cargoHeader.ATADPEnd"
                     name="ATADPEnd"
                     class="matInputBox"
                 />
                 <mat-datepicker-toggle
                     matSuffix
                     [for]="ATADPEnd"
                 ></mat-datepicker-toggle>
                 <mat-datepicker #ATADPEnd></mat-datepicker>
                 </mat-form-field>                
               </div>
               <div class="dateColorSub2"></div>
               <div class="row" id="btnGroup">
                   <button
                       [disabled]="!mySearchForm.form.valid"
                       class="btn btn-primary btnInSearchBox">
                       <i class="fa fa-search fa-fw"></i> Search</button>
                   <button
                       (click)="onCancel($event)"
                       [disabled]="!mySearchForm.form.valid"
                       class="btn btn-secondary  btnInSearchBox">
                       Clear
                   </button>
               </div>
             </div>

           </form>
         </mat-card-content>  
       </mat-card>

       <h4 *ngIf="showNumberOfCargo" id="numberOfCargo">
        {{ cntCargo }} BL(s) Found
    </h4>


       
       <!--  ####### Body Area ######## -->
       <div *ngIf="!editMode">
         <h4 *ngIf="!loaded" class="table loading-message">Loading containers ...</h4>
         <div *ngIf="!loaded">
           <mat-progress-bar mode="indeterminate"></mat-progress-bar>
         </div>

         <table
           *ngIf="cargoHeaders?.length > 0"
           class="table table-striped table-bordered table-hover"
           matSort
           (matSortChange)="sortData($event)"
           id="LoadList"
         >
           <thead class="thead-inverse">
             <tr class="tableStatus">
               <th mat-sort-header="BLNo" class="sortColumnMd colMd">BL#</th>
               <th mat-sort-header="VesselName" class="sortColumnLg colLg">Vessel Name</th>
               <th mat-sort-header="VoyageNo" class="sortColumnSm colSm">Voyage#</th>
               <th>LP</th>          
               <th>ETD LP</th>
               <th>ATD LP</th>
               <th>DP</th>
               <th>ETA DP</th>
               <th>ATA DP</th>
               <th>Customs<br> Clearance</th>
               <!-- <th>FTZ</th> -->
               <th>Status</th>
               <th>Note</th>
               <!-- <th>Last Updated</th> -->
             </tr>
           </thead>
           <tbody>
             <tr *ngFor="let cargoHeader of cargoHeaders" class="tableStatus">
               <td>
                <a
                routerLink="/cargo/cargo-detail/{{ cargoHeader.BLNo}}@"
                >{{ cargoHeader.BLNo }}</a
              >
               </td>
               <td  class="columnLg">
                <a
                routerLink="/cargo/cargo-detail/{{ cargoHeader.VesselName}}${{ cargoHeader.VoyageNo}}"
                >{{ cargoHeader.VesselName }}</a
              >
               </td>
               <td>
                 {{ cargoHeader.VoyageNo }}
               </td>
               <td>
                 {{ cargoHeader.LoadingPort }}
               </td>
               <td><div [ngClass]="
                   cargoHeader.ETDLPRevised == null ? 'dateNotChanged' : 'dateChanged'"
                 >
                 {{
                   cargoHeader.ETDLP == null
                     ? ""
                     : (cargoHeader.ETDLP | date: "MM/dd/yy")
                 }}
                 <div *ngIf="cargoHeader.ETDLPRevised!=null"><i class="far fa-hand-point-right"></i>
                 {{
                   (cargoHeader.ETDLPRevised | date: "MM/dd/yy") 
                 }}
               </div>
               </div>
             </td>
               <td>
                 {{ cargoHeader.ATDLP  | date: 'MM/dd/yy'}}
               </td>
               <td>
                 {{ cargoHeader.DischargingPort }}
               </td>  
               <td><div [ngClass]="
                 cargoHeader.ETADPRevised == null ? 'dateNotChanged' : 'dateChanged'"
               >
               {{
                 cargoHeader.ETADP == null
                   ? ""
                   : (cargoHeader.ETADP | date: "MM/dd/yy")
               }}
               <div *ngIf="cargoHeader.ETADPRevised!=null"><i class="far fa-hand-point-right"></i>
               {{
                 (cargoHeader.ETADPRevised | date: "MM/dd/yy") 
               }}
             </div>
             </div>
           </td>

               <td>
                 {{ cargoHeader.ATADP  | date: 'MM/dd/yy'}}
               </td>
               <td>
                 {{ cargoHeader.CustomsClearanceDate  | date: 'MM/dd/yy'}}
               </td>
               <td>
                 {{ cargoHeader.Status }}
               </td>
               <td>
                 {{ cargoHeader.MTSComment}}
               </td>
               <!-- <td>
             {{(cargoHeader.UpdatedDate!=null?cargoHeader.UpdatedDate :cargoHeader.UploadedDate)   | date:'short'   }}
               </td> -->
             </tr>
           </tbody>
         </table>   
         <h4 *ngIf="cargoHeaders?.length == 0">No BL found</h4>
       </div>  
 
</div>



</div>
