<div class="row topGridMain">
	<div class="bg-layer topGrid">
		<div class="header-main" id="shorcutBox" >
			<div ><i class="fas fa-edit  fa-2x"></i>
                <a [routerLink]="['/recentUpdates']" class="btn linkItem">Recent Updates</a><br>
                New entry, delivered, ETD/ETA updated load
			</div>
        </div>
	</div>
	<div class="bg-layer  topGrid">
		<div class="header-main" id="shorcutBox">
			<!-- <div ><i class="fab fa-searchengin fa-3x"></i>
                <a [routerLink]="['/cargo/cargo-header']" class="btn btn-link"><h2>Tracking</h2></a><br> 
                Search shipments with multiplel search keys.
            </div> -->
            <div ><h2><i class="fas fa-chart-pie  fa-1x"></i>&nbsp;Tracking</h2>
			</div>
			Search shipments with multiplel search keys.
            <div class="row d-flex justify-content-center">
                <div>
                    <a [routerLink]="['/cargo/cargo-main-header']" class="btn linkItem">BL</a>
                </div>
                <div>
                    <a [routerLink]="['/cargo/cargo-header']" class="btn linkItem">Container</a>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-layer  topGrid">
		<div class="header-main" id="shorcutBox">
			<div ><h2><i class="fas fa-chart-pie  fa-1x"></i>&nbsp;Dashboard</h2>
			</div>
			Analyse loads
            <div class="row d-flex justify-content-center">
                <div>
                    <a [routerLink]="['/dashboard/dashboard']" class="btn linkItem">Dashboard1</a>
                </div>
                <div>
                    <a [routerLink]="['/dashboard/dashboard2']" class="btn linkItem">Dashboard2</a>
                </div>
            </div>
        </div>
	</div>
</div>
