import { Injectable } from '@angular/core';
import { User } from "../../models/user";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../app.config";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  users: Observable<User[]>;
  user: User;
  constructor(private http: HttpClient, private config: AppConfig) {}
  checkEmail(email: string): Observable<any> {
    return this.http.get(
      this.config.apiMyUrl + "/api/user/emailCheck/" + email
    );
  }
  sendEmail(email: string, passcode: string): Observable<any> {
    return this.http.get(
      this.config.apiMyUrl + "/api/user/sendmail/" + email + "/" + passcode
    );
  }
  createUser(user: User): Observable<any> {
    return this.http.post(
      this.config.apiMyUrl + "/api/user/registration",
      user
    );
  }
  getUserProfile(userName: string): Observable<any> {
    return this.http.get(
      this.config.apiMyUrl + "/api/user/getUserProfile/" + userName
    );
  }
  checkEmailForPasswordRecovery(email: string): Observable<any> {
    return this.http.get(
      this.config.apiMyUrl +
        "/api/user/checkUserGetTokenForPasswordRecoveryByEmail/" +
        email
    );
  }

  checkEmailForPasswordRecoveryNew(email: string): Observable<any> {
    return this.http.get(
      this.config.apiMyUrl +
        "/api/user/checkUserGetTokenForPasswordRecoveryByEmailNew/" +
        email
    );
  }

  passwordRecovery(email: string, user: User) {
    return this.http.put(
      this.config.apiMyUrl + "/api/user/passwordRecovery/",
      user
    );
  }
  passwordRecoveryWithoutToken(email: string, user: User) {
    return this.http.put(
      this.config.apiMyUrl + "/api/user/passwordRecoveryWithoutToken/",
      user
    );
  }
}
