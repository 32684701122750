<div class="container">
  <div class="row justify-content-md-center newPasswordTitle">
    <h2>{{'Reset password'}}</h2>
  </div>
  <div class="row justify-content-md-center divNewPassword">
    <br>
    <div class="card newPasswordCard">
      <div class="card-body">
        <form
          name="form"
          (ngSubmit)="myForm.form.valid && onSubmit(myForm)"
          #myForm="ngForm"
          novalidate
        >
          <div class="form-group">
            <label for="password">  {{'New Password'}}</label>
            <input
              type="password"
              class="form-control tbEmail"
              name="Password"
              [(ngModel)]="password"
              #myPassword="ngModel"
              minlength="7"
              required
            />
            <div
              *ngIf="myForm.submitted && !myPassword.valid"
              class="alert alert-danger"
            >
            {{'Password required'}}
            </div>
            <div
              *ngIf="myPassword.errors?.minlength && myPassword.touched"
              class="alert alert-danger"
            >
              password minimum is 7
            </div>
          </div>
          <div class="form-group">
            <label for="password">  {{'Retype new password'}}</label>
            <input
              type="password"
              class="form-control tbEmail"
              name="rePassword"
              [(ngModel)]="rePassword"
              #myRePassword="ngModel"
              minlength="7"
              required
            />
            <div
              *ngIf="myForm.submitted && !myRePassword.valid"
              class="alert alert-danger"
            >
            {{'Password mismatch'}}
            </div>
            <div
              *ngIf="myRePassword.errors?.minlength && myRePassword.touched"
              class="alert alert-danger"
            >
            {{'password minimum length is 7'}}
            </div>
          </div>

          <div class="form-group">
            <button [disabled]="loading" class="btnSubmit">  {{'Send'}}</button>
            <img
              *ngIf="loading"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />
            <a [routerLink]="['/ec2020/loginEC2020Component']" class="btn btn-link">  {{'Cancel'}}</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>