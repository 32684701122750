import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { FlashMessagesService } from "angular2-flash-messages";
import { UserService } from "../../../services/user/user.service";
import { User } from "../../../models/user";
@Component({
  selector: 'app-token-check',
  templateUrl: './token-check.component.html',
  styleUrls: ['./token-check.component.css']
})
export class TokenCheckComponent implements OnInit {
  token: string;
  applyingUser: any = {};
  registrationMethod: string;
  loading = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit() {
    this.applyingUser = JSON.parse(sessionStorage.getItem("userInProcess"));
  }

  onSubmit() {
    if (this.applyingUser && this.applyingUser.token === this.token.trim()) {
      this.loading = true;
      this.userService
        .createUser(this.applyingUser.userNotConfirmedYet)
        .subscribe(
          data => {
            this.flashMessagesService.show(
              "You are registered successfully. Login with your userID and password.",
              {
                cssClass: "alert-success",
                timeout: 8000
              }
            );
            sessionStorage.removeItem("userInProcess");
            this.router.navigate(["/top"]);
          },
          error => {
            this.flashMessagesService.show(
              "Registration failed. " + error.message,
              { cssClass: "alert-danger", timeout: 5000 }
            );
            this.router.navigate(["/register"]);
          }
        );
    } else {
      this.flashMessagesService.show("Unmatched passcode", {
        cssClass: "alert-danger",
        timeout: 5000
      });
      this.router.navigate(["/tokenCheck"]);
    }
  }

}
