import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from "angular2-flash-messages";
import { Router, ActivatedRoute } from "@angular/router";
import { CargoService } from "../../services/cargo/cargo.service";
import { AuthService } from "../../services/auth/auth.service";
import { CargoHeader } from "../../models/cargoHeader";
import { Sort } from "@angular/material/sort";
import { MatRadioChange } from '@angular/material/radio';
import { WhatsNewUpdateList } from "../../models/whatsNewUpdateList";
import { WhatsNewNewEntryList } from "../../models/whatsNewNewEntryList";
import { WhatsNewArriveDPList } from "../../models/whatsNewArriveDPList";
import { WhatsNewDoorDeliveredList } from "../../models/whatsNewDoorDeliveredList";
import { MatTabChangeEvent } from '@angular/material/tabs';
import { User} from '../../models/user';
import { FileService} from '../../services/file/file.service';
import { CargoHeaderComponent } from '../cargo/cargo-header/cargo-header.component';

@Component({
  selector: 'app-recent-updates',
  templateUrl: './recent-updates.component.html',
  styleUrls: ['./recent-updates.component.css']
})
export class RecentUpdatesComponent implements OnInit {
  accounts:any=[];
  whatsNewNewEntryList:WhatsNewNewEntryList={
    InvoiceNo:null,
    ContainerNo:null,
    BLNo:null,
    IndexDate:null,
    Comment:null,
    StampDate:null,
    MyChoice:null
  }
  whatsNewArriveDPList:WhatsNewArriveDPList={
    InvoiceNo:null,
    ContainerNo:null,
    BLNo:null,
    IndexDate:null,
    Comment:null,
    StampDate:null,
    MyChoice:null
  } 
  whatsNewDoorDeliveredList:WhatsNewDoorDeliveredList={
    InvoiceNo:null,
    ContainerNo:null,
    BLNo:null,
    IndexDate:null,
    Comment:null,
    StampDate:null,
    MyChoice:null
  } 
  whatsNewUpdateList:WhatsNewUpdateList={
    InvoiceNo:null,
    ContainerNo:null,
    BLNo:null,
    IndexDate:null,
    Comment:null,
    StampDate:null,
    MyChoice:null
  }

  whatsNewNewEntryLists:WhatsNewNewEntryList[];
  whatsNewArriveDPLists:WhatsNewArriveDPList[];
  whatsNewDoorDeliveredLists:WhatsNewDoorDeliveredList[];
  whatsNewUpdateLists:WhatsNewUpdateList[];

  myChoiceWhatsNew:string="";
  loaded: boolean = false;
  loading = false;

  cargoHeader: CargoHeader = {
    CargoHeaderId:0,
    InvoiceNo: null,
    ContainerNo: null,
    ContainerType: null,
    BLNo: null,
    HouseBLNo:null,
    BLDate:null,
    ETDLP:null,
    ETDLPRevised:null,
    ATDLP :null,
    ETADP :null,
    ETADPRevised :null,
    ATADP :null,
    ETADoor :null,
    ETADoorRevised:null,
    ATADoor:null,
    AppointmentDate:null,
    GateOutDate:null,
    LastFreeDate:null,
    EmptyReturnDate:null,
    Commodity: null,
    DeliveryTerm: null,
    Currency: null,
    Status: null,
    CarrierName: null,
    VesselName : null,
    VoyageNo:null,
    PlaceOfReceipt: null,
    OriginCountry:null,
    LoadingPort: null,
    DischargingPort : null,
    Via : null,
    FinalDestination : null,
    Remarks: null,
    MTSComment : null,
    CargoType: null,  
    InlandMot:null,
    DomesticCarrierName:null,
    ETDLPStart:null,
    ETDLPEnd:null,
    ATDLPStart:null,
    ATDLPEnd:null,
    ETADPStart:null,
    ETADPEnd:null,
    ATADPStart:null,
    ATADPEnd :null,
    CustomsClearanceDate:null,
    ETADoorStart:null,
    ETADoorEnd :null,
    ATADoorStart :null,
    ATADoorEnd :null,
    CurrentLocation:null,
    CurrentEvent:null,
    StampDate:null,
    ContainerPickedUpDate:null,
    DeliveryCompletion:null,
    SealNo:null,
    CaseQty:null,
    //detail
    FactoryNo :null,
    PONo:null,
    PartNo :null,
    FTZFlag:null,
    UploadedDate:null,
    UpdatedDate:null
  }
  cargoHeaders:CargoHeader[];

  searchKeyTruckload: any = {};
  myChoice:string="";
  loginUser:any={};

  user:User={
    Id:null,
    FirstName:null,
    LastName:null,
    Email:null,
    UserName:null,
    RegisteredDate:null,
    CompanyName:null,
    DepartmentName:null,
    Password:null,
    RememberMe:null,
    IsLoggedIn:null,
    Token:null,
  }

  constructor(
    public authService: AuthService,
    private cargoService: CargoService,
    private router: Router,
    private flashMessage: FlashMessagesService,
    private route: ActivatedRoute,
    private fileService:FileService
  ) {}

  ngOnInit(): void {
    this.loginUser = JSON.parse(sessionStorage.getItem("loginUserWholeProfile"));
    if(this.loginUser!=null){
      console.log("login user is not null");
    }else{
      console.log("login user is null");
    }

    this.myChoice="Today";
    this.getWhatsNews(this.myChoice);
  }

  radioChange(event: MatRadioChange) {
    console.log(event.value);
    this.myChoice=event.value;
    this.getWhatsNews(this.myChoice);
  }

  getWhatsNews(myChoice:string){
    this.getNewShipmentList(myChoice);
    this.getArriveDPList(myChoice);
    this.getDoorDeliveredList(myChoice);
    this.getUpdatesList(myChoice);
   
  }

  getNewShipmentList(myChoice:string){
    this.loaded=false;
    this.whatsNewNewEntryList.MyChoice=myChoice;
    this.cargoService
      .getNewShipmentList(this.whatsNewNewEntryList)
      .subscribe(results => {
        this.whatsNewNewEntryLists = results;
      },        
      error => {
      }
      );
      this.loaded=true;
  }
  getArriveDPList(myChoice:string){
    this.loaded=false;
    this.whatsNewArriveDPList.MyChoice=myChoice;
    this.cargoService
      .getArrivedDPList(this.whatsNewArriveDPList)
      .subscribe(results => {
        this.whatsNewArriveDPLists = results;
      },        
      error => {      
      }
      );
      this.loaded = true;
  }
  getDoorDeliveredList(myChoice:string){
    this.loaded=false;
    this.whatsNewDoorDeliveredList.MyChoice=myChoice;
    this.cargoService
      .getDoorDeliveredList(this.whatsNewDoorDeliveredList)
      .subscribe(results => {
        this.whatsNewDoorDeliveredLists = results;
      },        
      error => {      
      }
      );
      this.loaded = true;
  }
  getUpdatesList(myChoice:string){
    this.loaded=false;
    this.whatsNewUpdateList.MyChoice = myChoice;
    this.cargoService
      .getUpdatesList(this.whatsNewUpdateList)
      .subscribe(results => {
        this.whatsNewUpdateLists = results;
        this.loaded = true;
      },        
      error => {
        this.loaded = true;
      }
      );
  }

  moveToContainerHeaderFromWhatsNewNewEntryList(event: WhatsNewNewEntryList) {
    this.router.navigate(["/cargo/cargo-header/" + event.InvoiceNo +"&"+event.ContainerNo]);
  }
  moveToContainerHeaderFromWhatsNewArriveDPList(event: WhatsNewArriveDPList) {
    this.router.navigate(["/cargo/cargo-header/" + event.InvoiceNo +"&"+event.ContainerNo]);
  }
  moveToContainerHeaderFromWhatsNewDoorDeliveredList(event: WhatsNewDoorDeliveredList) {
    this.router.navigate(["/cargo/cargo-header/" + event.InvoiceNo +"&"+event.ContainerNo]);
  }
  moveToContainerHeaderFromWhatsNewUpdateList(event: WhatsNewUpdateList) {
    this.router.navigate(["/cargo/cargo-header/" + event.InvoiceNo +"&"+event.ContainerNo]);
  }

    /**** Sort Begin ****************************************************** */
    sortData(sort: Sort) {
      const data = this.cargoHeaders.slice();
      if (!sort.active || sort.direction === "") {
        this.cargoHeaders = data;
        return;
      }
      this.cargoHeaders = data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        switch (sort.active) {
          case "InvoiceNo":
            return this.compare(a.InvoiceNo, b.InvoiceNo, isAsc);
          case "ContainerNo":
            return this.compare(a.ContainerNo, b.ContainerNo, isAsc);
          default:
            return 0;
        }
      });
    }
    compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    /***** Sort End********************************************************** */
}
