<div class="myContainer">
    <div class="col-md-6 truckingTitle">
      <h2><i class="far fa-bell fa-2x"></i>&nbsp; Recent Updates</h2>
    </div>


    <div class="col-md-12" id="rbtnWhatsNewGroup">
      <mat-radio-group      
      (change)="radioChange($event)"    
      aria-label="Select an option"
      class=radioDateRange>
        <mat-radio-button class="dateRange" value="Today" [checked]='true'>Today</mat-radio-button>
        <mat-radio-button class="dateRange"  value="Last2Days">Last 2 days</mat-radio-button>
        <mat-radio-button class="dateRange"  value="Last7Days">Last 7 days</mat-radio-button>
      </mat-radio-group>
    
        <div class="row whatsNewGroup">
          <!-- New Shipment Added -->
          <div class="col-md-3">
            <h4><i class="far fa-calendar-plus fa-2x"></i>&nbsp;&nbsp;New Shipments Added</h4>
            <div id="GroupUpdates">
              <h4 *ngIf="!loaded" class="table loading-message">Loading new shipment...</h4>
              <div *ngIf="!loaded">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
              <table
                *ngIf="whatsNewNewEntryLists?.length > 0; else NoNewShipment"
                class="table table-striped table-bordered table-hover"
                matSort
                (matSortChange)="sortData($event)"
                id="LoadList">
                <thead class="thead-inverse">
                  <tr class="tableStatusSub2">
                    <th class="columnWhatsNewSm">Added <br>Date</th>
                    <th class="columnWhatsNewLg"> Invoice#</th>
                    <th class="columnWhatsNewLg">Container#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let whatsNewNewEntryList of whatsNewNewEntryLists" >
                    <td class="columnWhatsNewSm">
                      {{ whatsNewNewEntryList.StampDate  | date: "MM/dd/yy" }}
                    </td>
                    <td class="columnWhatsNewLg">
                      <button type="button" 
                        [value] = "whatsNewNewEntryList.InvoiceNo"
                        name="InvoiceNo"
                        (click)="moveToContainerHeaderFromWhatsNewNewEntryList(whatsNewNewEntryList)"
                        class="btn btn-link columnWhatsNewLg">
                        {{ whatsNewNewEntryList.InvoiceNo }}
                      </button>
                    </td>
                    <td class="columnWhatsNewLg">
                      {{ whatsNewNewEntryList.ContainerNo}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <ng-template #NoNewShipment>
                <hr />
              </ng-template>
              <h4 *ngIf="whatsNewNewEntryLists?.length <= 0">No new shipment found</h4>
            </div> 
          </div>
          <!-- Arrived DP -->
          <div class="col-md-3">
            <h4> <i class="fas fa-ship fa-2x"></i>&nbsp;&nbsp;Arrived DP</h4>
            <div id="GroupUpdates">
              <h4 *ngIf="!loaded" class="table loading-message">Loading arrived shipment...</h4>
              <div *ngIf="!loaded">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
              <table
                *ngIf="whatsNewArriveDPLists?.length > 0; else NoArrivedDPShipment"
                class="table table-striped table-bordered table-hover"
                matSort
                (matSortChange)="sortData($event)"
                id="LoadList"
              >
                <thead class="thead-inverse">
                  <tr class="tableStatusSub3">
                    <th  class="columnWhatsNewSm">Arrived<br> Date</th>
                    <th  class="columnWhatsNewLg">Invoice#</th>
                    <th  class="columnWhatsNewLg">Container#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let whatsNewArriveDPList of whatsNewArriveDPLists" >
                    <td class="columnWhatsNewSm">
                      {{ whatsNewArriveDPList.IndexDate  | date: "MM/dd/yy" }}
                    </td>
                    <td class="columnWhatsNewLg">
                    <button type="button" 
                    [value] = "whatsNewArriveDPList.InvoiceNo"
                    name="InvoiceNo"
                    (click)="moveToContainerHeaderFromWhatsNewArriveDPList(whatsNewArriveDPList)"
                    class="btn btn-link  columnWhatsNewLg">{{ whatsNewArriveDPList.InvoiceNo }}</button>
                    </td>
                    <td class="columnWhatsNewLg">
                      {{ whatsNewArriveDPList.ContainerNo}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <ng-template #NoArrivedDPShipment>
                <hr />
              </ng-template>
              <h4 *ngIf="whatsNewArriveDPLists?.length <= 0">No DP arrived shipment found</h4>
            </div> 
          </div>
          <!-- Delivered Door -->
          <div class="col-md-3">
            <h4> <i class="fas fa-truck-loading fa-2x"></i>&nbsp;&nbsp;Door Delivered</h4>
            <div id="GroupUpdates">
              <h4 *ngIf="!loaded" class="table loading-message">Loading door delivered container...</h4>
              <div *ngIf="!loaded">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
              <table
                *ngIf="whatsNewDoorDeliveredLists?.length > 0; else NoDoorDelivered"
                class="table table-striped table-bordered table-hover"
                matSort
                (matSortChange)="sortData($event)"
                id="LoadList"
              >
                <thead class="thead-inverse">
                  <tr class="tableStatusSub4">
                    <th  class="columnWhatsNewSm">Delivered Date</th>
                    <th  class="columnWhatsNewLg">Invoice#</th>
                    <th  class="columnWhatsNewLg">Container#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let whatsNewDoorDeliveredList of whatsNewDoorDeliveredLists" >
                    <td class="columnWhatsNewSm">
                      {{ whatsNewDoorDeliveredList.IndexDate  | date: "MM/dd/yy" }}
                    </td>
                    <td class="columnWhatsNewLg">
                    <button type="button" 
                    [value] = "whatsNewDoorDeliveredList.InvoiceNo"
                    name="InvoiceNo"
                    (click)="moveToContainerHeaderFromWhatsNewDoorDeliveredList(whatsNewDoorDeliveredList)"
                    class="btn btn-link  columnWhatsNewLg">{{ whatsNewDoorDeliveredList.InvoiceNo }}</button>
                    </td>
                    <td class="columnWhatsNewLg">
                      {{ whatsNewDoorDeliveredList.ContainerNo}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <ng-template #NoDoorDelivered>
                <hr />
              </ng-template>
              <h4 *ngIf="whatsNewDoorDeliveredLists?.length <= 0">No Door Delivered found</h4>
            </div> 
          </div>
          <!-- ETD & ETA Updated -->
          <div class="col-md-3">
            <h4 ><i class="far fa-edit fa fa-2x"></i>&nbsp;&nbsp;ETD & ETA Updates</h4>
            <div id="GroupUpdates">
              <h4 *ngIf="!loaded" class="table loading-message">Loading updates...</h4>
              <div *ngIf="!loaded">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
              <table
                *ngIf="whatsNewUpdateLists?.length > 0; else NoUpdates"
                class="table table-striped table-bordered table-hover"
                matSort
                (matSortChange)="sortData($event)"
                id="LoadList"
              >
                <thead class="thead-inverse">
                  <tr class="tableStatusSub1">
                    <th  class="columnWhatsNewSm">Updated Date</th>
                    <th  class="columnWhatsNewLg">Invoice#</th>
                    <th  class="columnWhatsNewLg">Container#</th>
                    <th  class="columnWhatsNewExLg">Note</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let whatsNewUpdateList of whatsNewUpdateLists" >
                    <td class="columnWhatsNewSm">
                      {{ whatsNewUpdateList.StampDate  | date: "MM/dd/yy"}}
                    </td>
                    <td class="columnWhatsNewLg">
                      <button type="button" 
                      [value] = "whatsNewUpdateList.PickupuNo"
                      name="PickupNo"
                      (click)="moveToContainerHeaderFromWhatsNewUpdateList(whatsNewUpdateList)"
                      class="btn btn-link  columnWhatsNewLg">{{ whatsNewUpdateList.InvoiceNo }}</button>
                    </td>
                    <td class="columnWhatsNewLg">
                        {{ whatsNewUpdateList.ContainerNo }}
                      </td>
                      <td class="columnWhatsNewExLg">
                        {{ whatsNewUpdateList.Comment }}
                      </td>
                  </tr>
                </tbody>
              </table>
              <ng-template #NoUpdates>
                <hr />
              </ng-template>
              <h4 *ngIf="whatsNewUpdateLists?.length <= 0">No updates found</h4>
            </div> 
          </div>
        </div>
      </div>
    </div>