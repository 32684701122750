import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from "angular2-flash-messages";
import { Router } from "@angular/router";
import { UserService } from "../../../services/user/user.service";
import { User } from "../../../models/user";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  Email: string;
  loading = false;
  user: User;

  constructor(
    private userService: UserService,
    private router: Router,
    private flashMessagesService: FlashMessagesService
  ) { }

  ngOnInit(): void {
    localStorage.removeItem("passwordRecoveryAskingUser");
  }

  onSubmit() {
    this.loading = true;
    if (this.Email == null) {
      // Password match check
      this.flashMessagesService.show("Email should not be blank", {
        cssClass: "alert-danger",
        timeout: 5000
      });
      this.loading = false;
      this.router.navigate(["/reset-password"]);
    } else {
      this.userService
       // .checkEmailForPasswordRecovery(this.Email)
        .checkEmailForPasswordRecoveryNew(this.Email.toLowerCase())
        .subscribe(res => {
          this.user = res;
          // ############################################
          // This user is default user table in ASP.NET
          // "displayName":null,
          // "notes":null,
          // "type":0,
          // "flags":0,
          // "createdDate":"0001-01-01T00:00:00",
          // "lastModifiedDate":"0001-01-01T00:00:00",
          // "userId":0,
          // "firstName":"Yasu",
          // "lastName":"Ozeki",
          // "companyName":"marubeni-trans.com",
          // "departmentName":"IT",
          // "registeredDate":"2019-01-20T00:00:00",
          // "lastAccessedDate":null,
          // "id":"8510a05f-91ac-42ed-9d23-4f7728f69a7f",
          // "userName":"ozeki@marubeni-trans.com",
          // "normalizedUserName":"OZEKI@MARUBENI-TRANS.COM",
          // "email":"ozeki@marubeni-trans.com",
          // "normalizedEmail":"OZEKI@MARUBENI-TRANS.COM",
          // "emailConfirmed":false,
          // "passwordHash":"AQAAAAEAACcQAAAAEMiMRDTE5K1S+aS21oC3OffySQag5UuF5z9bU5NPv7p1ntXCZ0FRVch5IJafXWpINg==","securityStamp":"QQZUHDBGI5C5MX3BKLU7BDAXT2CYKA6F","concurrencyStamp":"b1127fcb-37b4-4a8c-a7ff-2b223302779e",
          // "phoneNumber":null,
          // "phoneNumberConfirmed":false,
          // "twoFactorEnabled":false,
          // "lockoutEnd":null,
          // "lockoutEnabled":true,
          // "accessFailedCount":0}
          // ###########################################

          if (this.user == null) {
            this.flashMessagesService.show(
              "Email : " + this.Email + " not in the database. Please check your email account again.",
              { cssClass: "alert-danger", timeout: 10000 }
            );
            this.loading = false;
            this.router.navigate(["/reset-password"]);
          } else {
            console.log("Setting user: " + this.user.CompanyName);
            console.log("Setting user: " + this.user.Email);
            console.log("Setting password: " + this.user.Password);

            /* (A) ************************************************************************************
            Session Storage not works when opening new page. localStorage should be used. 
            But currently it is using original idea, which is not email link opening new password page. 
            Therefore below sessionStorage is OK 
            *******************************************************************************************/
            sessionStorage.setItem(
              "passwordResettingUser",
              JSON.stringify(this.user)
            );
            this.router.navigate(["new-password/" + this.Email]);
            this.router.navigate(["email-sent-message"]);

            /* (B) ************************************************************************************
              After all recovery by separate email is selected. so localStorage is necessary.
            *******************************************************************************************/
            //   localStorage.setItem(
            //   "passwordRecoveryAskingUser",
            //   JSON.stringify(this.user)
            // ); 
            //this.router.navigate(["reset-password-message"]);
          }
        });
    }
  }

}
