import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from "angular2-flash-messages";
import { Router } from "@angular/router";
import { UserService } from "../../../services/user/user.service";
import { User } from "../../../models/user";


@Component({
  selector: 'app-email-sent-message',
  templateUrl: './email-sent-message.component.html',
  styleUrls: ['./email-sent-message.component.css']
})
export class EmailSentMessageComponent implements OnInit {

  constructor(
    private userService: UserService,
    private router: Router,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit(): void {
  }

}
