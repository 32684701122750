<div class="cargoDetailContainer">
  <div class="row">
  <div *ngIf="backToPage=='BL'"  class="col-md-6">
    <div>
        <a
        routerLink="/cargo/cargo-main-header"
        class="btn btn-link"
        id="linkBackTo"
      >
        <i class="fas fa-arrow-circle-left"></i> Back to BL Tracking
        Page</a
      >
    </div>
  </div>
  <div *ngIf="backToPage=='Container'" class="col-md-6">
    <div>
        <a
        routerLink="/cargo/cargo-header"
        class="btn btn-link"
        id="linkBackTo"
      >
        <i class="fas fa-arrow-circle-left"></i> Back to Container Tracking
        Page</a
      >
    </div>
  </div>
</div>

<div id="DealerEditHeader" class="col-md-3">
  <i class="fas fa-info-circle fa-1x"></i>
  Container Details:
</div>
<div class="card-header cardTop">
  <table>
    <tr class=topTitles>
      <!-- <td class="detalHeaderName">Invoice#:</td>
      <td class="detailHeaderValue">{{ cargoHeader.InvoiceNo }}</td>
      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td class="detalHeaderName">Container#:</td>
      <td class="detailHeaderValue">{{ cargoHeader.ContainerNo }}</td> -->
      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td class="detalHeaderName">BL#:</td>
      <td class="detailHeaderValue"> </td>
      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td class="detalHeaderName">Vessel:</td>
      <td class="detailHeaderValue">{{ cargoHeader.VesselName }}</td>
      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td class="detailHeaderValue">{{ cargoHeader.VoyageNo }}</td>
      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td class="detalHeaderName">ETD LP:</td>
      <td class="detailHeaderValue">{{ cargoHeader.ETDLP| date: 'MM/dd/yy' }}</td>
      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td class="detalHeaderName">ATD LP:</td>
      <td class="detailHeaderValue">{{ cargoHeader.ATDLP| date: 'MM/dd/yy' }}</td>
      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td class="detalHeaderName">ETA DP:</td>
      <td class="detailHeaderValue">{{ cargoHeader.ETADP| date: 'MM/dd/yy' }}</td>
      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td class="detalHeaderName">ATA DP:</td>
      <td class="detailHeaderValue">{{ cargoHeader.ATADP| date: 'MM/dd/yy' }}</td>   
    </tr>
  </table>
</div>

  <div class="row rowButtons">
    <div class="col-md-6">
      <div class="row">
      <h3>Case Details <i class="fas fa-dice-d6 fa-1x"></i></h3>
      </div>
    </div>
    <div class="col-sm-1">
      <button (click)="exportToExcel()" class="btn btn-success customWidth">
        Download
      </button>
    </div>
    <div class="col-sm-2">
      <button (click)="exportToExcelForSAP()" class="btn btn-success customWidth">
        Download for SAP
      </button>
    </div>
  </div>
  <div class="col-md-12">
    <div class="form-group ">
    <h4 *ngIf="!loadedCargoDetails"  class="table loading-message">
      Loading case information ...
    </h4>
    <div *ngIf="!loadedCargoDetails">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="example-container mat-elevation-z8">
      <table 
      mat-table 
      [dataSource]="cargoDetails" 
     
      id="tableScrollable"
      class="table table-striped table-bordered table-hover"
      >
      <ng-container matColumnDef="BLNo" >
        <th mat-header-cell *matHeaderCellDef  id="caseGridTitleLg"> BL # </th>
        <td mat-cell *matCellDef="let element"
        [className]="
        element.BLNo == searchingBLNo? 'matchingSearchKey': ''"
        id="caseGrid"> {{element.BLNo}} </td>
      </ng-container>
      <ng-container matColumnDef="InvoiceNo">
        <th mat-header-cell *matHeaderCellDef > Invoice # </th>
        <td mat-cell *matCellDef="let element"
        [className]="
        element.InvoiceNo == searchingInvoiceNo? 'matchingSearchKey': ''"
        id="caseGrid"> {{element.InvoiceNo}} </td>
      </ng-container>
        <ng-container matColumnDef="ContainerNo">
          <th mat-header-cell *matHeaderCellDef > Container # </th>
          <td mat-cell *matCellDef="let element" 
          [className]="
          element.ContainerNo == searchingContainerNo? 'matchingSearchKey': ''"
          id="caseGrid"> {{element.ContainerNo}} </td>
        </ng-container>
        <ng-container matColumnDef="FactoryNo">
          <th mat-header-cell *matHeaderCellDef > Factory # </th>
          <td mat-cell *matCellDef="let element"          
          [className]="
          element.FactoryNo == searchingFactoryNo? 'matchingSearchKey': ''"
          id="caseGrid"> 
          {{element.FactoryNo}}</td>
        </ng-container> 
        <ng-container matColumnDef="CaseQty">
          <th mat-header-cell *matHeaderCellDef > Case Qty </th>
          <td mat-cell *matCellDef="let element" id="caseGrid"> {{element.CaseQty}} </td>
        </ng-container> 
        <ng-container matColumnDef="CaseNo">
          <th mat-header-cell *matHeaderCellDef > Case # </th>
          <td mat-cell *matCellDef="let element" id="caseGrid"> {{element.CaseNo}} </td>
        </ng-container> 
        <!-- <ng-container matColumnDef="KeyNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Key # </th>
          <td mat-cell *matCellDef="let element" class="caseGrid"> {{element.KeyNo}} </td> 
        </ng-container>   -->
        <ng-container matColumnDef="ItemNo">
          <th mat-header-cell *matHeaderCellDef > Item # </th>
          <td mat-cell *matCellDef="let element" id="caseGrid"> {{element.ItemNo}} </td>
        </ng-container>  
        <ng-container matColumnDef="PLNo">
          <th mat-header-cell *matHeaderCellDef > PL # </th>
          <td mat-cell *matCellDef="let element" id="caseGrid"> {{element.PLNo}} </td>
        </ng-container>  
        <ng-container matColumnDef="PONo">
          <th mat-header-cell *matHeaderCellDef > PO # </th>
          <td mat-cell *matCellDef="let element"
          [className]="
          element.PONo == searchingPONo? 'matchingSearchKey': ''"
          id="caseGrid"> 
          {{element.PONo}} </td>
        </ng-container>  
        <ng-container matColumnDef="PartsDescription">
          <th mat-header-cell *matHeaderCellDef > Parts Description </th>
          <td mat-cell *matCellDef="let element" id="caseGrid"> {{element.PartsDescription}} </td>
        </ng-container>  
        <ng-container matColumnDef="Qty">
          <th mat-header-cell *matHeaderCellDef > Qty </th>
          <td mat-cell *matCellDef="let element" id="caseGrid"> {{element.Qty}} </td>
        </ng-container> 
        <ng-container matColumnDef="PartNo">
          <th mat-header-cell *matHeaderCellDef > Part# </th>
          <td mat-cell *matCellDef="let element"
          [className]="
          element.PartNo == searchingPartNo? 'matchingSearchKey': ''"
          id="caseGrid"> 
          {{element.PartNo}} </td>
        </ng-container> 



        <tr mat-header-row *matHeaderRowDef="myCargoDetailDisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: myCargoDetailDisplayedColumns;"></tr>
      </table>
    </div>
  </div> 
  </div>

  <hr>

  <div *ngIf="showDateChangeHistory==true">
  <!-- Grid Group Names -->
  <div class="row gridGroupNames">
    <div class="col-md-7">
      <h3>Date Change History <i class="fas fa-history fa-1x"></i></h3>
    </div>
  </div>



  <div class="row">
    <!-- Date Change -->
    <div class="col-md-7">
        <div class="form-group ">
          <h4 *ngIf="loadedDateUpdatedLog==false" class="table loading-message">
            Loading date update log ...
          </h4>
          <div *ngIf="loadedDateUpdatedLog==false">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <table
            *ngIf="dateUpdatedLogs?.length > 0; else noDateScheduleLogs"
            class="table table-striped table-bordered table-hover"
            id="tblContainerExtraLogs"
          >
            <thead class="thead-inverse">
              <tr class="tableStatus">
                <th>Date</th>
                <th>ETD LP Original</th>
                <th>ETD LP Revised</th>
                <th>ATD LP</th>
                <th>ETA DP Original</th>
                <th>ETA DP Revised</th>
                <th>ATA DP</th>
                <th>Custom Clearance</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let dateUpdatedLog of dateUpdatedLogs"
                class="tableStatus"
              >
                <td>
                  {{ dateUpdatedLog.UpdatedDate |   date:'short' }}
                </td>
                <td>
                  {{ dateUpdatedLog.ETDLP | date: "MM/dd/yy" }}
                </td>
                <td class="etaChangeHistoryColumn">
                  {{ dateUpdatedLog.ETDLPRevised | date: "MM/dd/yy" }}
                </td>
                <td>
                  {{ dateUpdatedLog.ATDLP | date: "MM/dd/yy" }}
                </td>
                <td>
                  {{ dateUpdatedLog.ETADP | date: "MM/dd/yy" }}
                </td>
                <td class="etaChangeHistoryColumn">
                  {{ dateUpdatedLog.ETADPRevised | date: "MM/dd/yy" }}
                </td>
                <td>
                  {{ dateUpdatedLog.ATADP | date: "MM/dd/yy" }}
                </td>
                <td>
                  {{ dateUpdatedLog.CustomsClearanceDate | date: "MM/dd/yy" }}
                </td>
                <td class="etaChangeHistoryColumn">
                  {{ dateUpdatedLog.Comment }}
                </td>
              </tr>
            </tbody>
          </table>
          <ng-template #noDateScheduleLogs>
            <hr />
            <h4 *ngIf="dateUpdatedLogs?.length == 0">No update log found</h4>
          </ng-template>
        </div>
    </div> 
  </div>
  <hr />
  </div>
</div>



