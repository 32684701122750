<div class="container">
  <div class="col-md-6 col-md-offset-3">
      <br><br>
    <h2><i class="fas fa-user-edit  fa-2x"></i> Register</h2>
    <br />
    <form
      name="form"
      (ngSubmit)="myForm.form.valid && onSubmit()"
      #myForm="ngForm"
      novalidate
    >
     <!-- First Name -->
    <div class='divInputBox' >
      <Label>
        1. First Name
      </Label><br>
        <mat-form-field  appearance="outline" class="RegisterInput">
          <mat-label>
            First Name
          </mat-label>
          <input
            matInput
            placeholder="Enter First Name"
            type="text"
            name="FirstName"
            [(ngModel)]="user.FirstName"
            #myFirstName="ngModel"
            required
            class="matInputField"
          />
          <mat-error *ngIf="myFirstName.invalid">{{
            getErrorMessage()
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- Last Name -->
    <div class='divInputBox'>
      <Label>
        2. Last Name
      </Label><br>
        <mat-form-field appearance="outline" class="RegisterInput">
          <mat-label>
            Last Name
          </mat-label>
          <input
            matInput
            placeholder="Enter Last Name"
            type="text"
            name="LastName"
            [(ngModel)]="user.LastName"
            #myLastName="ngModel"
            required
            class="matInputField"
          />
          <mat-error *ngIf="myLastName.invalid">{{
            getErrorMessage()
          }}</mat-error>
        </mat-form-field>
    </div>
    <!-- Email -->
    <div class='divInputBox'>
      <Label>
        3. Email
      </Label><br>
      <mat-form-field  appearance="outline" class="RegisterInput">
        <input
          matInput
          placeholder="Enter Email"
          type="email"
          name="Email"
          [(ngModel)]="user.Email"   
          #myEmail="ngModel"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
          required
          (blur)="onBlurEmailBox()"
          class="matInputField"
        />
        <mat-error *ngIf="myEmail.invalid">{{ getErrorMessage() }}</mat-error>
      </mat-form-field>
    </div>
    <!-- Password -->
    <div  class='divInputBox'>
      <Label>
        4. Password
      </Label><br>
      <mat-form-field  appearance="outline" class="RegisterInput">
        <input
          matInput
          placeholder="Enter Password"
          type="password"
          name="Passwrd"
          [(ngModel)]="user.Password"
          #myPassword="ngModel"
          required
          minlength="7"
          class="matInputField"
        />
        <mat-hint>Minimum Length 7</mat-hint>
        <mat-error *ngIf="myPassword.invalid">{{
          getErrorMessage()
        }}</mat-error>
      </mat-form-field>
    </div>
    <!-- Re-Password -->
    <div>
      <mat-form-field appearance="outline" class="RegisterInput">
        <input
          matInput
          placeholder="Retype Password"
          type="password"
          name="rePasswrd"
          [(ngModel)]="user.rePassword"
          #myRePassword="ngModel"
          required
          minlength="7"
          class="matInputField"
        />
        <mat-error *ngIf="myPassword.invalid">{{
          getErrorMessage()
        }}</mat-error>
      </mat-form-field>
    </div>
    <!-- Department (MTS Only) -->
    <div *ngIf="showMTSDepartment" appearance="outline" class='divInputBox'>
      <Label>
        5. Department (MTS Only)
      </Label><br> 
      <mat-form-field  appearance="outline" class="RegisterInput" >
        <mat-label>Department</mat-label>
        <mat-select
          name="DepartmentName"
          [(ngModel)]="user.DepartmentName"
          #myDepartmentName="ngModel"
          required
          class="matInputField"
        >
          <mat-option>None</mat-option>
          <mat-option
            *ngFor="let item of MTSDepartmentNames"
            [value]="item"
            >{{ item }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="myDepartmentName.invalid">{{
          getErrorMessage()
        }}</mat-error>
     </div>

      <br /><br />
      <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary btnsRegister">Register</button>
        <img
          *ngIf="loading"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
        <a [routerLink]="['/login']" class="btn btn-link  btnsRegister">Cancel</a>
      </div>
    </form>
  </div>
</div>