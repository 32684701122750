import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AppConfig } from "../../app.config";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { CargoHeader } from "../../models/cargoHeader";
import { CargoDetail } from "../../models/cargoDetail";
import { CargoHeaderDetail } from "../../models/cargoHeaderDetail";
import{ShipmentAdviceCargoHeader} from "../../models/shipmentAdviceCargoHeader";
import{ShipmentAdviceCargoContainer} from "../../models/shipmentAdviceCargoContainer";
import { WhatsNewUpdateList } from "../../models/whatsNewUpdateList";
import { WhatsNewNewEntryList } from "../../models/whatsNewNewEntryList";
import { WhatsNewArriveDPList } from "../../models/whatsNewArriveDPList";
import { WhatsNewDoorDeliveredList } from "../../models/whatsNewDoorDeliveredList";
import { AuthService } from '../auth/auth.service';
import { DateUpdatedLog} from "../../models/dateUpdatedLog";

@Injectable({
  providedIn: 'root'
})
export class CargoService {

  constructor(private http: HttpClient, 
    private config: AppConfig,
    private authService:AuthService) {}

    getCargoBLsArrivedToday(): Observable<CargoHeader[]> {
      return this.http.get<CargoHeader[]>(
        this.config.apiMyUrl + "/api/cargo/getCargoBLsArrivedToday",
        { headers: this.authService.getJWT()}
      );
    }

    getCargoBLsBySearchKeys(cargo: CargoHeader): Observable<CargoHeader[]> {
      return this.http.post<CargoHeader[]>(
        this.config.apiMyUrl + "/api/cargo/getCargoBLsBySearchKeys",
        cargo,
        { headers: this.authService.getJWT()  }
      );
    }

    getCargoHeadersArrivedToday(): Observable<CargoHeader[]> {
      return this.http.get<CargoHeader[]>(
        this.config.apiMyUrl + "/api/cargo/GetCargoHeadersArrivedToday",
        { headers: this.authService.getJWT()}
      );
    }
  
    getCargoHeadersBySearchKeys(cargo: CargoHeader): Observable<CargoHeader[]> {
      return this.http.post<CargoHeader[]>(
        this.config.apiMyUrl + "/api/cargo/getCargoHeadersBySearchKeys",
        cargo,
        { headers: this.authService.getJWT()  }
      );
    }

    getCargoHeadersByInvContNo(cargo: CargoHeader): Observable<CargoHeader[]> {
      return this.http.post<CargoHeader[]>(
        this.config.apiMyUrl + "/api/cargo/getCargoHeadersByInvContNo",
        cargo,
        { headers: this.authService.getJWT()  }
      );
    }
    getCargoHeadersByBLNo(cargo: CargoHeader): Observable<CargoHeader[]> {
      return this.http.post<CargoHeader[]>(
        this.config.apiMyUrl + "/api/cargo/getCargoHeadersByBLNo",
        cargo,
        { headers: this.authService.getJWT()  }
      );
    }
    getCargoHeadersByVesselNameVoyageNo(cargo: CargoHeader): Observable<CargoHeader[]> {
      return this.http.post<CargoHeader[]>(
        this.config.apiMyUrl + "/api/cargo/getCargoHeadersByVesselNameVoyageNo",
        cargo,
        { headers: this.authService.getJWT()  }
      );
    }


  /***************************************
 * What's new
 * ************************************/
  getNewShipmentList(model: WhatsNewNewEntryList): Observable<WhatsNewNewEntryList[]> {
    return this.http.post<WhatsNewNewEntryList[]>(
      this.config.apiMyUrl +
        "/api/cargo/getNewShipmentList",model, { headers: this.authService.getJWT()  }
    );
  }

  getArrivedDPList(model: WhatsNewArriveDPList): Observable<WhatsNewArriveDPList[]> {
    return this.http.post<WhatsNewArriveDPList[]>(
      this.config.apiMyUrl +
        "/api/cargo/getArrivedDPList",model, { headers: this.authService.getJWT()  }
    );
  }

  getDoorDeliveredList(model: WhatsNewDoorDeliveredList): Observable<WhatsNewDoorDeliveredList[]> {
    return this.http.post<WhatsNewDoorDeliveredList[]>(
      this.config.apiMyUrl +
        "/api/cargo/getDoorDeliveredList",model, { headers: this.authService.getJWT()  }
    );
  }

  getUpdatesList(model:WhatsNewUpdateList): Observable<WhatsNewUpdateList[]> {
    return this.http.post<WhatsNewUpdateList[]>(
      this.config.apiMyUrl +
        "/api/cargo/getUpdatesList",model, { headers: this.authService.getJWT()  }
    );
  }
/**************************************************************************** */



  updateCargoHeaders(cargoHeaders:CargoHeader[]){
    return  this.http.post<CargoHeader[]>(
      this.config.apiMyUrl + "/api/cargo/updateCargoHeaders",
      cargoHeaders,
      { headers: this.authService.getJWT()  });
  }
  
  getCargoHeaderDetailsBySearchKeys(cargo: CargoHeader): Observable<CargoHeader[]> {
    return this.http.post<CargoHeader[]>(
      this.config.apiMyUrl + "/api/cargo/getCargoHeaderDetailsBySearchKeys",
      cargo,
      { headers: this.authService.getJWT()  }
    );
  }

  getCargoDetailsBySearchKeys(cargo: CargoHeader): Observable<CargoDetail[]> {
    return this.http.post<CargoDetail[]>(
      this.config.apiMyUrl + "/api/cargo/getCargoDetailsBySearchKeys",
      cargo,
      { headers: this.authService.getJWT()  }
    );
  }

  getCargoDetailsByInvContNo(cargo: CargoHeader): Observable<CargoDetail[]> {
    return this.http.post<CargoDetail[]>(
      this.config.apiMyUrl + "/api/cargo/getCargoDetailsByInvContNo",
      cargo,
      { headers: this.authService.getJWT()  }
    );
  }
  getCargoDetailsByBLNo(cargo: CargoHeader): Observable<CargoDetail[]> {
    return this.http.post<CargoDetail[]>(
      this.config.apiMyUrl + "/api/cargo/getCargoDetailsByBLNo",
      cargo,
      { headers: this.authService.getJWT()  }
    );
  }
  getCargoDetailsByVesselNameVoyageNo(cargo: CargoHeader): Observable<CargoDetail[]> {
    return this.http.post<CargoDetail[]>(
      this.config.apiMyUrl + "/api/cargo/getCargoDetailsByVesselNameVoyageNo",
      cargo,
      { headers: this.authService.getJWT()  }
    );
  }

  getCargoHeaderDetailsByInvContNo(cargo: CargoHeader): Observable<CargoHeaderDetail[]> {
    return this.http.post<CargoHeaderDetail[]>(
      this.config.apiMyUrl + "/api/cargo/getCargoHeaderDetailsByInvContNo",
      cargo,
      { headers: this.authService.getJWT()  }
    );
  }
  getCargoHeaderDetailsByBLNo(cargo: CargoHeader): Observable<CargoHeaderDetail[]> {
    return this.http.post<CargoHeaderDetail[]>(
      this.config.apiMyUrl + "/api/cargo/getCargoHeaderDetailsByBLNo",
      cargo,
      { headers: this.authService.getJWT()  }
    );
  }
  getCargoHeaderDetailsByVesselNameVoyageNo(cargo: CargoHeader): Observable<CargoHeaderDetail[]> {
    return this.http.post<CargoHeaderDetail[]>(
      this.config.apiMyUrl + "/api/cargo/getCargoHeaderDetailsByVesselNameVoyageNo",
      cargo,
      { headers: this.authService.getJWT()  }
    );
  }

  getDateUpdatedLogBySearchKeys(cargo: CargoHeader): Observable<DateUpdatedLog[]> {
    return this.http.post<DateUpdatedLog[]>(
      this.config.apiMyUrl + "/api/cargo/getDateUpdatedLogBySearchKeys",
      cargo,
      { headers: this.authService.getJWT()  }
    );
  }
  getCargoDetailByBLNo(BLNo:String):Observable<CargoDetail[]>{
    return this.http.get<CargoDetail[]>(
      this.config.apiMyUrl + "/api/cargo/getCargoDetailByBLNo/"+BLNo,
      { headers: this.authService.getJWT()  }
    );
  }


  /*##################################################################  
    File Upload 
  ####################################################################*/
  uploadShipmentAdviceFromEmailBody(fileName:string, fileContent:Blob):any{
    const formData: FormData = new FormData();
    formData.append(fileName, fileContent);
   return  this.http.post(
    this.config.apiMyUrl + "/api/cargo/uploadShipmentAdviceFromEmailBody",
    formData,
    { headers: this.authService.getJWT()  }
    );
  }

  uploadFileCLPInv(fileToUpload: File, invBLNo:String): Observable<number> {
    const formData: FormData = new FormData();
    formData.append(fileToUpload.name, fileToUpload);
    return this.http.post<number>(
      this.config.apiMyUrl + "/api/cargo/uploadFileCLPInv/"+invBLNo,
       formData
    );
  }

  upseartCargoHeaderBasedOnCLPInvFile(cargoHeader:CargoHeader): any {
    return this.http.post(
      this.config.apiMyUrl + "/api/cargo/upseartCargoHeaderBasedOnCLPInvFile",
      cargoHeader,
      { headers: this.authService.getJWT()  }
    );
  }

  uploadFileToActivityContainerAppointment(fileToUpload: File): Observable<number> {
    console.log('going to')
    const formData: FormData = new FormData();
    formData.append(fileToUpload.name, fileToUpload);
    return this.http.post<number>(
      this.config.apiMyUrl + "/api/cargo/uploadFileContainerAppointment",
       formData,
    );
  }

  upseartCargoHeaders(cargoHeaders:CargoHeader[]){
    return  this.http.post<CargoHeader[]>(
      this.config.apiMyUrl + "/api/cargo/upseartCargoHeaders",
      cargoHeaders,
      { headers: this.authService.getJWT()  }
      );
  }

  upseartCargoHeader(cargoHeader:CargoHeader){
    return  this.http.post<CargoHeader>(
      this.config.apiMyUrl + "/api/cargo/upseartCargoHeader",
      cargoHeader,
      { headers: this.authService.getJWT()  }
      );
  }

  deleteDataByInvoiceNo(invoiceNo:String){
    return  this.http.delete(
      this.config.apiMyUrl + "/api/cargo/deleteDataByInvoiceNo/"+invoiceNo,
      { headers: this.authService.getJWT()  }
      );
  }

}
