
<div class="row">
    <div class="col-md-6 offset-md-3">
      <div>
        <div class="card card-body mb-3">
          <div><br /></div>
          <form
            name="form"
            (ngSubmit)="myForm.form.valid && onSubmit()"
            #myForm="ngForm"
            novalidate
          >
            <div
              class="form-group"
              [ngClass]="{ 'has-danger': myForm.submitted && !myToken.valid }"
            >
              <label for="token"
                >Enter the passcode you received in your inbox</label
              >
              <input
                type="text"
                class="form-control"
                name="token"
                [(ngModel)]="token"
                #myToken="ngModel"
                required
              />
              <div
                *ngIf="myForm.submitted && !myToken.valid"
                class="form-control-feedback"
              >
                Passcode is required
              </div>
            </div>
            <div class="form-group">
              <button [disabled]="loading" class="btn btn-primary">
                Register
              </button>
              <img
                *ngIf="loading"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
  
              <a [routerLink]="['/register']" class="btn btn-link">Cancel</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>