import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from "angular2-flash-messages";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { UserService } from "../../../services/user/user.service";
import { User } from "../../../models/user";

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  user: User={
    Id: null,
    FirstName: null,
    LastName: null,
    Email: null,
    UserName:null,
    RegisteredDate: null,
    CompanyName: null,
    DepartmentName: null,
    Password:null,
    RememberMe: null,
    IsLoggedIn: null,
    Token: null,
  } 
  loading = false;
  password: string;
  rePassword: string;
  token:string;
  tempToken:string[];

  constructor(
    private userService: UserService,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("passwordResettingUser"));
    console.log(
      "PasswordRequestingUser : " + (this.user == null ? null : this.user.Email)
    );
  }

  onSubmit({ value, valid }: { value: User; valid: boolean }) {
    if (!valid) {
      this.flashMessagesService.show("Please fill in all fields", {
        cssClass: "alert-danger",
        timeout: 5000
      });
      this.router.navigate(["new-password/" + this.user.Email]);
    } else if (value.Password != this.rePassword) {
      this.flashMessagesService.show("Password mismatch....", {
        cssClass: "alert-danger",
        timeout: 5000
      });
      this.loading = false;
      this.router.navigate(["new-password/" + this.user.Email]);
    } else {
      console.log("Test1:" + value.Password);

      //this.userService.updateUser(this.user.email, value).subscribe(
      this.user.Password = value.Password;
      this.user.Token = value.Token;
      this.userService.passwordRecovery(this.user.Email, this.user).subscribe(
        data => {
          this.flashMessagesService.show("Password updated successfully.", {
            cssClass: "alert-success",
            timeout: 5000
          });
          this.router.navigate(["/login"]);
        },
        error => {
          this.flashMessagesService.show("Password update failed...", {
            cssClass: "alert-danger",
            timeout: 5000
          });
          this.loading = false;
          this.router.navigate(["/reset-password"]);
        }
      );
    }
  }
}
