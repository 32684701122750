<br>
<br>
<div class="container">
  <div class="row">
      <div class="col-md-6 mx-auto" id="title">
        <h2>{{'Request to Reset Your Password Received'}}</h2>
        <br>
        <div class="card"  id="EmailBox">
          <div class="card-body">
            <label>
              {{
              'Thanks for submitting your email address. If you have a EC account,'+
              ' we`ve sent you an email with the information needed to reset your password. '+
              'The email might take a couple of minutes to reach your account.'+
              ' Please check your junk mail to ensure you receive it.'}}</label>
          </div>
        </div>
      </div>
  </div>
</div>